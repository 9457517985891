import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AddDocContainer from '../containers/AddDocContainer'
import DocsInArchContainer from '../containers/DocsInArchContainer'
import DocsWithWrongStatusContainer from '../containers/DocsWithWrongStatusContainer'
import DocsInProcessContainer from '../containers/DocsInProcessContainer'

import ActionResultInfoPopup from './ActionResultInfoPopup'
import UserInfoPopup from './UserInfoPopup'

const activeElementsArray = [<DocsInArchContainer />, <AddDocContainer />, <DocsWithWrongStatusContainer />, <DocsInProcessContainer />];

class Body extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	    	activeElement: 0,
	    	status: "success", action: "uploading", message: "done"
	    }
	}

	componentDidMount() {

	}

	render() {
		return (
			<div className="col-10 no-left-right-margin body-width">
  				{activeElementsArray[this.props.base.selectedTab]}
  				{
                    this.props.base.showActionResult
                    ? <ActionResultInfoPopup resultData={this.state} />
                    : null
                }
                {
                	this.props.base.showUserInfo
                	? <UserInfoPopup />
                	: null
                }
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Body);