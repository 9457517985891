import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { pushObject, getTechSignature, getObjectTechSignatureVerificationResults, getObjectStatus, pushMetadata, getObjectMetadata } from '../actions/api';
import { setProcessingStatus, setSetTechSignatureArr, setUploadResultData, setUploadDataIsDone, setTachSignatureVerfyResultsArr, setActivityLog, clearUploadResultData,
			setShowActionResult, setActionResultData, clearUploadSignersDataResult } from '../actions/localStates';

import AddDocView from '../components/AddDocView';
import AddedDocResultView from '../components/AddedDocResultView'


class AddDocContainer extends Component {
	constructor() {
		super();
		this.state = {

		};

		this.addDocRef = React.createRef();

        this.addDoc = this.addDoc.bind(this);
	}

	componentDidMount() {
	}

	componentWillUnmount () {
	}

	addDoc () {
		this.props.actions.clearUploadResultData();
		this.props.actions.clearUploadSignersDataResult();
		this.props.actions.setProcessingStatus(true)
		this.props.actions.pushObject(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.base.docVersion, this.props.docs.fileForUpload)
		.then((response) => {
			console.log(response);
			if (response.code !== undefined && response.code.length > 0) {
				this.props.actions.setProcessingStatus(false);
				
				this.props.actions.setActionResultData({status: "failed", action: "fileUploadingLabel", message: response.message})
				this.props.actions.setShowActionResult(true)

				return;
			}

			this.props.actions.setUploadResultData(response)

			this.props.actions.getTechSignature(this.props.base.authServiceUrl, this.props.login.accessToken, response.id)
			.then((response) => {
				console.log(response);
				if (response.code !== undefined && response.code.length > 0) {
					this.props.actions.setProcessingStatus(false)

					this.props.actions.setActionResultData({status: "failed", action: "fileUploadingLabel", message: response.message})
					this.props.actions.setShowActionResult(true)

					return;
				}
				this.props.actions.setSetTechSignatureArr(response)
				

				this.props.actions.getObjectTechSignatureVerificationResults(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.docs.uploadDataResult.id)
				.then((response) => {
					if (response.code !== undefined && response.code.length > 0) {
						this.props.actions.setProcessingStatus(false)

						this.props.actions.setActionResultData({status: "failed", action: "fileUploadingLabel", message: response.message})
						this.props.actions.setShowActionResult(true)
						return;
					}

					this.props.actions.setTachSignatureVerfyResultsArr(response)
					console.log(response)

					var metadata = {
			            authors: this.props.docs.authors || [],
			            fileName: this.props.docs.fileForUpload.name,
			            tags: this.props.docs.tags || []
			        }

					this.props.actions.pushMetadata(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.docs.uploadDataResult.id, JSON.stringify(metadata))
					.then((response) => {
						if (response.code !== undefined && response.code.length > 0) {
							this.props.actions.setProcessingStatus(false)

							this.props.actions.setActionResultData({status: "failed", action: "fileUploadingLabel", message: response.message})
							this.props.actions.setShowActionResult(true)

							return;
						}

						this.props.actions.getObjectMetadata(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.docs.uploadDataResult.id)
						.then((response) => {
							if (response.code !== undefined && response.code.length > 0) {
								this.props.actions.setProcessingStatus(false)

								this.props.actions.setActionResultData({status: "failed", action: "fileUploadingLabel", message: response.message})
								this.props.actions.setShowActionResult(true)
								return;
							}

							this.props.actions.setUploadResultData(response)

							this.props.actions.getObjectStatus(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.docs.uploadDataResult.id)
							.then((response) => {
								if (response.code !== undefined && response.code.length > 0) {
									this.props.actions.setProcessingStatus(false)

									this.props.actions.setActionResultData({status: "failed", action: "fileUploadingLabel", message: response.message})
									this.props.actions.setShowActionResult(true)
									return;
								}
								console.log(response);

								this.props.actions.setActivityLog(response)

								this.props.actions.setProcessingStatus(false)
								this.props.actions.setUploadDataIsDone(true)
							})
						})
					})
				})
			})
		})
	}

	render() {
		return (
			<div>
				{
					this.props.docs.uploadIsDone
					? <AddedDocResultView />
					: <AddDocView addDoc={this.addDoc} forwardRef={this.addDocRef} />
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer,
        docs: state.addDocReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	setProcessingStatus,
    	pushObject, clearUploadSignersDataResult,
    	getTechSignature,
    	setSetTechSignatureArr,
    	setUploadResultData,
    	setUploadDataIsDone,
    	getObjectTechSignatureVerificationResults,
    	setTachSignatureVerfyResultsArr,
    	getObjectStatus, pushMetadata,
    	getObjectMetadata,
    	setActivityLog,
    	clearUploadResultData,
    	setShowActionResult, setActionResultData
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDocContainer);