import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import chainIsValid from '../img/block-2_9_.svg'
import chainIsInValid from '../img/block-2_8_1.svg'

class VerifyBlockchainResultPopup extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    	showVerifyResults: false
	    }

	    this.handleShowResults = this.handleShowResults.bind(this);
	    this.close = this.close.bind(this);
	}

	componentDidMount() {

	}

	handleShowResults () {
		this.setState({
            showVerifyResults: !this.state.showVerifyResults
        });
	}

	close () {
		this.props.closeBlockchainPopup()
	}

	render() {
		let showResults = "popup_inner_verify-results"

		if (this.state.showVerifyResults && this.props.resultData.data.stageResults !== undefined) {
			showResults = "popup_inner_verify-results-show"
		}

		return (
			<div className='popup'>
				<div className={showResults}>
					<div className="col-12" style={{margin: "0px"}}>
						<h4 style={{marginTop: "15px"}}>{i18next.t("verifyResultsLabel")}</h4>
					</div>
					<div className="col-12">
						<span style={{color: "#b5b1af"}}>{i18next.t("idLabel")}</span><span>{this.props.resultData.data.archObjectId}</span>
					</div>
					<div className="col-12" style={{paddingLeft: "15px", paddingRight: "15px", marginTop: "10px"}}>

						<div className={this.props.resultData.data.valid ? "alert alert-secondary" : "alert alert-warning"} style={{marginBottom: "8px"}}>

							<div className={this.state.showVerifyResults ? "col-12 no-left-right-margin open-signature-form row" : "col-12 no-left-right-margin row"} style={{paddingBottom: "4px"}}>
								<div className="col-xl-10 col-lg-10 col-md-10 no-left-right-margin">
			                        {
			                            i18next.t("digitalDocLabel")}{this.props.resultData.data.valid ? <span style={{color: "green"}}>{i18next.t("heIsValid")}</span> : <span style={{color: "red"}}>{i18next.t("heIsInvalid")}</span> 
			                        }
			                    </div>
			                
	                            <div className="col-xl-2 col-lg-2 col-md-2 no-left-right-margin">
	                                <div className="no-left-right-margin float-right" style={{float: "inline-end"}}>
	                                	<i className={this.state.showVerifyResults ? "fa fa-chevron-up" : "fa fa-chevron-down"} onClick={this.handleShowResults} style={{cursor: "pointer"}}></i>
	                                </div>
	                            </div>
	                        </div>

                            {
			                	(this.state.showVerifyResults && this.props.resultData.data.stageResults !== undefined)
					            ? <div className="verify-results-div">
					            	{
				                        this.props.resultData.data.stageResults.map((value, index) => {
				                            return <div key={index} className="col-12 no-left-right-margin" style={{marginTop: "8px"}}>
				                            	<div className="col-12 row no-left-right-margin">
				                            		<div className="col-10 row no-left-right-margin">
				                            			<div className={value.successful ? "bg-green" : "bg-red"}>{value.localizedTitle}</div>
				                            			<div style={{fontSize: "12px"}}>{value.executionDetails}</div>
				                            		</div>
				                            		<div className="col-2 no-left-right-margin">
				                            			{   
											                value.successful
											                ? <div className="float-right" style={{float: "inline-end"}}><img className="img" width="45px" height="45px" src={chainIsValid} alt="chainIsValid" /></div>
											                : <div className="float-right" style={{float: "inline-end"}}><img className="img" width="45px" height="45px" src={chainIsInValid} alt="chainIsInValid" /></div>
											            }
				                            		</div>
				                            	</div>
				                            </div>
				                        })
				                    }
				                </div>
			                	: null
			                }
		                </div>
		            </div>
					
                    <button className="btn btn-outline-info" style={{marginRight: "15px", float: "right"}} onClick={this.close}>{i18next.t("closeLabel")}</button>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer,
        resultData: state.blockchainVerificationResultReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyBlockchainResultPopup);