import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'
import saveAs from 'file-saver';

import { setALLDocsWithProcessStatusPaginationParams, setProcessStatusPageNumber, setProcessStatusPageSize, clearDocsWithProcessStatus,
			setProcessStatusPageTotalElements, setProcessStatusPageTotalPages, setProcessingStatus, setDocsWithProcessStatus } from '../actions/localStates'

import { getMetadata, getObjectData } from '../actions/api'

import { formatDate, searchUrl, pageUrl, fromBytes } from '../actions/helpers';

import first from '../img/first.svg'
import last from '../img/last.svg'
import downloadFile from '../img/download-file.svg'

class DocsInProcessView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			elementsCountArray: [10, 20, 50],
	    	showItemsCountForm: false,
	    	current_page: 1,
			records_per_page: this.props.page.size
		};

		this.handleShowCountForm = this.handleShowCountForm.bind(this);

		this.prevPage = this.prevPage.bind(this);
		this.nextPage = this.nextPage.bind(this);
		this.firstPage = this.firstPage.bind(this);
		this.lastPage = this.lastPage.bind(this);
		this.changePage = this.changePage.bind(this);
		this.numPages = this.numPages.bind(this);
	}

	handleShowCountForm () {
		this.setState({showItemsCountForm: !this.state.showItemsCountForm})
	}

	convertSize (size) {
		var res, str = "";
		res = this.props.actions.fromBytes(size);
		str = res.value + " " + i18next.t(res.unit);
		return str
	}

	handleDownloadFile (doc) {
		this.props.actions.getObjectData(this.props.base.serviceUrl, this.props.login.accessToken, doc.id)
		.then((response) => {
			saveAs(response, doc.fileName || doc.id);
		})
	}

	componentDidMount() {
		var params = {
			page: this.props.page.number,
			size: this.props.page.size,
			status: "CREATED"
		}

		if (this.props.page.docsWithProcessStatusPaginationParamsSetted) {

		    this.setState({current_page: this.props.page.number});

			this.props.actions.getMetadata(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.actions.searchUrl(params))
			.then((response) => {
				if (response === null) {
					this.props.actions.setIsLoggedIn(false);
					return
				}
				this.props.actions.setDocsWithProcessStatus(response.content)
				this.props.actions.setALLDocsWithProcessStatusPaginationParams(response.page)
			})
		} else {
			this.props.actions.setProcessingStatus(true)
			this.props.actions.getMetadata(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.actions.searchUrl(params))
			.then((response) => {
				console.log(response);
				this.props.actions.setALLDocsWithProcessStatusPaginationParams(response.page)
				this.props.actions.setDocsWithProcessStatus(response.content)
				this.props.actions.setProcessingStatus(false)
			})
		}
	}

	handleCountChanged (value) {
		var page
		if (this.props.page.number < 1) page = 1;
	    if (this.props.page.number > this.numPages()) page = this.numPages();
		var params = {
			page: page - 1,
			size: value,
			status: "CREATED"
		}
		this.props.actions.setProcessStatusPageSize(value);
		this.setState({showItemsCountForm: !this.state.showItemsCountForm});

		this.props.actions.setProcessingStatus(true)
		this.props.actions.clearDocsWithProcessStatus()

		this.props.actions.getMetadata(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.actions.searchUrl(params))
		.then((response) => {
			this.props.actions.setALLDocsWithProcessStatusPaginationParams(response.page)
			this.props.actions.setDocsWithProcessStatus(response.content)
			this.props.actions.setProcessStatusPageSize(response.page.size)
			this.numPages();
			this.changePage(this.props.page.number);

			this.props.actions.setProcessingStatus(false)
		})
	}

	prevPage ()	{
		if (this.props.page.number > 0) {
	        this.changePage(this.props.page.number - 1);
	    }
	}

	firstPage ()	{
	    this.changePage(0);
	}

	lastPage ()	{
	    this.changePage(this.numPages() - 1);
	}

	nextPage () {
		if (this.props.page.number < this.numPages()) {
	        this.changePage(this.props.page.number + 1);
	    }
	}

	changePage (page) {
	    if (page < 0) page = 0;
	    if (page >= this.numPages()) page = this.numPages() - 1;

	    var params = {
			page: page,
			size: this.props.page.size,
			status: "CREATED"
		}

		if (page === this.props.page.number) {
	    	return;
	    }

		this.props.actions.setProcessingStatus(true)
		this.props.actions.clearDocsWithProcessStatus()

		this.props.actions.getMetadata(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.actions.searchUrl(params))
		.then((response) => {
			this.props.actions.setDocsWithProcessStatus(response.content)
			this.props.actions.setALLDocsWithProcessStatusPaginationParams(response.page)

			this.props.actions.setProcessingStatus(false)
		})
	}

	numPages () {
	    return Math.ceil(this.props.page.totalElements / this.props.page.size);
	}

	getSelectedDoc (doc) {
		this.props.getDoc(doc)
	}

	componentWillUnmount () {
	}

	render() {
		let itemsCountFormOpen = 'dropdown-menu'
		let useIEStyle = 'offset-8 bottomright'

		if (this.state.showItemsCountForm) {
			itemsCountFormOpen = 'dropdown-menu show'
		}

		if (this.props.base.isIEBrowser) {
			useIEStyle = 'offset-8 bottomright-ie'
		}
		return (
			<div>
				<div className="no-left-right-margin">
					{
						this.props.docs.data
						? <div>
							<div style={{paddingTop: "58px"}}>
							</div>
							<div className="col-12 no-left-right-margin" style={{paddingRight: "15px"}}>
								<div className="row fixed_header_div">
			                        <div className="col-4" style={{textAlign: "center", wordWrap: "break-word", borderRightWidth: "0px"}}>{i18next.t("fileName")}</div>
									<div className="col-1 no-left-right-margin" style={{textAlign: "center", wordWrap: "break-word", paddingLeft: "2px", borderRightWidth: "0px"}}>{i18next.t("dataSize")}</div>
									<div className="col-2" style={{textAlign: "center", wordWrap: "break-word", borderRightWidth: "0px"}}>{i18next.t("description")}</div>
			                        <div className="col-2" style={{textAlign: "center", wordWrap: "break-word", borderRightWidth: "0px"}}>{i18next.t("archiveDate")}</div>
						    		<div className="col-2" style={{textAlign: "center", wordWrap: "break-word", borderRightWidth: "0px"}}>{i18next.t("creatorUserId")}</div>
						    		<div className="col-1" style={{textAlign: "center", wordWrap: "break-word"}}>{i18next.t("actionsLabel")}</div>
						    	</div>
						    	<div className="data-list" style={{marginTop: "5px"}}>
									{
					        			this.props.docs.data.map((doc, index) => {
										    return (<div key={index} className="row top-bottom-padding-4 place-items" style={{wordWrap: "break-word"}}>
						                            <div className="col-4 place-items" onClick={this.getSelectedDoc.bind(this, doc)} style={{textAlign: "left", cursor: "pointer"}}>{doc.fileName}</div>
										    		<div className="col-1 place-items" onClick={this.getSelectedDoc.bind(this, doc)} style={{textAlign: "right", cursor: "pointer"}}>{this.convertSize(doc.dataSize)}</div>
										    		<div className="col-2 place-items" onClick={this.getSelectedDoc.bind(this, doc)} style={{textAlign: "center", cursor: "pointer"}}>{doc.description}</div>
						                            <div className="col-2 place-items" onClick={this.getSelectedDoc.bind(this, doc)} style={{textAlign: "center", cursor: "pointer"}}>{this.props.actions.formatDate(doc.archiveDate)}</div>
										    		<div className="col-2 place-items" onClick={this.getSelectedDoc.bind(this, doc)} style={{textAlign: "center", cursor: "pointer"}}>{doc.creatorUserId}</div>
										    		<div className="col-1" style={{textAlign: "center", paddingRight: "0px"}}>
										    			<img className="img" style={{cursor: "pointer"}} onClick={this.handleDownloadFile.bind(this, doc)} src={downloadFile} width="40" height="30" alt={downloadFile}/>
										    		</div>
										    </div>)
										})
									}
								</div>
							</div>
						</div>
						: <div className="loading-div"><div className="loading-div-inner"><i className="fa fa-spinner fa-pulse fa-5x"></i></div></div>
	        		}
				</div>
				{
					this.numPages() === 0
					? null
					: <div className={useIEStyle}>
						<div className="row">
							<div className="btn-group dropup" style={{minWidth: "5rem"}}>
								<button type="button" onClick={this.handleShowCountForm} className="btn btn-info dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.showItemsCountForm}>
									{
										this.props.page.size
									}
								</button>
								<div className={itemsCountFormOpen} style={{minWidth: "5rem"}}>
									{
										this.state.elementsCountArray.map((value, index) => {
											return (<span key={index} className="dropdown-item" style={{cursor: "pointer"}} onClick={this.handleCountChanged.bind(this, value)}>{value}</span>)
										})
									}
								</div>
							</div>

							<div  className="row">
								<div className={this.props.page.number === 0 ? "col-1 cursor-default opacity-05" : "col-1 cursor-pointer opacity-1"} onClick={this.firstPage}>
									<img className="img" style={{marginTop: "5px"}} src={first} width="20" height="26" alt={first}/>
								</div>
								<div className={this.props.page.number === 0 ? "col-1 cursor-default opacity-05" : "col-1 cursor-pointer opacity-1"} style={{paddingTop: "10px"}} onClick={this.prevPage}>
									<i className="fa fa-chevron-left"></i>
								</div>
								<div className="col-1" style={{paddingTop: "10px"}}>
									{
										this.props.page.number + 1
									}
								</div>
								<span style={{paddingTop: "10px"}}> / </span>
								<div className="col-1" style={{paddingTop: "10px"}}>
									{
										this.numPages()
									}
								</div>
								<div className={this.props.page.number === (this.numPages() - 1) ? "col-1 cursor-default opacity-05" : "col-1 cursor-pointer opacity-1"} style={{paddingTop: "10px"}} onClick={this.nextPage}>
									<i className="fa fa-chevron-right"></i>
								</div>
								<div className={this.props.page.number === (this.numPages() - 1) ? "col-1 cursor-default opacity-05" : "col-1 cursor-pointer opacity-1"} onClick={this.lastPage}>
									<img className="img" style={{marginTop: "5px"}} src={last} width="20" height="26" alt={last}/>
								</div>
							</div>
						</div>
					</div>
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer,
        docs: state.docsWithProcessStatusReducer,
        page: state.processStatusPagePagingReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	formatDate, getMetadata, fromBytes,
    	setALLDocsWithProcessStatusPaginationParams,
    	setProcessStatusPageNumber, setProcessStatusPageSize,
		setProcessStatusPageTotalElements, setProcessStatusPageTotalPages,
		setProcessingStatus, setDocsWithProcessStatus,
		searchUrl, pageUrl, getObjectData,
		clearDocsWithProcessStatus
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DocsInProcessView);