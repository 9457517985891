const baseState = {
    number: 0,
    size: 20,
    totalElements: 0,
    totalPages: 0,
    docsInArchPaginationParamsSetted: false,
    sortBy: "archiveDate",
    sortType: "desc"
}

const mainPagePagingReducer = (state = baseState, { type, payload }) => {

    switch (type) {
        case "SET_MAIN_PAGE_NUMBER": {
            return {
                ...state,
                number: payload
            }
        }

        case "SET_MAIN_PAGE_SIZE": {
            return {
                ...state,
                size: payload
            }
        }

        case "SET_MAIN_TOTAL_ELEMENTS": {
            return {
                ...state,
                totalElements: payload
            }
        }

        case "SET_MAIN_TOTAL_PAGES": {
            return {
                ...state,
                totalPages: payload
            }
        }

        case "SET_SORT_BY": {
            return {
                ...state,
                sortBy: payload
            }
        }

        case "SET_SORT_TYPE": {
            return {
                ...state,
                sortType: payload
            }
        }

        case "SET_ALL_DOCS_IN_ARCH_PAGINATION_PARAMS": {
            return {
                ...state,
                number: payload.number,
                size: payload.size,
                totalElements: payload.totalElements,
                totalPages: payload.totalPages,
                docsInArchPaginationParamsSetted: true
            }
        }

        default:
            return state;
    }
}

export default mainPagePagingReducer