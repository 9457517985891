import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import { setSelectedStatusForDocsWithWrongStatus, setStatusValuesForDocsWithWrongStatus, setALLDocsWithWrongStatusPaginationParams, setWrongStatusPageNumber, setWrongStatusPageSize,
			setWrongStatusPageTotalElements, setWrongStatusPageTotalPages, setProcessingStatus, setDocsWithWrongStatus, setActionResultArr,
			setShowActionResult, setActionResultData, setSelectedAll } from '../actions/localStates'

import { getMetadata, deleteObject, publishObject } from '../actions/api'

import { formatDate, searchUrl, pageUrl, fromBytes, formatDateWithoutTime } from '../actions/helpers';

import first from '../img/first.svg'
import last from '../img/last.svg'
// import downloadFile from '../img/download-file.svg'
// import verifyDoc from '../img/verify-doc.svg'

class DocsWithWrongStatusView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showDropDown: false,
			arr: this.props.docs.statusValuesArr || [],
			activeElement: this.props.docs.selectedStatus || "",
			elementsCountArray: [10, 20, 50],
	    	showItemsCountForm: false,
	    	current_page: 1,
			records_per_page: this.props.page.size,
			checkedArr: [],
			success: 0,
			failed: 0
		};

		this.handleShowDropDown = this.handleShowDropDown.bind(this);
		this.handleShowCountForm = this.handleShowCountForm.bind(this);
		this.handleSelectAll = this.handleSelectAll.bind(this);

		this.prevPage = this.prevPage.bind(this);
		this.nextPage = this.nextPage.bind(this);
		this.firstPage = this.firstPage.bind(this);
		this.lastPage = this.lastPage.bind(this);
		this.changePage = this.changePage.bind(this);
		this.numPages = this.numPages.bind(this);

		this.handleDeleteDocs = this.handleDeleteDocs.bind(this);
		this.handlePublishDocs = this.handlePublishDocs.bind(this);
		this.deleteDocs = this.deleteDocs.bind(this);
		this.publishDocs = this.publishDocs.bind(this);

		this.generateMessage = this.generateMessage.bind(this);
	}

	handleShowDropDown () {
		document.activeElement.blur();
        this.setState({
            showDropDown: !this.state.showDropDown
        });
	}

	getSelectedDoc (doc) {
		if (this.props.docs.selectedStatus !== "PUBLICATION_INITIATED") {
			return;
		}
		this.props.getDoc(doc)
	}

	generateMessage (status, action) {
		var str = "";

		if (status === "success") {
			str = i18next.t(action) + "\n" + i18next.t("docsCount") + this.state.success
		} else {
			str = i18next.t(action) + "\n" + i18next.t("docsCount") + this.state.failed
		}

		return str
	}

	async deleteDocs (id) {
		var _this = this, result;
		result = await _this.props.actions.deleteObject(_this.props.base.authServiceUrl, this.props.login.accessToken, id)

		if (result) {
			_this.setState({"success": _this.state.success += 1})
		} else {
			_this.setState({"failed": _this.state.failed += 1})
		}
	}

	async publishDocs (id) {
		var _this = this;
		await _this.props.actions.publishObject(_this.props.base.authServiceUrl, this.props.login.accessToken, id)
	}

	handleDeleteDocs () {
		var _this = this;
		var params = {
			page: this.props.page.number,
			size: this.props.page.size,
			status: this.props.docs.selectedStatus
		}

		this.props.actions.setProcessingStatus(true);

		async function processArray(array) {
			for (const item of array) {
				await _this.deleteDocs(_this.props.docs.data[item].id);
			}

			_this.props.actions.getMetadata(_this.props.base.authServiceUrl, _this.props.login.accessToken, _this.props.actions.searchUrl(params))
			.then((response) => {
				console.log(response);
				_this.props.actions.setALLDocsWithWrongStatusPaginationParams(response.page)
				_this.props.actions.setDocsWithWrongStatus(response.content)
				_this.setState({"checkedArr": []})
				_this.props.actions.setSelectedAll(false)
				_this.props.actions.setProcessingStatus(false)

				if (_this.state.success > 0 && _this.state.failed > 0) {
					_this.props.actions.setActionResultArr([
						{status: "success", action: "deletingDocs", message: _this.generateMessage("success", "deletingDocsSucsess")},
						{status: "failed", action: "deletingDocs", message: _this.generateMessage("failed", "deletingDocsFailed")}
					])
				} else if (_this.state.success > 0) {
					_this.props.actions.setActionResultData({status: "success", action: "deletingDocs", message: _this.generateMessage("success", "deletingDocsSucsess")})
				} else {
					_this.props.actions.setActionResultData({status: "failed", action: "deletingDocs", message: _this.generateMessage("failed", "deletingDocsFailed")})
				}

                _this.props.actions.setShowActionResult(true)

                _this.setState({"success": 0})
                _this.setState({"failed": 0})
			})
		}

		processArray(this.state.checkedArr)	
	}

	handlePublishDocs () {
		var _this = this;
		var params = {
			page: this.props.page.number,
			size: this.props.page.size,
			status: this.props.docs.selectedStatus
		}

		this.props.actions.setProcessingStatus(true);

		async function processArray(array) {
			for (const item of array) {
				await _this.publishDocs(_this.props.docs.data[item].id);
			}

			_this.props.actions.getMetadata(_this.props.base.authServiceUrl, _this.props.login.accessToken, _this.props.actions.searchUrl(params))
			.then((response) => {
				console.log(response);
				_this.props.actions.setALLDocsWithWrongStatusPaginationParams(response.page)
				_this.props.actions.setDocsWithWrongStatus(response.content)
				_this.setState({"checkedArr": []})
				_this.props.actions.setSelectedAll(false)
				_this.props.actions.setProcessingStatus(false)

				if (_this.state.success > 0 && _this.state.failed > 0) {
					_this.props.actions.setActionResultArr([
						{status: "success", action: "publishingDocs", message: _this.generateMessage("success", "publishingDocsSucsess")},
						{status: "failed", action: "publishingDocs", message: _this.generateMessage("failed", "publishingDocsFailed")}
					])
				} else if (_this.state.success > 0) {
					_this.props.actions.setActionResultData({status: "success", action: "publishingDocs", message: _this.generateMessage("success", "publishingDocsSucsess")})
				} else {
					_this.props.actions.setActionResultData({status: "failed", action: "publishingDocs", message: _this.generateMessage("failed", "publishingDocsFailed")})
				}

                _this.props.actions.setShowActionResult(true)

                _this.setState({"success": 0})
                _this.setState({"failed": 0})
			})
		}

		processArray(this.state.checkedArr)	
	}

	handleCheck (index) {
		var checked = this.state.checkedArr;

		if (!checked.includes(index)) {
			checked.push(index)

			if (checked.length === this.props.docs.data.length) {
				this.props.actions.setSelectedAll(true)
			}
		} else {
			const num = checked.indexOf(index);
			if (num > -1) {
			  checked.splice(num, 1);
			  this.props.actions.setSelectedAll(false)
			}
		}

		this.setState({"checkedArr": checked})

		console.log(checked)
	}

	convertSize (size) {
		var res, str = "";
		res = this.props.actions.fromBytes(size);
		str = res.value + " " + i18next.t(res.unit);
		return str
	}

	handleSelectAll () {
		var i, arr = [];

		if (this.state.checkedArr.length === this.props.docs.data.length) {
			this.props.actions.setSelectedAll(false)
			this.setState({"checkedArr": []})
		} else {
			for (i = 0; i < this.props.docs.data.length; i += 1) {
				arr.push(i)
			}
			
			this.props.actions.setSelectedAll(true)
			this.setState({"checkedArr": arr})
		}
	}

	containElement (index) {
		var num = this.state.checkedArr.indexOf(index);

		if (num > -1) {
			return true;
		} else {
			return false;
		}
	}

	handleChangeState (value, index) {
		var arr = this.state.arr;
		var params = {
			page: this.props.page.number,
			size: this.props.page.size,
			status: value
		}

        document.activeElement.blur();
        this.setState({
            showDropDown: !this.state.showDropDown
        });

        this.props.actions.setSelectedStatusForDocsWithWrongStatus(value);

		arr.splice(index, 1);
		arr.push(this.state.activeElement);

		this.setState({"checkedArr": []})
		this.props.actions.setSelectedAll(false)

		this.setState({
            activeElement: value
        });

		this.props.actions.setStatusValuesForDocsWithWrongStatus(arr);
		this.props.actions.getMetadata(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.actions.searchUrl(params))
		.then((response) => {
			if (response === null) {
				this.props.actions.setIsLoggedIn(false);
				return
			}
			this.props.actions.setDocsWithWrongStatus(response.content)
			this.props.actions.setALLDocsWithWrongStatusPaginationParams(response.page)
		})

        console.log(this.state);
	}

	handleShowCountForm () {
		this.setState({showItemsCountForm: !this.state.showItemsCountForm})
	}

	componentDidMount() {
		// var i, tmpArr = [];

		var params = {
			page: this.props.page.number,
			size: this.props.page.size,
			status: this.props.docs.selectedStatus
		}

		if (this.props.page.docsInArchPaginationParamsSetted) {

		    this.setState({current_page: this.props.page.number});

			this.props.actions.getMetadata(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.actions.searchUrl(params))
			.then((response) => {
				if (response === null) {
					this.props.actions.setIsLoggedIn(false);
					return
				}
				this.props.actions.setDocsWithWrongStatus(response.content)
				this.props.actions.setALLDocsWithWrongStatusPaginationParams(response.page)
			})
		} else {
			this.props.actions.setProcessingStatus(true)
			this.props.actions.getMetadata(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.actions.searchUrl(params))
			.then((response) => {
				console.log(response);
				this.props.actions.setALLDocsWithWrongStatusPaginationParams(response.page)
				this.props.actions.setDocsWithWrongStatus(response.content)
				this.props.actions.setProcessingStatus(false)
			})
		}
	}

	handleCountChanged (value) {
		var page
		if (this.props.page.number < 1) page = 1;
	    if (this.props.page.number > this.numPages()) page = this.numPages();
		var params = {
			page: page - 1,
			size: value,
			status: this.props.docs.selectedStatus
		}
		this.props.actions.setWrongStatusPageSize(value);
		this.setState({showItemsCountForm: !this.state.showItemsCountForm});

		this.props.actions.getMetadata(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.actions.searchUrl(params))
		.then((response) => {
			this.props.actions.setALLDocsWithWrongStatusPaginationParams(response.page)
			this.props.actions.setDocsWithWrongStatus(response.content)
			this.props.actions.setWrongStatusPageSize(response.page.size)
			this.numPages();
			this.changePage(this.props.page.number);
		})
	}

	prevPage ()	{
		if (this.props.page.number > 0) {
	        this.changePage(this.props.page.number - 1);
	    }
	}

	firstPage ()	{
	    this.changePage(0);
	}

	lastPage ()	{
	    this.changePage(this.numPages() - 1);
	}

	nextPage () {
		if (this.props.page.number < this.numPages()) {
	        this.changePage(this.props.page.number + 1);
	    }
	}

	changePage (page) {
	    if (page < 0) page = 0;
	    if (page >= this.numPages()) page = this.numPages() - 1;

	    var params = {
			page: page,
			size: this.props.page.size,
			status: this.props.docs.selectedStatus
		}

		if (page === this.props.page.number) {
	    	return;
	    }

		this.props.actions.getMetadata(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.actions.searchUrl(params))
		.then((response) => {
			this.props.actions.setDocsWithWrongStatus(response.content)
			this.props.actions.setALLDocsWithWrongStatusPaginationParams(response.page)
		})
	}

	numPages () {
	    return Math.ceil(this.props.page.totalElements / this.props.page.size);
	}

	componentWillUnmount () {
	}

	render() {
		let itemsFormOpen = 'dropdown-menu'
		let itemsCountFormOpen = 'dropdown-menu'
		let useIEStyle = 'offset-8 bottomright'

		let isEnabled = (this.state.checkedArr.length > 0 && !this.props.base.processing) ? false : true;

		let activeStyle = "col-2 place-items"

		if (this.props.docs.selectedStatus === "PUBLICATION_INITIATED") {
			activeStyle = "col-2 place-items cursor-pointer"
		}

		if (this.state.showDropDown) {
			itemsFormOpen = 'dropdown-menu show'
		}

		if (this.state.showItemsCountForm) {
			itemsCountFormOpen = 'dropdown-menu show'
		}

		if (this.props.base.isIEBrowser) {
			useIEStyle = 'offset-8 bottomright-ie'
		}
		return (
			<div>
				<div className="no-left-right-margin">
					{
						this.props.docs.data
						? <div>
							{
		                    	this.state.arr.length > 0
		                    	? <div className="col-md-12 col-xs-12 col-sm-12" style={{marginTop: "10px", marginBottom: "10px", paddingLeft: "0px"}}>
				                    <div className="btn-group dropdown" style={{width: "250px"}}>
										<button type="button" onClick={this.handleShowDropDown} className="btn btn-outline-info btn-block dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.showDropDown}>
												{i18next.t(this.state.activeElement)}
										</button>
										<div className={itemsFormOpen} style={{minWidth: "5rem", width: "100%"}}>
											{
												this.state.arr.map((value, index) => {
													return (<span key={index} className="dropdown-item" style={{cursor: "pointer"}} onClick={this.handleChangeState.bind(this, value, index)} value={value}>{i18next.t(value)}</span>)
												})
											}
										</div>
									</div>
									<div className="float-right">
										{
											this.props.docs.selectedStatus === "PUBLICATION_INITIATED"
											? <button type="button" className="btn btn-outline-info" disabled={isEnabled} style={{marginRight: "5px"}} onClick={this.handlePublishDocs}>
												{i18next.t("publishLabel")}
											</button>
											: null
										}
										<button type="button" className="btn btn-outline-info" disabled={isEnabled} onClick={this.handleDeleteDocs}>
											{i18next.t("deleteLabel")}
										</button>
									</div>									
			                    </div>
			                    : null
		                    }
							<div className="col-12 no-left-right-margin" style={{paddingRight: "15px"}}>
								<div className="row fixed_header_div">
			                        <div className="col-4 no-left-right-margin" style={{textAlign: "center", paddingTop: "0px", paddingBottom: "0px", borderRightWidth: "0px"}}>
			                        	<div className="row col-12 no-left-right-margin" style={{borderWidth: "0px", borderStyle: "none"}}>
					                        <div className="col-2 no-left-right-margin abc-checkbox" style={{borderWidth: "0px", borderStyle: "none", alignSelf: "top"}}>
												<div className="checkbox" style={{borderWidth: "0px", borderStyle: "none", padding: "0px"}}>
							                        <input id="checkbox1" onChange={this.handleSelectAll} checked={this.props.docs.selectedAll} type="checkbox" />
							                        <label htmlFor="checkbox1">
							                        </label>
							                    </div>

											</div>

											<div className="col-10 no-left-right-margin" style={{borderWidth: "0px", borderStyle: "none", paddingTop: "0px", wordWrap: "break-word"}}>
												{i18next.t("fileName")}
											</div>
										</div>
									</div>
									<div className="col-2 no-left-right-margin" style={{textAlign: "center", wordWrap: "break-word", paddingLeft: "2px", borderRightWidth: "0px"}}>{i18next.t("dataSize")}</div>
									<div className="col-2" style={{textAlign: "center", wordWrap: "break-word", borderRightWidth: "0px"}}>{i18next.t("description")}</div>
			                        <div className="col-2" style={{textAlign: "center", wordWrap: "break-word", borderRightWidth: "0px"}}>{i18next.t("archiveDate")}</div>
						    		<div className="col-2" style={{textAlign: "center", wordWrap: "break-word"}}>{i18next.t("creatorUserId")}</div>
						    	</div>
						    	<div className="data-list" style={{marginTop: "5px"}}>
									{
					        			this.props.docs.data.map((doc, index) => {
										    return (<div key={index} className="row top-bottom-padding-8 place-items" style={{wordWrap: "break-word"}}>
						                            <div className="col-4 place-items no-left-right-margin" style={{textAlign: "center"}}>
						                            	<div className="row col-12 no-left-right-margin">
							                            	<div className="col-2 no-left-right-margin abc-checkbox" style={{textAlign: "center", paddingRight: "0px"}}>

							                            		<div className="checkbox" style={{borderWidth: "0px", borderStyle: "none"}}>
											                        <input id={index} checked={this.containElement(index)} onChange={this.handleCheck.bind(this, index)} type="checkbox" />
											                        <label htmlFor={index}>
											                        </label>
											                    </div>


												    		</div>
												    		<div className={this.props.docs.selectedStatus === "PUBLICATION_INITIATED" ? "col-10 no-left-right-margin cursor-pointer" : "col-10 no-left-right-margin cursor-default"} style={{textAlign: "left"}} onClick={this.getSelectedDoc.bind(this, doc)}>
												    			{doc.fileName || ""}
												    		</div>
												    	</div>
											    	</div>
										    		<div className={activeStyle} onClick={this.getSelectedDoc.bind(this, doc)} style={{textAlign: "right"}}>{this.convertSize(doc.dataSize)}</div>
										    		<div className={activeStyle} onClick={this.getSelectedDoc.bind(this, doc)} style={{textAlign: "center"}}>{doc.description || ""}</div>
						                            <div className={activeStyle} onClick={this.getSelectedDoc.bind(this, doc)} style={{textAlign: "center"}}>{this.props.actions.formatDate(doc.archiveDate)}</div>
										    		<div className={activeStyle} onClick={this.getSelectedDoc.bind(this, doc)} style={{textAlign: "center"}}>{doc.creatorUserId || ""}</div>
										    </div>)
										})
									}
								</div>
							</div>
						</div>
						: <div className="loading-div"><div className="loading-div-inner"><i className="fa fa-spinner fa-pulse fa-5x"></i></div></div>
	        		}
				</div>
				{
					this.numPages() === 0
					? null
					: <div className={useIEStyle}>
						<div className="row">
							<div className="btn-group dropup" style={{minWidth: "5rem"}}>
								<button type="button" onClick={this.handleShowCountForm} className="btn btn-info dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.showItemsCountForm}>
									{
										this.props.page.size
									}
								</button>
								<div className={itemsCountFormOpen} style={{minWidth: "5rem"}}>
									{
										this.state.elementsCountArray.map((value, index) => {
											return (<span key={index} className="dropdown-item" style={{cursor: "pointer"}} onClick={this.handleCountChanged.bind(this, value)}>{value}</span>)
										})
									}
								</div>
							</div>

							<div  className="row">
								<div className={this.props.page.number === 0 ? "col-1 cursor-default opacity-05" : "col-1 cursor-pointer opacity-1"} onClick={this.firstPage}>
									<img className="img" style={{marginTop: "5px"}} src={first} width="20" height="26" alt={first}/>
								</div>
								<div className={this.props.page.number === 0 ? "col-1 cursor-default opacity-05" : "col-1 cursor-pointer opacity-1"} style={{paddingTop: "10px"}} onClick={this.prevPage}>
									<i className="fa fa-chevron-left"></i>
								</div>
								<div className="col-1" style={{paddingTop: "10px"}}>
									{
										this.props.page.number + 1
									}
								</div>
								<span style={{paddingTop: "10px"}}> / </span>
								<div className="col-1" style={{paddingTop: "10px"}}>
									{
										this.numPages()
									}
								</div>
								<div className={this.props.page.number === (this.numPages() - 1) ? "col-1 cursor-default opacity-05" : "col-1 cursor-pointer opacity-1"} style={{paddingTop: "10px"}} onClick={this.nextPage}>
									<i className="fa fa-chevron-right"></i>
								</div>
								<div className={this.props.page.number === (this.numPages() - 1) ? "col-1 cursor-default opacity-05" : "col-1 cursor-pointer opacity-1"} onClick={this.lastPage}>
									<img className="img" style={{marginTop: "5px"}} src={last} width="20" height="26" alt={last}/>
								</div>
							</div>
						</div>
					</div>
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer,
        docs: state.docsWithWrongStatusReducer,
        page: state.wrongStatusPagePagingReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	formatDate, setProcessingStatus,
    	setSelectedStatusForDocsWithWrongStatus,
		setStatusValuesForDocsWithWrongStatus,
		setALLDocsWithWrongStatusPaginationParams,
		setWrongStatusPageNumber, setWrongStatusPageSize,
		setWrongStatusPageTotalElements, setWrongStatusPageTotalPages,
		searchUrl, pageUrl, getMetadata, formatDateWithoutTime,
		setDocsWithWrongStatus, fromBytes, deleteObject,
		setActionResultArr, setShowActionResult, publishObject,
		setActionResultData, setSelectedAll
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DocsWithWrongStatusView);