const baseState = {
    isLoggedIn: false,
    login: "",
    password: "",
    accessToken: null,
    loggedInUserData: null,
    loggedInUserName: null,
    loggedInUserId: null,
    userData: null,
    urlForRedirection: "",
    twoFactorCode: "",
    is2fEnabled: false,
    twoFactorEnabled: false,
    update2fSettingsGrant: false,
    changePasswordGrant: false,
    twoFactorAvailabelChannels: null,
    showTwoFactorSettings: false,
    twoFactorChannelId: null,
    twoFactorSettings: null
}

const loginReducer = (state = baseState, { type, payload }) => {

    switch (type) {
    	case "SET_IS_LOGGED_IN": {
			return {
				...state,
				isLoggedIn: payload
			}
		}

		case "SET_LOGIN_INPUT": {
			return {
				...state,
				login: payload
			}
		}

		case "SET_PASSWORD_INPUT": {
			return {
				...state,
				password: payload
			}
		}

		case "SET_TOKEN": {
			return {
				...state,
				accessToken: payload
			}
		}

		case "SET_ALL_USER_FIELDS": {
			return {
				...state,
				userData: payload,
				twoFactorChannelId: payload.twoFactorChannelId || null,
				twoFactorSettings: payload.twoFactorSettings || null,
				confirmedProfile: payload.confirmedProfile || null
			}
		}

		case "CLEAR_ALL_USER_FIELDS": {
			return {
				...state,
				userData: null,
			    twoFactorChannelId: null,
				twoFactorSettings: null,
				confirmedProfile: null
			}
		}

		case "CLEAR_TOKEN": {
			return {
				...state,
				accessToken: null
			}
		}

		case "SET_LOGGED_IN_USER_DATA": {
			return {
				...state,
				loggedInUserData: payload,
				loggedInUserName: payload.userName,
				loggedInUserId: payload.firstName
			}
		}

		case "CLEAR_LOGGED_IN_USER_DATA": {
			return {
				...state,
				loggedInUserData: null,
				loggedInUserName: null,
				loggedInUserId: null
			}
		}

		case "SET_REDIRECT_URL": {
			return {
				...state,
				urlForRedirection: payload
			}
		}

		case "SET_TWO_FACTOR_CODE": {
			return {
				...state,
				twoFactorCode: payload
			}
		}

		case "SET_IS_2F_ENABLED": {
			return {
				...state,
				is2fEnabled: payload
			}
		}

		case "SET_TWO_FACTOR_ENABLED": {
			return {
				...state,
				twoFactorEnabled: payload
			}
		}

		case "CLEAR_TWO_FACTOR_ENABLED": {
			return {
				...state,
				twoFactorEnabled: false
			}
		}

		case "CLEAR_TWO_FACTOR_CODE": {
			return {
				...state,
				twoFactorCode: ""
			}
		}

		case "CHANGE_PASSWORD_GRANT": {
            return {
                ...state,
                changePasswordGrant: payload
            }
        }

        case "SET_UPDATE_2F_SETTINGS_GRANT": {
			return {
				...state,
				update2fSettingsGrant: payload
			}
		}

		case "SET_TWO_FACTOR_AVAILABEL_CHANNELS": {
			return {
				...state,
				twoFactorAvailabelChannels: payload
			}
		}

		case "SET_SHOW_TWO_FACTOR_SETTINGS": {
			return {
				...state,
				showTwoFactorSettings: payload
			}
		}

		case "SET_TWO_FACTOR_CHANNEL_ID": {
			return {
				...state,
				twoFactorChannelId: payload
			}
		}

		case "SET_TWO_FACTOR_SETTINGS": {
			return {
				...state,
				twoFactorSettings: payload
			}
		}

        default:
            return state;
    }
}

export default loginReducer