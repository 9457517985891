import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import { setTags } from '../actions/localStates'

class AddTagsPopup extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    	arr: this.props.docs.tags || [],
	    	currentTag: ""
	    }

	    this.handleAddTag = this.handleAddTag.bind(this);
	    this.handleTagChanged = this.handleTagChanged.bind(this);
	    this.pushTags = this.pushTags.bind(this);
	    this.cancel = this.cancel.bind(this);
	    this.keyUpHandler = this.keyUpHandler.bind(this, 'return');
	    this.handleTagsKeyUp = this.handleTagsKeyUp.bind(this, 'tagsInput');
	}

	componentDidMount() {
		document.addEventListener('keyup', this.keyUpHandler);
		document.addEventListener('keyup', this.handleTagsKeyUp);
	}

	componentWillUnmount() {
		document.removeEventListener('keyup', this.keyUpHandler);
		document.removeEventListener('keyup', this.handleTagsKeyUp);
	}

	cancel () {
		this.props.closeTagsPopup()
	}

	pushTags () {
		this.props.actions.setTags(this.state.arr)
		this.props.closeTagsPopup()
	}

	handleTagChanged (e) {
		this.setState({"currentTag": e.target.value})
	}

	handleAddTag () {
		document.activeElement.blur();
		var arr = this.state.arr;
		arr.push(this.state.currentTag)
		this.setState({"arr": arr})
		this.setState({"currentTag": ""})
	}

	handleDeleteTag (value) {
		var arr = this.state.arr;
		arr.splice(value, 1)
		this.setState({"arr": arr})
	}

	handleTagsKeyUp(refName, e) {
        if (e.keyCode === 13 && refName === "tagsInput") {
        	console.log(refName);
        	e.preventDefault()
			e.stopPropagation();

			if (e.nativeEvent !== undefined) {
				e.nativeEvent.stopImmediatePropagation();
			}

			document.getElementById("add").click();
			document.activeElement.blur()
        }
    }

	keyUpHandler(refName, e) {
        if (e.keyCode === 13 && refName === "return") {
        	console.log(refName);
        	

			if (this.state.arr.length > 0) {
				e.preventDefault()
				e.stopPropagation();

				if (e.nativeEvent !== undefined) {
					e.nativeEvent.stopImmediatePropagation();
				}
				document.getElementById("push").click();
				document.activeElement.blur()
			} else {
				e.preventDefault()
				e.stopPropagation();

				if (e.nativeEvent !== undefined) {
					e.nativeEvent.stopImmediatePropagation();
				}
				document.getElementById("close").click();
				document.activeElement.blur()
			}
        }
    }

	render() {
		return (
			<div className='popup'>
				<div className="popup_inner_tags">
					<div>
						<div>
							<h4 style={{marginLeft: "15px", marginTop: "15px"}}>{i18next.t("tags")}</h4>
							<div className="col-12 input-group row">
							  <input className="form-control" onChange={this.handleTagChanged} value={this.state.currentTag} onKeyUp={this.handleTagsKeyUp} ref="tagsInput" />
							  <button id="add" className="btn btn-outline-info" disabled={this.state.currentTag.length > 0 ? false : true} style={{marginLeft: "5px"}} onClick={this.handleAddTag}>{i18next.t("addLabel")}</button>
							</div>
							{
								this.state.arr.length > 0
								? <div className="tags-list" style={{marginLeft: "15px", marginRight: "15px", marginTop: "10px"}}>
										{
											this.state.arr.map((value, index) => {
												return <label key={index} className="span-tags">{value}<i style={{cursor: "pointer", marginLeft: "5px", color: "#b5b1af"}} onClick={this.handleDeleteTag.bind(this, index)} className="fa fa-remove"></i></label>
											})
										}
									</div>
								: <div className="tags-list" style={{marginLeft: "15px", marginRight: "15px", marginTop: "10px"}}>
								</div>
							}
							<div style={{float: "right", marginRight: "15px", paddingTop: "10px", paddingBottom: "10px"}}>
								<button id="push" className="btn btn-info" style={{marginLeft: "5px", cursor: "pointer"}} disable={this.state.arr.length > 0 ? "false" : "true"} onClick={this.pushTags}>{i18next.t("okLabel")}</button>
								<button id="close" className="btn btn-outline-info" style={{marginLeft: "5px"}} onClick={this.cancel}>{i18next.t("cancelLabel")}</button>
							</div>
	                    </div>
	                </div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer,
        docs: state.addDocReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	setTags
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTagsPopup);