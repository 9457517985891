const baseState = {
    number: 0,
    size: 20,
    totalElements: 0,
    totalPages: 0,
    docsWithProcessStatusPaginationParamsSetted: false
}

const processStatusPagePagingReducer = (state = baseState, { type, payload }) => {

    switch (type) {
        case "SET_PROCESS_STATUS_PAGE_NUMBER": {
            return {
                ...state,
                number: payload
            }
        }

        case "SET_PROCESS_STATUS_PAGE_SIZE": {
            return {
                ...state,
                size: payload
            }
        }

        case "SET_PROCESS_STATUS_TOTAL_ELEMENTS": {
            return {
                ...state,
                totalElements: payload
            }
        }

        case "SET_PROCESS_STATUS_TOTAL_PAGES": {
            return {
                ...state,
                totalPages: payload
            }
        }

        case "SET_ALL_DOCS_WITH_PROCESS_STATUS_PAGINATION_PARAMS": {
            return {
                ...state,
                number: payload.number,
                size: payload.size,
                totalElements: payload.totalElements,
                totalPages: payload.totalPages,
                docsWithProcessStatusPaginationParamsSetted: true
            }
        }

        default:
            return state;
    }
}

export default processStatusPagePagingReducer