const baseState = {
    fileForUpload: null,
    techSignatureArr: null,
    uploadDataResult: null,
    uploadIsDone: false,
    activityLog: null,
    archiveDate: "",
    authors: [],
    autoDefinedDataMimeType: "",
    comment: null,
    confirmedDate: "",
    creatorUserId: "",
    creatorUserInfo: null,
    dataMimeType: null,
    dataSize: 0,
    description: null,
    fileName: null,
    id: "",
    outerId: null,
    ownDate: null,
    ownNumber: null,
    signers: [],
    tags: [],
    techSignatureVerifyResultsArr: null,
    uploadSignersDataResult: null,
    isDocEdit: false,
    docSignaures: null
}

const addDocReducer = (state = baseState, { type, payload }) => {

    switch (type) {
    	case "SET_DOC_FOR_UPLOAD": {
			return {
				...state,
				fileForUpload: payload
			}
		}

		case "SET_TECH_SIGNATURE_ARR": {
			return {
				...state,
				techSignatureArr: payload
			}
		}

		case "SET_UPLOAD_SIGNERS_DATA_RESULT_ARR": {
			return {
				...state,
				uploadSignersDataResult: payload
			}
		}

		case "CLEAR_UPLOAD_SIGNERS_DATA_RESULT_ARR": {
			return {
				...state,
				uploadSignersDataResult: null
			}
		}

		case "SET_UPLOAD_DATA_RESULT": {
			return {
				...state,
				uploadDataResult: payload,
				archiveDate: payload.archiveDate,
			    authors: payload.authors,
			    autoDefinedDataMimeType: payload.autoDefinedDataMimeType,
			    comment: payload.comment,
			    confirmedDate: payload.confirmedDate,
			    creatorUserId: payload.creatorUserId,
			    creatorUserInfo: payload.creatorUserInfo,
			    dataMimeType: payload.dataMimeType,
			    dataSize: payload.dataSize,
			    description: payload.description,
			    fileName: payload.fileName,
			    id: payload.id,
			    outerId: payload.outerId,
			    ownDate: payload.ownDate,
			    ownNumber: payload.ownNumber,
			    signers: payload.signers,
			    tags: payload.tags
			}
		}

		case "SET_UPLOAD_DATA_IS_DONE": {
			return {
				...state,
				uploadIsDone: payload
			}
		}

		case "CLEAR_UPLOAD_DATA_RESULT": {
			return {
				...state,
				uploadDataResult: null,
				archiveDate: "",
			    authors: [],
			    autoDefinedDataMimeType: "",
			    comment: null,
			    confirmedDate: "",
			    creatorUserId: "",
			    creatorUserInfo: null,
			    dataMimeType: null,
			    dataSize: 0,
			    description: null,
			    fileName: null,
			    id: "",
			    outerId: null,
			    ownDate: null,
			    ownNumber: null,
			    signers: [],
			    tags: []
			}
		}

		case "SET_TECH_SIGNATURE_VERIFY_RESULTS_ARR": {
			return {
				...state,
				techSignatureVerifyResultsArr: payload
			}
		}

		case "CLEAR_TECH_SIGNATURE_VERIFY_RESULTS_ARR": {
			return {
				...state,
				techSignatureVerifyResultsArr: null
			}
		}

		case "SET_ARCHIVE_DATE": {
			return {
				...state,
				archiveDate: payload
			}
		}

		case "CLEAR_ARCHIVE_DATE": {
			return {
				...state,
				archiveDate: ""
			}
		}

		case "SET_AUTHORS": {
			return {
				...state,
				authors: payload
			}
		}

		case "CLEAR_AUTHORS": {
			return {
				...state,
				authors: []
			}
		}

		case "SET_AUTO_DEFINED_MIME_TYPE": {
			return {
				...state,
				autoDefinedDataMimeType: payload
			}
		}

		case "CLEAR_AUTO_DEFINED_MIME_TYPE": {
			return {
				...state,
				autoDefinedDataMimeType: ""
			}
		}

		case "SET_COMMENT": {
			return {
				...state,
				comment: payload
			}
		}

		case "CLEAR_COMMENT": {
			return {
				...state,
				comment: null
			}
		}

		case "SET_CONFIRMED_DATE": {
			return {
				...state,
				confirmedDate: payload
			}
		}

		case "CLEAR_CONFIRMED_DATE": {
			return {
				...state,
				confirmedDate: ""
			}
		}

		case "SET_CREATOR_USER_ID": {
			return {
				...state,
				creatorUserId: payload
			}
		}

		case "CLEAR_CREATOR_USER_ID": {
			return {
				...state,
				creatorUserId: ""
			}
		}

		case "SET_CREATOR_USER_INFO": {
			return {
				...state,
				creatorUserInfo: payload
			}
		}

		case "CLEAR_CREATOR_USER_INFO": {
			return {
				...state,
				creatorUserInfo: null
			}
		}

		case "SET_DATA_MIME_TYPE": {
			return {
				...state,
				dataMimeType: payload
			}
		}

		case "CLEAR_DATA_MIME_TYPE": {
			return {
				...state,
				dataMimeType: null
			}
		}

		case "SET_DATA_SIZE": {
			return {
				...state,
				dataSize: payload
			}
		}

		case "CLEAR_DATA_SIZE": {
			return {
				...state,
				dataSize: 0
			}
		}

		case "SET_DESCRIPTION": {
			return {
				...state,
				description: payload
			}
		}

		case "CLEAR_DESCRIPTION": {
			return {
				...state,
				description: null
			}
		}

		case "SET_FILE_NAME": {
			return {
				...state,
				fileName: payload
			}
		}

		case "CLEAR_FILE_NAME": {
			return {
				...state,
				fileName: null
			}
		}

		case "SET_ID": {
			return {
				...state,
				id: payload
			}
		}

		case "CLEAR_ID": {
			return {
				...state,
				id: ""
			}
		}

		case "SET_OUTER_ID": {
			return {
				...state,
				outerId: payload
			}
		}

		case "CLEAR_OUTER_ID": {
			return {
				...state,
				outerId: null
			}
		}

		case "SET_OWN_DATE": {
			return {
				...state,
				ownDate: payload
			}
		}

		case "CLEAR_OWN_DATE": {
			return {
				...state,
				ownDate: null
			}
		}

		case "SET_OWN_NUMBER": {
			return {
				...state,
				ownNumber: payload
			}
		}

		case "CLEAR_OWN_NUMBER": {
			return {
				...state,
				ownNumber: null
			}
		}

		case "SET_SIGNERS": {
			return {
				...state,
				signers: payload
			}
		}

		case "CLEAR_SIGNERS": {
			return {
				...state,
				signers: []
			}
		}

		case "SET_TAGS": {
			return {
				...state,
				tags: payload
			}
		}

		case "CLEAR_TAGS": {
			return {
				...state,
				tags: []
			}
		}

		case "SET_ACTIVITY_LOG": {
			return {
				...state,
				activityLog: payload
			}
		}

		case "SET_IS_DOC_EDIT": {
			return {
				...state,
				isDocEdit: payload
			}
		}

		case "SET_DOC_SIGNATURES": {
			return {
				...state,
				docSignaures: payload
			}
		}

		case "CLEAR_DOC_SIGNATURES": {
			return {
				...state,
				docSignaures: null
			}
		}

        default:
            return state;
    }
}

export default addDocReducer