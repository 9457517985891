const baseState = {
    data: null
}

const docsWithProcessStatusReducer = (state = baseState, { type, payload }) => {

    switch (type) {
        case "SET_DOCS_WITH_PROCESS_STATUS_DATA": {
            return {
                ...state,
                data: payload
            }
        }

        case "CLEAR_DOCS_WITH_PROCESS_STATUS_DATA": {
            return {
                ...state,
                data: null
            }
        }

        default:
            return state;
    }
}

export default docsWithProcessStatusReducer