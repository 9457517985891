import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getMetadata, getTechSignature, getObjectTechSignatureVerificationResults, getObjectSignatureVerificationResults, getObjectMetadata, getObjectStatus, getSignature } from '../actions/api'
import { setDocsWithProcessStatus, setDocsWithWrongStatus, setProcessingStatus, clearUploadResultData, setSetTechSignatureArr, setTachSignatureVerfyResultsArr, setUploadSignersDataResult,
			setUploadResultData, setActivityLog, setUploadDataIsDone, changeSelectedTab, setDocSignatures, setActionResultData, setShowActionResult, clearAllAuthorFields,
			setPreviousTab } from '../actions/localStates'

import DocsWithWrongStatusView from '../components/DocsWithWrongStatusView'

class DocsWithWrongStatusContainer extends Component {
	constructor() {
		super();
		this.state = {

		};

		this.getSelectedDoc = this.getSelectedDoc.bind(this);
	}

	getSelectedDoc (doc) {
		this.props.actions.clearUploadResultData();
		this.props.actions.clearAllAuthorFields();

		this.props.actions.getObjectTechSignatureVerificationResults(this.props.base.authServiceUrl, this.props.login.accessToken, doc.id)
		.then((response) => {
			if (response.code !== undefined && response.code.length > 0) {
				this.props.actions.setProcessingStatus(false)
				
				this.props.actions.setActionResultData({status: "failed", action: "getDocData", message: response.message})
            	this.props.actions.setShowActionResult(true)

				return;
			}

			this.props.actions.setTachSignatureVerfyResultsArr(response)
			console.log(response)

			this.props.actions.getObjectSignatureVerificationResults(this.props.base.authServiceUrl, this.props.login.accessToken, doc.id)
			.then((response) => {
				if (response.code !== undefined && response.code.length > 0) {
					this.props.actions.setProcessingStatus(false)
					
					this.props.actions.setActionResultData({status: "failed", action: "getDocData", message: response.message})
            		this.props.actions.setShowActionResult(true)

					return;
				}

				this.props.actions.setUploadSignersDataResult(response)


                this.props.actions.getObjectMetadata(this.props.base.authServiceUrl, this.props.login.accessToken, doc.id)
				.then((response) => {
					if (response.code !== undefined && response.code.length > 0) {
						this.props.actions.setProcessingStatus(false)
						
						this.props.actions.setActionResultData({status: "failed", action: "getDocData", message: response.message})
        				this.props.actions.setShowActionResult(true)

						return;
					}

					this.props.actions.setUploadResultData(response)

					this.props.actions.getObjectStatus(this.props.base.authServiceUrl, this.props.login.accessToken, doc.id)
					.then((response) => {
						if (response.code !== undefined && response.code.length > 0) {
							this.props.actions.setProcessingStatus(false)
							
							this.props.actions.setActionResultData({status: "failed", action: "getDocData", message: response.message})
        					this.props.actions.setShowActionResult(true)
							return;
						}
						console.log(response);

						this.props.actions.setActivityLog(response)

						this.props.actions.setProcessingStatus(false)
						this.props.actions.setUploadDataIsDone(true)
						this.props.actions.setPreviousTab(2);
						this.props.actions.changeSelectedTab(1);
					})
				})
            })
		})
    }

	componentDidMount() {

	}

	componentWillUnmount () {
	}

	render() {
		return (
			<DocsWithWrongStatusView getDoc={this.getSelectedDoc} />
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	getMetadata, getSignature,
    	setDocsWithWrongStatus,
    	setProcessingStatus, setDocSignatures,
    	setDocsWithProcessStatus,
    	clearUploadResultData,
    	setSetTechSignatureArr,
    	setTachSignatureVerfyResultsArr, setUploadSignersDataResult,
		setUploadResultData, setActivityLog,
		setUploadDataIsDone, changeSelectedTab,
		getTechSignature, getObjectTechSignatureVerificationResults,
		getObjectSignatureVerificationResults,
		getObjectMetadata, getObjectStatus,
		setActionResultData, setShowActionResult,
		clearAllAuthorFields, setPreviousTab
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DocsWithWrongStatusContainer);