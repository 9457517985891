import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { queryUrl, formatDateForCalendar, formatDateWithoutTimeQueryStr, toBytes } from '../actions/helpers';
import { searchDataByQueryString } from '../actions/api';
import { setProcessingStatus, clearRecievedData, setRecievedData, setALLDocsInArchPaginationParams, setSearchString } from '../actions/localStates';

import DocsInArchSearchView from '../components/DocsInArchSearchView'

class DocsInArchSearchContainer extends Component {
	constructor(props) {
    super(props);

        this.state = {
        }

        this.searchRef = React.createRef();
        this.search = this.search.bind(this);

    }

	componentDidMount() {

	}

    search () {
        var id = "", desc = "", ownNumber = "", dateFrom = "", dateTo = "", date = "",
            confirmedDateFrom = "", confirmedDateTo = "", confirmedDate = "", fileNameForSearch = "",
            sizeForSearch = "", quetyStr = "";

        if (this.props.search.ownNumber.length > 0) {
            ownNumber = "ownNumber.keyword:" + this.props.search.ownNumber

            ownNumber = ownNumber.replace(/[.*+?^${}()\-|/[\]\\]/g, '\\$&');

            quetyStr +=  "(" + ownNumber + ")" 
        }

        if (this.props.search.dateFrom !== null) {
            dateFrom = this.props.actions.formatDateWithoutTimeQueryStr(this.props.search.dateFrom);
        }

        if (this.props.search.dateTo !== null) {
            dateTo = this.props.actions.formatDateWithoutTimeQueryStr(this.props.search.dateTo);
        }

        if (this.props.search.confirmedDateFrom !== null) {
            confirmedDateFrom = this.props.actions.formatDateWithoutTimeQueryStr(this.props.search.confirmedDateFrom);
        }

        if (this.props.search.confirmedDateTo !== null) {
            confirmedDateTo = this.props.actions.formatDateWithoutTimeQueryStr(this.props.search.confirmedDateTo);
        }

        if (this.props.search.dateTo !== null || this.props.search.dateFrom !== null) {
            date = "archiveDate:[" + ((dateFrom !== null && dateFrom.length > 0) ? dateFrom : "*") + " TO " + ((dateTo !== null && dateTo.length > 0) ? dateTo : "*") + "]"

            if (quetyStr.length > 0) {
                quetyStr += " AND (" + date + ")"
            } else {
                quetyStr += "(" + date + ")"
            }
        }

        if (this.props.search.description.length > 0) {
            var resultDesc = this.props.search.description.replace(/[.*+?^${}()|/[\]\\]/g, '\\$&');
            desc = "description:\"" + resultDesc + "\"~2"
            if (quetyStr.length > 0) {
                quetyStr += " AND (" + desc + ")"
            } else {
                quetyStr += "(" + desc + ")"
            }
        }

        if (this.props.search.id.length > 0) {
            if (this.props.search.id.length <= 39) {
                id = "id: *" + this.props.search.id + "*"
            } else {
                id = "id: \"" + this.props.search.id + "\""
            }

            if (quetyStr.length > 0) {
                quetyStr += " AND (" + id + ")"
            } else {
                quetyStr += "(" + id + ")"
            }
        }

        if (this.props.search.addedByMe) {
            if (quetyStr.length > 0) {
                quetyStr += " AND (creatorUserId.keyword: " + this.props.login.loggedInUserName + ")"
            } else {
                quetyStr += "(creatorUserId.keyword: " + this.props.login.loggedInUserName + ")"
            }
        }

        if (this.props.search.fileName.length > 0) {
            fileNameForSearch = "fileName: *" + this.props.search.fileName + "*"
            if (quetyStr.length > 0) {
                quetyStr += " AND (" + fileNameForSearch + ")"
            } else {
                quetyStr += "(" + fileNameForSearch + ")"
            }
        }

        if (this.props.search.confirmedDateTo !== null || this.props.search.confirmedDateFrom !== null) {
            confirmedDate = "confirmedDate:[" + ((confirmedDateFrom !== null && confirmedDateFrom.length > 0) ? confirmedDateFrom : "*") + " TO " + ((confirmedDateTo !== null && confirmedDateTo.length > 0) ? confirmedDateTo : "*") + "]"

            if (quetyStr.length > 0) {
                quetyStr += " AND (" + confirmedDate + ")"
            } else {
                quetyStr += "(" + confirmedDate + ")"
            }
        }

        if (this.props.search.tags.length > 0) {
            var resTags = this.props.search.tags.replace(/[.*+?^${}()|/[\]\\]/g, '\\$&');
            var tags = "tags.keyword:( \"" + resTags + "\" ) OR tags.keyword:(" + resTags + ") OR tags.keyword:" + resTags
            if (quetyStr.length > 0) {
                quetyStr += " AND (" + tags + ")"
            } else {
                quetyStr += "(" + tags + ")"
            }
        }

        if (this.props.search.sizeFrom.length > 0 || this.props.search.sizeTo.length > 0) {
            var sizeFromInBytes = "", sizeToInBytes = ""
            this.props.search.sizeFrom.length > 0 ? sizeFromInBytes = this.props.actions.toBytes(this.props.search.sizeFrom, this.props.search.selectedSizeFromUnit) : sizeFromInBytes = "";
            this.props.search.sizeTo.length > 0 ? sizeToInBytes = this.props.actions.toBytes(this.props.search.sizeTo, this.props.search.selectedSizeToUnit) : sizeToInBytes = "";

            console.log("sizeFromInBytes: " + sizeFromInBytes);
            console.log("sizeToInBytes: " + sizeToInBytes);

            if (this.props.search.sizeFrom.length > 0 && this.props.search.sizeTo.length > 0) {
                sizeForSearch = "dataSize:(>=" + sizeFromInBytes + " AND <=" + sizeToInBytes + ")"
            } else if (this.props.search.sizeFrom.length > 0) {
                sizeForSearch = "dataSize:(>=" + sizeFromInBytes + ")"
            } else {
                sizeForSearch = "dataSize:(<=" + sizeToInBytes + ")"
            }

            if (quetyStr.length > 0) {
                quetyStr += " AND (" + sizeForSearch + ")"
            } else {
                quetyStr += "(" + sizeForSearch + ")"
            }
        }

        if (this.props.search.creatorUserInfo.length > 0) {
            var creatorUserInfo = "(creatorUserId.keyword:" + this.props.search.creatorUserInfo + ") OR (creatorUserId:*" + this.props.search.creatorUserInfo + "*) OR (creatorUserInfo.keyword:" + this.props.search.creatorUserInfo + ") OR (creatorUserInfo:*" + this.props.search.creatorUserInfo + "*)"
            if (quetyStr.length > 0) {
                quetyStr += " AND (" + creatorUserInfo + ")"
            } else {
                quetyStr += "(" + creatorUserInfo + ")"
            }
        }

        if (this.props.search.signer.length > 0) {
            var signer = "(signers.surname.keyword:" + this.props.search.signer + ") OR (signers.firstName.keyword:" + this.props.search.signer + ") OR (signers.surname:*" + this.props.search.signer + "*) OR (signers.firstName:*" + this.props.search.signer + "*) OR (signers.surname:*" + this.props.search.signer + "*) OR (signers.firstName:*" + this.props.search.signer + "*)"
            if (quetyStr.length > 0) {
                quetyStr += " AND (" + signer + ")"
            } else {
                quetyStr += "(" + signer + ")"
            }
        }

        if (this.props.search.author.length > 0) {
            var author = "(authors.surname.keyword:" + this.props.search.author + ") OR (authors.firstName.keyword:" + this.props.search.author + ") OR (authors.surname:*" + this.props.search.author + "*) OR (authors.firstName:*" + this.props.search.author + "*)"
            if (quetyStr.length > 0) {
                quetyStr += " AND (" + author + ")"
            } else {
                quetyStr += "(" + author + ")"
            }
        }

        var params = {
            page: this.props.page.page,
            size: this.props.page.size,
            sortBy: this.props.page.sortBy === "fileName" || this.props.page.sortBy === "description" ? this.props.page.sortBy  + ".keyword" : this.props.page.sortBy,
            sortType: this.props.page.sortType
        }

        var query = {
            "queryString" : quetyStr
        }

        this.props.actions.setSearchString(quetyStr)

        this.props.actions.setProcessingStatus(true)
        this.props.actions.clearRecievedData();
        this.props.actions.searchDataByQueryString(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.actions.queryUrl(params), query)
        .then((response) => {
            if (response !== null) {
                this.props.actions.setRecievedData(response.content)
                this.props.actions.setALLDocsInArchPaginationParams(response.page)
            } else {
                var page = {
                    number: 0,
                    size: 20,
                    totalElements: 0,
                    totalPages: 0
                }
                
               this.props.actions.setRecievedData([])
               this.props.actions.setALLDocsInArchPaginationParams(page)
            }

            this.props.actions.setProcessingStatus(false)
        })
    }

	render() {
		return (
			<div>
				<DocsInArchSearchView searchDocs={this.search} forwardRef={this.searchRef} />
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        search: state.docsInArchSearchReducer,
        login: state.loginReducer,
        page: state.mainPagePagingReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
        searchDataByQueryString, queryUrl,
        setProcessingStatus, clearRecievedData,
        setRecievedData, setALLDocsInArchPaginationParams,
        setSearchString, formatDateForCalendar,
        formatDateWithoutTimeQueryStr, toBytes
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DocsInArchSearchContainer);