import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { changeSelectedTab, clearUploadResultData, setUploadDataIsDone, setDocVersion } from '../actions/localStates'

import i18next from 'i18next'

class Drawer extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	    	drawerOpen: false
	    }

	    this.handleDrawerClick = this.handleDrawerClick.bind(this)
	}

	componentDidMount() {
	}

	handleDrawerClick () {

	}

	handleSelectedTabChanged (value) {
		if (value === 1) {
			this.props.actions.setUploadDataIsDone(false)
			this.props.actions.clearUploadResultData();
			this.props.actions.setDocVersion(1)
		}

		this.props.actions.changeSelectedTab(value);
		document.activeElement.blur();
	}

	render() {
		return (
			<div className="col-2">
				<div style={{marginTop: "10px"}} onClick={this.handleSelectedTabChanged.bind(this, 1)} value={1}>
		          	{
		          		<button className="btn btn-outline-info btn-block" style={{"cursor": "pointer"}}><i className="fa fa-plus-circle"></i>&nbsp;{i18next.t("addDocButtonLabel")}</button>
		          	}
	            </div>
	            <div style={{"cursor": "pointer", marginTop: "10px", paddingTop: "6px"}} className={this.props.base.selectedTab === 0 ? "active-tab tab-height" : "tab-height"} onClick={this.handleSelectedTabChanged.bind(this, 0)} value={0}>
		          	{
		          		<span>{i18next.t("docsInArchTitle")}</span>
		          	}
	            </div>
	            <div style={{"cursor": "pointer", paddingTop: "6px"}} className={this.props.base.selectedTab === 3 ? "active-tab tab-height" : "tab-height"} onClick={this.handleSelectedTabChanged.bind(this, 3)} value={3}>
		          	{
		          		<span>{i18next.t("docsInProcessTitle")}</span>
		          	}
	            </div>
	            <div style={{"cursor": "pointer", paddingTop: "6px"}} className={this.props.base.selectedTab === 2 ? "active-tab tab-height" : "tab-height"} onClick={this.handleSelectedTabChanged.bind(this, 2)} value={2}>
		          	{
		          		<span>{i18next.t("docsWithWrongStatusTitle")}</span>
		          	}
	            </div>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	changeSelectedTab,
    	clearUploadResultData,
    	setUploadDataIsDone,
    	setDocVersion
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Drawer);