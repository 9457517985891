import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { formatDate, fromBytes, formatDateForCalendar } from '../actions/helpers'

import i18next from 'i18next'

import saveAs from 'file-saver';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import uk from 'date-fns/locale/uk';

import { publishObject, deleteObject, pushSignature, pushMetadata, getObjectStatus, getObjectSignatureVerificationResults, getObjectMetadata, getObjectData,
            deleteSignature, getSignature, getTechSignature, searchDataByQueryString } from '../actions/api'
import { setProcessingStatus, setAuthors, setUploadSignersDataResult, setUploadResultData, setAllAuthorFields, setEditAuthor, setActivityLog, changeSelectedTab,
            setOwnNumber, setOwnDate, setDescription, setComment, setCreatorUserInfo, setOuterId, setShowActionResult, setActionResultData,
            setDocSignatures, clearUploadSignersDataResult, setCreatorUserId, setSetTechSignatureArr, setIsLoggedIn, setRecievedData, 
            setALLDocsInArchPaginationParams, clearDocsWithProcessStatus, clearRecievedData } from '../actions/localStates'

import { queryUrl } from '../actions/helpers'

import AddAuthorPopup from './AddAuthorPopup'
import AddTagsPopup from './AddTagsPopup'
import LogPopup from './LogPopup'

import logImage from '../img/log-file3.svg'
import dsIsValid from '../img/verify-ds-isvalid.svg'
import dsIsInValid from '../img/verify-ds-isinvalid.svg'
import downloadSignature from '../img/download-signature.svg'
import calenderIcon from '../img/calendar.svg'

import Dropzone from 'react-dropzone';

const dropzoneForDSRef = createRef();
registerLocale('uk', uk)
setDefaultLocale('uk')

class AddedDocResultView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            uploadingFileName: "",
            showPopup: false,
            showTagsPopup: false,
            techSignature0Open: false,
            techSignature1Open: false,
            showLog: false,
            currentDate: this.props.actions.formatDateForCalendar(this.props.docs.ownDate)
        };

        this.handleDeleteObject = this.handleDeleteObject.bind(this)
        this.handlePublishObject = this.handlePublishObject.bind(this)
        this.showDsVerifyResults = this.showDsVerifyResults.bind(this)
        this.handleOnDrop = this.handleOnDrop.bind(this);
        this.handleAddAuthor = this.handleAddAuthor.bind(this);
        this.handleAddTags = this.handleAddTags.bind(this);
        this.updateMetadata = this.updateMetadata.bind(this);

        this.handleShowLog = this.handleShowLog.bind(this);
        this.base64ToArrayBuffer = this.base64ToArrayBuffer.bind(this);

        this.showTechDsVerifyResults = this.showTechDsVerifyResults.bind(this);
        this.handleShowTechSignature = this.handleShowTechSignature.bind(this);
        this.handleShowTechSignature0 = this.handleShowTechSignature0.bind(this);
        this.handleShowTechSignature1 = this.handleShowTechSignature1.bind(this);

        this.onDocNumberChanged = this.onDocNumberChanged.bind(this);
        this.onCreationDateChanged = this.onCreationDateChanged.bind(this);
        this.onDescriptionChanged = this.onDescriptionChanged.bind(this);
        this.onCommentChanged = this.onCommentChanged.bind(this);
        this.onCreatorUserInfoChanged = this.onCreatorUserInfoChanged.bind(this);
        this.onOuterIdChanged = this.onOuterIdChanged.bind(this);

        this.handleDeleteSignature = this.handleDeleteSignature.bind(this);

        this.dateChanged = this.dateChanged.bind(this);
        this.calendarRef = React.createRef();
    }

	componentDidMount() {
        console.log(new Date());
        console.log(this.props.docs.archiveDate)
	}

    dateChanged (d) {
        this.props.actions.setOwnDate(d)
        this.setState({currentDate: d})
    }

    handleAddTags () {
        document.activeElement.blur();
        this.setState({
            showTagsPopup: !this.state.showTagsPopup
        });
    }

    handleAddAuthor () {
        this.props.actions.setEditAuthor(false, 0);
        document.activeElement.blur();
        this.setState({
            showPopup: !this.state.showPopup
        });
    }

    handleShowLog () {
        document.activeElement.blur();
        this.setState({
            showLog: !this.state.showLog
        });
    }

    handleShowTechSignature0 () {
        this.setState({
            techSignature0Open: !this.state.techSignature0Open
        });
    }

    handleShowTechSignature (index) {
        switch (index) {
            case 0: {
                this.handleShowTechSignature0()
                break;
            }

            case 1: {
                this.handleShowTechSignature1()
                break;
            }

            default:
                break;
        }
    }

    handleShowTechSignature1 () {
        this.setState({
            techSignature1Open: !this.state.techSignature1Open
        });
    }

    base64ToArrayBuffer (base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }

    handleDownloadSignature (id) {
        var sign = "";

        this.props.actions.getSignature(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.docs.id)
        .then((response) => {
            if (response.code !== undefined && response.code.length > 0) {
                this.props.actions.setProcessingStatus(false)
                return;
            }

            this.props.actions.setDocSignatures(response);
            this.props.docs.docSignaures.forEach(function (data) {
                if (data.id === id) {
                    sign = data.signature
                }
            });

            var data = this.base64ToArrayBuffer(sign)
            var blob = new Blob([data]);
            saveAs(blob, id + ".p7s");
        });
    }

    handleDownloadTechSignature (index) {
        this.props.actions.getTechSignature(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.docs.id)
        .then((response) => {
            console.log(response);
            if (response.code !== undefined && response.code.length > 0) {
                this.props.actions.setProcessingStatus(false)
                alert(response.message);
                return;
            }
            this.props.actions.setSetTechSignatureArr(response)
            var data = this.base64ToArrayBuffer(this.props.docs.techSignatureArr[index].signature)
            var blob = new Blob([data]);
            saveAs(blob, this.props.docs.techSignatureArr[index].id + ".p7s");
        });
    }

    handleDownloadFile (doc) {
        this.props.actions.getObjectData(this.props.base.authServiceUrl, this.props.login.accessToken, doc.id)
        .then((response) => {
            saveAs(response, doc.fileName || doc.id);
        })
    }

    handleDeleteAuthor (value) {
        this.props.docs.authors.splice(value, 1)
        this.props.actions.setAuthors(this.props.docs.authors)
    }

    handleEditAuthor (value) {
        this.props.actions.setAllAuthorFields(this.props.docs.authors[value]);
        this.props.actions.setEditAuthor(true, value);
        document.activeElement.blur();
        this.setState({
            showPopup: !this.state.showPopup
        });
    }

    convertSize (size) {
        var res, str = "";
        res = this.props.actions.fromBytes(size);
        str = res.value + " " + i18next.t(res.unit);
        return str
    }

    onCreatorUserInfoChanged (e) {
        this.props.actions.setCreatorUserId(e.target.value)
    }

    onDescriptionChanged (e) {
        this.props.actions.setDescription(e.target.value)
    }

    onOuterIdChanged (e) {
        this.props.actions.setOuterId(e.target.value)
    }

    onCommentChanged (e) {
        this.props.actions.setComment(e.target.value)
    }

    onDocNumberChanged (e) {
        this.props.actions.setOwnNumber(e.target.value)
    }

    onCreationDateChanged (e) {
        // this.props.actions.setOwnDate(e.target.value)
    }

    handleDeleteSignature () {
        this.props.actions.setProcessingStatus(false)
        this.props.actions.deleteSignature(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.docs.uploadDataResult.id)
        .then((response) => {
            if (response.code !== undefined && response.code.length > 0) {
                this.props.actions.setProcessingStatus(false)

                this.props.actions.setActionResultData({status: "failed", action: "deletingSignatures", message: response.message})
                this.props.actions.setShowActionResult(true)
                return;
            }

            this.props.actions.clearUploadSignersDataResult()

            this.props.actions.getObjectMetadata(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.docs.uploadDataResult.id)
            .then((response) => {
                if (response.code !== undefined && response.code.length > 0) {
                    this.props.actions.setProcessingStatus(false)

                    this.props.actions.setActionResultData({status: "failed", action: "deletingSignatures", message: response.message})
                    this.props.actions.setShowActionResult(true)
                    return;
                }

                this.props.actions.setUploadResultData(response)

                this.props.actions.getObjectStatus(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.docs.uploadDataResult.id)
                .then((response) => {
                    if (response.code !== undefined && response.code.length > 0) {
                        this.props.actions.setActionResultData({status: "failed", action: "deletingSignatures", message: response.message})
                        this.props.actions.setShowActionResult(true)

                        return;
                    }
                    this.props.actions.setActivityLog(response)
                    this.props.actions.setActionResultData({status: "success", action: "deletingSignatures", message: i18next.t("deletingSignaturesSucsess")})
                    this.props.actions.setShowActionResult(true)
                })
            })
        })
    }

    updateMetadata () {
        var metadata = {
            archiveDate: this.props.docs.archiveDate,
            authors: this.props.docs.authors,
            autoDefinedDataMimeType: this.props.docs.autoDefinedDataMimeType,
            comment: this.props.docs.comment,
            confirmedDate: this.props.docs.confirmedDate,
            creatorUserId: this.props.docs.creatorUserId,
            creatorUserInfo: this.props.docs.creatorUserInfo,
            dataMimeType: this.props.docs.dataMimeType,
            dataSize: this.props.docs.dataSize,
            description: this.props.docs.description,
            fileName: this.props.docs.fileName,
            id: this.props.docs.id,
            outerId: this.props.docs.outerId,
            ownDate: this.props.docs.ownDate,
            ownNumber: this.props.docs.ownNumber,
            signers: this.props.docs.signers,
            tags: this.props.docs.tags
        }

        this.props.actions.pushMetadata(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.docs.uploadDataResult.id, JSON.stringify(metadata))
        .then((response) => {

            this.props.actions.getObjectMetadata(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.docs.uploadDataResult.id)
            .then((response) => {
                if (response.code !== undefined && response.code.length > 0) {
                    this.props.actions.setProcessingStatus(false)

                    this.props.actions.setActionResultData({status: "failed", action: "fileUploadingLabel", message: response.message})
                    this.props.actions.setShowActionResult(true)
                    return;
                }

                this.props.actions.setUploadResultData(response)

                this.props.actions.getObjectStatus(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.docs.uploadDataResult.id)
                .then((response) => {
                    if (response.code !== undefined && response.code.length > 0) {
                        this.props.actions.setActionResultData({status: "failed", action: "updatingFileData", message: response.message})
                        this.props.actions.setShowActionResult(true)

                        return;
                    }
                    this.props.actions.setActivityLog(response)
                    this.props.actions.setActionResultData({status: "success", action: "updatingFileData", message: i18next.t("updatingFileDataSucsess")})
                    this.props.actions.setShowActionResult(true)
                })
            })
        })
    }

    formatDate(dateInString) {
        var localeDateOptions = {
            "day": "numeric",
            "month": "numeric",
            "year": "numeric",
            "hour": "2-digit",
            "minute": "2-digit",
            "second": "2-digit"
        }

        return new Date(dateInString).toLocaleDateString('uk-UA', localeDateOptions);
    }

    constructTimestampDetails(aTs, aTsType) {
        let contentTs = i18next.t("dataTs");
        let signatureTs = i18next.t("dsTs");
        let result = [];

        let tsTypeString = function () {
            if (aTsType === "content") {
                return contentTs;
            }
            if (aTsType === "signature") {
                return signatureTs;
            }
            return "";
        }
        
        if (aTs && aTs.dateTime) {
            if (aTs.isFailure) {
                result.push(<div>{tsTypeString() + i18next.t("errorWhileVerifying")}</div>);
            } else {
                result.push(
                    <div>
                        <div>
                            <span className="font-weight-bold">{tsTypeString()}</span>
                            {
                                ": "
                                + (aTs.isValid ? i18next.t("sheIsValid") : i18next.t("sheIsInvalid"))
                                + "; "
                                + this.formatDate(aTs.dateTime)
                            }
                        </div>
                    </div>
                );
            }
        }
        return result;
    }

    showTechDsVerifyResults (result, index) {
        let divPtblg = [];
        let signerNumber = [];
        let signerStatus = [];
        let signerDetails = [];
        let tsDetails = [];

        if (result.signatureTimestamps && result.signatureTimestamps.length) {
            for (var j = 0; j < result.signatureTimestamps.length; j += 1) {
                tsDetails.push(this.constructTimestampDetails(result.signatureTimestamps[j], "signature"))
            }
        }

        signerNumber.push(<div className="no-left-right-margin col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{textAlign: "center"}}>
            {   
                result.isValid
                ? <div><img className="img" width="90px" height="90px" src={dsIsValid} alt="dsIsValid" /></div>
                : <div><img className="img" width="90px" height="90px" src={dsIsInValid} alt="dsIsInValid" /></div>
            }
            {i18next.t("signature") + (index + 1)}
        </div>)

        if (result.status === "SUCCESS") {
                signerStatus.push(<div className="no-left-right-margin col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{textAlign: "center"}}>
                    {
                        result.isValid ? i18next.t("heIsValid") : i18next.t("heIsInvalid")
                    }
                </div>)
                
                
                signerDetails.push(<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 signer-details">
                    <div className="margin-bottom-5">
                        <span className="font-weight-bold">{index === 0 ? i18next.t("techSignature") : i18next.t("techDS")}</span> <span>{result.signerInfo.ownerCertificateInfo.value.ownerFullName.value }</span>
                    </div>
                        {
                            result.signerInfo.certificateSerialNumber.value ? 
                                <div>
                                    <span className="font-weight-bold">{i18next.t("serialNumber")}</span><span>{result.signerInfo.certificateSerialNumber.value.replace(/\s/g, "") }</span>
                                </div> : null
                        }
                    <div className="margin-bottom-5">
                        <span className="font-weight-bold">{i18next.t("ca")}</span><span>{result.signerInfo.issuerCertificateInfo.value.issuerFullName.value }</span>
                    </div>
                    <div className="margin-bottom-5">
                        {
                            result.signingDateTime !== undefined && result.signingDateTime.length > 0
                            ? <span><span className="font-weight-bold">{i18next.t("dsDate")}</span><span>{this.formatDate(result.signingDateTime)}</span></span>
                            : null
                        }
                    </div>
                    
                    <div>
                        {
                            tsDetails
                        }
                    </div>
                </div>)

                divPtblg.push(<div className="ptblg-default">
                    {
                         <div className={result.isValid ? "alert alert-secondary" : "alert alert-warning"}>
                            <div className="row col-12 no-left-right-margin open-signature-form">
                                <div className="col-xl-10 col-lg-10 col-md-10 no-left-right-margin">
                                    {
                                        index === 0 ? i18next.t("techSignature") : i18next.t("techDS")}{this.props.docs.techSignatureVerifyResultsArr.verifyResults[index].isValid ? <span style={{color: "green"}}>{i18next.t("heIsValid")}</span> : <span style={{color: "red"}}>{i18next.t("heIsInvalid")}</span>
                                    }
                                </div>
                                <div className="col-xl-2 col-lg-2 col-md-2 no-left-right-margin" style={{marginBottom: "3px"}}>
                                    <div className="no-left-right-margin float-right" style={{float: "inline-end"}}>
                                    <i className="fa fa-chevron-up" onClick={this.handleShowTechSignature.bind(this, index)} style={{cursor: "pointer", marginRight: "10px"}}></i>
                                    <img className="img" style={{cursor: "pointer"}} onClick={this.handleDownloadTechSignature.bind(this, index)} width="40px" height="30px" src={downloadSignature} alt="downloadSignature" /></div>
                                </div>
                            </div>
                            <div className="row" style={{marginTop: "5px"}}>
                                <div className="no-left-right-margin col-lg-3 col-md-3 col-sm-12 col-xs-12">{signerNumber} {signerStatus}</div> <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">{signerDetails}</div>
                            </div>
                        </div>
                    }
                </div>)
            } else {
                signerStatus.push(<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        {i18next.t("notDefined")}
                    </div>)
                signerDetails.push(<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 signer-details">
                        <div>
                            { result.failureCause }
                            {
                                result.isUntrustedValid
                                ? <div><p></p><div><b>{i18next.t("signatureValidWithUntrustedTime")}</b></div></div>
                                : null
                            }
                        </div>
                    </div>)
                divPtblg.push(<div className="">
                    <div className={result.isValid ? "alert alert-secondary" : "alert alert-warning"}>
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">{signerNumber} {signerStatus}</div> <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">{signerDetails}</div>
                        </div>
                    </div>
                </div>)
            }
        return divPtblg
    }

    showDsVerifyResults(data) {
        var _this = this;
        let divPtblg = [], resDiv = [], extBoundIdArr = [], dataObject = [], tmpArr = [];

        data.forEach(function (element) {
            if (!extBoundIdArr.includes(element.externalBoundId)) {
                extBoundIdArr.push(element.externalBoundId);
            }
        });

        extBoundIdArr.forEach(function (id) {
            data.forEach(function (element) {
                if (element.externalBoundId === id) {
                    tmpArr.push(element);
                }
            });
            dataObject.push(tmpArr)
            tmpArr = [];
        });

        console.log(dataObject);


        dataObject.forEach(function (verifyResults) {
            for (var i = 0; i < verifyResults.length; i += 1) {
                let signerNumber = [];
                let signerStatus = [];
                let signerDetails = [];
                let tsDetails = [];

                if (verifyResults[i].signatureTimestamps && verifyResults[i].signatureTimestamps.length) {
                    for (var j = 0; j < verifyResults[i].signatureTimestamps.length; j += 1) {
                        tsDetails.push(_this.constructTimestampDetails(verifyResults[i].signatureTimestamps[j], "signature"))
                    }
                }

                

                signerNumber.push(<div className="no-left-right-margin col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{textAlign: "center"}}>
                    {   
                        verifyResults[i].isValid
                        ? <div><img className="img" width="90px" height="90px" src={dsIsValid} alt="dsIsValid" /></div>
                        : <div><img className="img" width="90px" height="90px" src={dsIsInValid} alt="dsIsInValid" /></div>
                    }
                    {i18next.t("signature") + (i + 1)}
                </div>)

                if (verifyResults[i].status === "SUCCESS") {
                    signerStatus.push(<div className="no-left-right-margin col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{textAlign: "center"}}>
                        {
                            verifyResults[i].isValid ? i18next.t("heIsValid") : i18next.t("heIsInvalid")
                        }
                    </div>)
                    
                    
                    signerDetails.push(<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 signer-details">
                        <div className="margin-bottom-5">
                            <span className="font-weight-bold">{i18next.t("signer")}</span> <span>{verifyResults[i].signerInfo.ownerCertificateInfo.value.ownerFullName.value }</span>
                        </div>
                            {
                                verifyResults[i].signerInfo.certificateSerialNumber.value ? 
                                    <div>
                                        <span className="font-weight-bold">{i18next.t("serialNumber")}</span><span style={{wordWrap: "break-word"}}>{verifyResults[i].signerInfo.certificateSerialNumber.value.replace(/\s/g, "") }</span>
                                    </div> : null
                            }
                        <div className="margin-bottom-5">
                            <span className="font-weight-bold">{i18next.t("ca")}</span><span>{verifyResults[i].signerInfo.issuerCertificateInfo.value.issuerFullName.value }</span>
                        </div>
                        <div className="margin-bottom-5">
                            {
                                verifyResults[i].signingDateTime !== undefined && verifyResults[i].signingDateTime.length > 0
                                ? <span><span className="font-weight-bold">{i18next.t("dsDate")}</span><span>{_this.formatDate(verifyResults[i].signingDateTime)}</span></span>
                                : null
                            }
                        </div>
                        
                        <div>
                            {
                                tsDetails
                            }
                        </div>
                    </div>)

                    resDiv.push(
                                <div className="row" style={{marginTop: "4px"}}>
                                    <div className="no-left-right-margin col-lg-3 col-md-3 col-sm-12 col-xs-12">{signerNumber} {signerStatus}</div> <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">{signerDetails}</div>
                                </div>
                    )
                } else {
                    signerStatus.push(<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            {i18next.t("notDefined")}
                        </div>)
                    signerDetails.push(<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 signer-details">
                            <div>
                                { verifyResults[i].failureCause }
                                {
                                    verifyResults[i].isUntrustedValid
                                    ? <div><p></p><div><b>{i18next.t("signatureValidWithUntrustedTime")}</b></div></div>
                                    : null
                                }
                            </div>
                        </div>)
                    divPtblg.push(<div className="">
                        <div className={verifyResults[i].isValid ? "alert alert-secondary" : "alert alert-warning"}>
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">{signerNumber} {signerStatus}</div> <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">{signerDetails}</div>
                            </div>
                        </div>
                    </div>)
                }
            }
            divPtblg.push(<div className="ptblg-default">
                <div  className="alert alert-secondary">
                    <div className="row col-12 no-left-right-margin open-signature-form">
                        <div className="col-xl-10 col-lg-10 col-md-10 no-left-right-margin">
                            {i18next.t("signature")}<span style={{color: "green"}}>{i18next.t("heIsValid")}</span>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-2 no-left-right-margin" style={{marginBottom: "3px"}}>
                            <div className="no-left-right-margin float-right" style={{float: "inline-end"}}>
                            <img className="img" style={{cursor: "pointer"}} onClick={_this.handleDownloadSignature.bind(_this, verifyResults[0].externalBoundId)} width="40px" height="30px" src={downloadSignature} alt="downloadSignature" /></div>
                        </div>
                    </div>
                    {resDiv}
                </div>
            </div>)

            resDiv = [];
        });

        return divPtblg
    }

    handleOnDrop (files) {
        var metadata = {
            archiveDate: this.props.docs.archiveDate,
            authors: this.props.docs.authors,
            autoDefinedDataMimeType: this.props.docs.autoDefinedDataMimeType,
            comment: this.props.docs.comment,
            confirmedDate: this.props.docs.confirmedDate,
            creatorUserId: this.props.docs.creatorUserId,
            creatorUserInfo: this.props.docs.creatorUserInfo,
            dataMimeType: this.props.docs.dataMimeType,
            dataSize: this.props.docs.dataSize,
            description: this.props.docs.description,
            fileName: this.props.docs.fileName,
            id: this.props.docs.id,
            outerId: this.props.docs.outerId,
            ownDate: this.props.docs.ownDate,
            ownNumber: this.props.docs.ownNumber,
            signers: this.props.docs.signers,
            tags: this.props.docs.tags
        }

        if (files[0] !== undefined) {
            this.setState({"uploadingFileName": files[0].name})
            this.props.actions.setProcessingStatus(true)
            console.log(files[0].name)
            this.props.actions.pushSignature(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.docs.uploadDataResult.id, files[0])
            .then((response) => {
                console.log(response)
                if (response.code !== undefined && response.code.length > 0) {
                    this.props.actions.setProcessingStatus(false)
                    
                    this.props.actions.setActionResultData({status: "failed", action: "uploadingDSData", message: response.message})
                    this.props.actions.setShowActionResult(true)
                    return;
                }

                this.props.actions.getObjectSignatureVerificationResults(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.docs.uploadDataResult.id)
                .then((response) => {
                    if (response.code !== undefined && response.code.length > 0) {
                        this.props.actions.setProcessingStatus(false)
                        
                        this.props.actions.setActionResultData({status: "failed", action: "uploadingDSData", message: response.message})
                        this.props.actions.setShowActionResult(true)
                        return;
                    }

                    this.props.actions.setUploadSignersDataResult(response)

                    this.props.actions.getSignature(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.docs.uploadDataResult.id)
                    .then((response) => {
                        if (response.code !== undefined && response.code.length > 0) {
                            this.props.actions.setProcessingStatus(false)
                            
                            this.props.actions.setActionResultData({status: "failed", action: "uploadingDSData", message: response.message})
                            this.props.actions.setShowActionResult(true)
                            return;
                        }

                        this.props.actions.setDocSignatures(response);

                        this.props.actions.pushMetadata(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.docs.uploadDataResult.id, JSON.stringify(metadata))
                        .then((response) => {
                            this.props.actions.getObjectMetadata(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.docs.uploadDataResult.id)
                            .then((response) => {
                                if (response.code !== undefined && response.code.length > 0) {
                                    this.props.actions.setProcessingStatus(false)

                                    this.props.actions.setActionResultData({status: "failed", action: "uploadingDSData", message: response.message})
                                    this.props.actions.setShowActionResult(true)
                                    return;
                                }

                                this.props.actions.setUploadResultData(response)
                                console.log(response)

                                this.props.actions.getObjectStatus(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.docs.uploadDataResult.id)
                                .then((response) => {
                                    if (response.code !== undefined && response.code.length > 0) {
                                        this.props.actions.setProcessingStatus(false)
                                        this.props.actions.setActionResultData({status: "failed", action: "uploadingDSData", message: response.message})
                                        this.props.actions.setShowActionResult(true)
                                        return;
                                    }

                                    console.log(response)
                                    this.props.actions.setProcessingStatus(false)
                                })
                            })
                        })
                    })
                })
            })
        }
        
    }

    handleDeleteObject () {
        var query;
        var params = {
            page: this.props.page.number,
            size: this.props.page.size,
            sortBy: this.props.page.sortBy === "fileName" || this.props.page.sortBy === "description" ? this.props.page.sortBy  + ".keyword" : this.props.page.sortBy,
            sortType: this.props.page.sortType
        }

        if (this.props.search.searchString !== null) {
            query = {
                "queryString" : this.props.search.searchString
            }
        } else {
            query = {
                "queryString" : "id:*"
            }
        }

        this.props.actions.clearDocsWithProcessStatus()

        this.props.actions.deleteObject(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.docs.uploadDataResult.id)
        .then((response) => {
            if (response.code !== undefined && response.code.length > 0) {
                this.props.actions.setActionResultData({status: "failed", action: "deleteObject", message: response.message})
                this.props.actions.setShowActionResult(true)
                return;
            }

            if (this.props.page.docsInArchPaginationParamsSetted) {
                this.props.actions.setProcessingStatus(true)
                this.props.actions.clearRecievedData()
                this.setState({current_page: this.props.page.number});

                this.props.actions.searchDataByQueryString(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.actions.queryUrl(params), query)
                .then((response) => {
                    if (response === null) {
                        this.props.actions.setIsLoggedIn(false);
                        return
                    }
                    this.props.actions.setRecievedData(response.content)
                    this.props.actions.setALLDocsInArchPaginationParams(response.page)
                    this.props.actions.setProcessingStatus(false)

                    if (this.props.base.previousTab === 0) {
                        this.props.actions.setActionResultData({status: "success", action: "deleteObject", message: i18next.t("deleteObjectAfterTimeoutSuccess")})
                    } else {
                       this.props.actions.setActionResultData({status: "success", action: "deleteObject", message: i18next.t("deleteObjectSuccess")}) 
                    }

                    this.props.actions.setShowActionResult(true)
                    this.props.actions.changeSelectedTab(this.props.base.previousTab);
                })
            } else {
                this.props.actions.setProcessingStatus(true)
                this.props.actions.searchDataByQueryString(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.actions.queryUrl(params), query)
                .then((response) => {
                    console.log(response);
                    this.props.actions.clearRecievedData()
                    this.props.actions.setALLDocsInArchPaginationParams(response.page)
                    this.props.actions.setRecievedData(response.content)
                    this.props.actions.setProcessingStatus(false)

                    if (this.props.base.previousTab === 0) {
                        this.props.actions.setActionResultData({status: "success", action: "deleteObject", message: i18next.t("deleteObjectAfterTimeoutSuccess")})
                    } else {
                       this.props.actions.setActionResultData({status: "success", action: "deleteObject", message: i18next.t("deleteObjectSuccess")}) 
                    }

                    this.props.actions.setShowActionResult(true)
                    this.props.actions.changeSelectedTab(this.props.base.previousTab);
                })
            }
        })
    }

    handlePublishObject () {
        this.props.actions.publishObject(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.docs.uploadDataResult.id)
        .then((response) => {
            if (response.code !== undefined && response.code.length > 0) {
                this.props.actions.setActionResultData({status: "failed", action: "publishObject", message: response.message})
                this.props.actions.setShowActionResult(true)
                return;
            }

            this.props.actions.getObjectStatus(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.docs.uploadDataResult.id)
            .then((response) => {
                if (response.code !== undefined && response.code.length > 0) {
                    this.props.actions.setActionResultData({status: "failed", action: "publishObject", message: response.message})
                    this.props.actions.setShowActionResult(true)
                    return;
                }

                this.props.actions.setActivityLog(response)
                this.props.actions.setActionResultData({status: "success", action: "publishObject", message: i18next.t("publishObjectSuccess")})
                this.props.actions.setShowActionResult(true)
            })
        })
    }

	render() {
		return (
            <div className="col-12" style={{paddingLeft: "0px"}}>
                <div style={{float: "right", marginTop: "10px", marginBottom: "10px"}}>
                    <button className="btn btn-outline-info" style={{marginRight: "5px"}} onClick={this.handleDeleteObject}>{i18next.t("deleteLabel")}</button>
                    {
                        (this.props.docs.activityLog.currentStatus === "CREATED" && (this.props.docs.uploadSignersDataResult !== null && this.props.docs.uploadSignersDataResult.verifyResults.length > 0))
                        ? <button className="btn btn-outline-info" style={{marginRight: "5px"}} onClick={this.handleDeleteSignature}>{i18next.t("deleteSignatures")}</button>
                        : null
                    }
                    {
                        this.props.docs.activityLog.currentStatus === "PUBLISHED"
                        ? null
                        : <button className="btn btn-outline-info" style={{marginRight: "5px"}} onClick={this.handlePublishObject}>{i18next.t("publishLabel")}</button>
                    }
                    {
                        <button className="btn btn-info" onClick={this.updateMetadata}>{i18next.t("updateLabel")}</button>
                    }
                </div>
                
                <div className="addDocStatus row col-lg-12 col-md-12" style={{marginTop: "10px", marginBottom: "10px"}}>
                    <div className="row col-12 addDocResultHeader no-left-right-margin" style={{paddingTop: "8px", paddingBottom: "4px"}}>
                        <div className="col-lg-2 col-md-2">
                            {i18next.t("fileName")}
                        </div>
                        <div className="col-lg-3 col-md-3">
                            {i18next.t("id")}
                        </div>
                        <div className="col-lg-2 col-md-2">
                            {i18next.t("docStatusLabel")}
                        </div>
                        <div className="col-lg-1 col-md-1">
                            {i18next.t("dataSize")}
                        </div>
                        <div className="col-lg-2 col-md-2">
                            {i18next.t("autoDefinedDataMimeType")}
                        </div>
                        <div className="col-lg-1 col-md-1 no-left-right-margin">
                            {i18next.t("confirmedDate")}
                        </div>
                        <div className="col-lg-1 col-md-1 no-left-right-margin">
                            {i18next.t("log")}
                        </div>
                    </div>

                    <div className="row col-12 no-left-right-margin" style={{textAlign: "center", wordWrap: "break-word", paddingTop: "4px", paddingBottom: "8px"}}>
                        <div className="col-lg-2 col-md-2" style={{cursor: "pointer", textDecoration: "underline"}} onClick={this.handleDownloadFile.bind(this, this.props.docs.uploadDataResult)}>
                            {this.props.docs.uploadDataResult.fileName}
                        </div>
                        <div className="col-lg-3 col-md-3 no-left-right-margin">
                            {this.props.docs.uploadDataResult.id}
                        </div>
                        <div className="col-lg-2 col-md-2">
                            {i18next.t(this.props.docs.activityLog.currentStatus)}
                        </div>
                        <div className="col-lg-1 col-md-1">
                            {this.convertSize(this.props.docs.uploadDataResult.dataSize)}
                        </div>
                        <div className="col-lg-2 col-md-2 no-left-right-margin">
                            <span title={this.props.docs.uploadDataResult.autoDefinedDataMimeType} className="span-text">{ this.props.docs.uploadDataResult.autoDefinedDataMimeType }</span>
                        </div>
                        <div className="col-lg-1 col-md-1 no-left-right-margin">
                            {this.props.actions.formatDate(this.props.docs.uploadDataResult.archiveDate)}
                        </div>
                        <div className="col-lg-1 col-md-1 no-left-right-margin">
                            <img className="img" style={{cursor: "pointer", marginTop: "3px"}} width="40px" height="30px" onClick={this.handleShowLog} src={logImage} alt="logImage" />
                        </div>
                    </div>
                </div>
                <div className="row add-doc-resul-col" style={{paddingLeft: "0px"}}>
                    <div className="col-lg-6 col-md-6 col-sm-12" style={{paddingLeft: "0px"}}>
                        <div className="font-weight-bold color-888" style={{textAlign: "right"}}>
                            {i18next.t("signaturesLabel")}
                        </div>
                        {
                            this.props.docs.uploadSignersDataResult
                            ? <div>
                                {
                                    this.props.docs.uploadSignersDataResult.verifyResults.length > 0
                                    ? <div>
                                        {
                                            this.showDsVerifyResults(this.props.docs.uploadSignersDataResult.verifyResults)
                                        }
                                    </div>
                                    : <div style={{textAlign: "center", marginBottom: "8px"}}>{i18next.t("noSignaturesLabel")}</div>
                                }
                            </div>
                            : null
                        }
                        
                        <div>
                            {
                                this.props.docs.activityLog.currentStatus === "PUBLISHED" || this.props.docs.activityLog.currentStatus === "PUBLICATION_INITIATED"
                                ? null
                                : <div>
                                    <Dropzone ref={dropzoneForDSRef} onDrop={this.handleOnDrop}>
                                        {({getRootProps, getInputProps}) => (
                                            <div {...getRootProps()} style={{cursor: "pointer", marginTop: "16px"}} className="dropzone">
                                                <input {...getInputProps()} disable={this.props.base.processing.toString()} />
                                                {
                                                    this.props.base.processing
                                                    ? <p style={{marginTop: "16px"}}><i className="fa fa-spinner fa-pulse"></i>&nbsp; Uploading {this.state.uploadingFileName}</p>
                                                    : <p style={{cursor: "pointer", marginTop: "16px"}}>{i18next.t("dragAndDropDSLabel")}</p>
                                                }
                                            </div>
                                        )}
                                    </Dropzone>
                                    
                                </div>
                            }
                            <div className="dashed-border-bottom">
                            </div>
                            <div className="font-weight-bold color-888" style={{textAlign: "right"}}>
                                {i18next.t("techSignaturesLabel")}
                            </div>
                            {
                                this.props.docs.techSignatureVerifyResultsArr.verifyResults !== null
                                ? <div>
                                    {
                                        this.props.docs.techSignatureVerifyResultsArr.verifyResults[0] !== undefined
                                        ? <div className={this.state.techSignature0Open ? "" : (this.props.docs.techSignatureVerifyResultsArr.verifyResults[0] !== undefined && this.props.docs.techSignatureVerifyResultsArr.verifyResults[0].isValid) ? "alert alert-secondary" : "alert alert-warning"} style={{marginBottom: "10px"}}>
                                            {
                                                
                                                this.state.techSignature0Open
                                                ? this.showTechDsVerifyResults(this.props.docs.techSignatureVerifyResultsArr.verifyResults[0], 0)
                                                : <div className="row col-12 no-left-right-margin">
                                                    <div className="col-xl-10 col-lg-10 col-md-10 no-left-right-margin" style={{lineHeight: "1.5"}}>
                                                        {
                                                            i18next.t("techSignature")}{this.props.docs.techSignatureVerifyResultsArr.verifyResults[0].isValid ? <span style={{color: "green"}}>{i18next.t("heIsValid")}</span> : <span style={{color: "red"}}>{i18next.t("heIsInvalid")}</span> 
                                                        }
                                                    </div>
                                                    <div className="col-xl-2 col-lg-2 col-md-2 no-left-right-margin">
                                                        <div className="no-left-right-margin float-right" style={{float: "inline-end"}}><i className="fa fa-chevron-down" onClick={this.handleShowTechSignature0} style={{cursor: "pointer", marginRight: "10px"}}></i>
                                                        <img className="img" style={{cursor: "pointer"}} onClick={this.handleDownloadTechSignature.bind(this, 0)} width="40px" height="30px" src={downloadSignature} alt="downloadSignature" /></div>
                                                    </div>
                                                </div>
                                                
                                            }
                                        </div>
                                        : null
                                    }
                                    {
                                        this.props.docs.techSignatureVerifyResultsArr.verifyResults[1] !== undefined
                                        ? <div className={this.state.techSignature1Open ? "" : (this.props.docs.techSignatureVerifyResultsArr.verifyResults[1] !== undefined && this.props.docs.techSignatureVerifyResultsArr.verifyResults[1].isValid) ? "alert alert-secondary" : "alert alert-warning"} style={{marginBottom: "10px"}}>
                                            {
                                                this.state.techSignature1Open
                                                ? this.showTechDsVerifyResults(this.props.docs.techSignatureVerifyResultsArr.verifyResults[1], 1)
                                                : <div className="row col-12 no-left-right-margin">
                                                    <div className="col-xl-10 col-lg-10 col-md-10 no-left-right-margin" style={{lineHeight: "1.5"}}>
                                                        {
                                                            i18next.t("techDS")}{this.props.docs.techSignatureVerifyResultsArr.verifyResults[1].isValid ? <span style={{color: "green"}}>{i18next.t("heIsValid")}</span> : <span style={{color: "red"}}>{i18next.t("heIsInvalid")}</span> 
                                                        }
                                                    </div>
                                                    <div className="col-xl-2 col-lg-2 col-md-2 no-left-right-margin">
                                                        <div className="no-left-right-margin float-right" style={{float: "inline-end"}}><i className="fa fa-chevron-down" onClick={this.handleShowTechSignature1} style={{cursor: "pointer", marginRight: "10px"}}></i>
                                                        <img className="img" style={{cursor: "pointer"}} onClick={this.handleDownloadTechSignature.bind(this, 1)} width="40px" height="30px" src={downloadSignature} alt="downloadSignature" /></div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        : null
                                    }
                                </div>
                                : <div style={{textAlign: "center", marginBottom: "8px"}}>{i18next.t("noSignaturesLabel")}</div>
                            }
                            

                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 dashed-border-left" style={{paddingLeft: "0px"}}>
                        <div className="font-weight-bold color-888" style={{textAlign: "right"}}>
                            {i18next.t("additionalInfo")}
                        </div>
                        <div className="row">
                            <div className="row col-6" style={{paddingLeft: "0px"}}>
                                <label className="col-lg-12 col-md-12 col-xs-12 col-sm-12 col-form-label no-right-padding">{i18next.t("ownNumber")}</label>
                                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding" style={{marginBottom: "10px"}}>
                                    <input type="text" value={this.props.docs.ownNumber} onChange={this.onDocNumberChanged} className="form-control col-lg-12 col-md-12 col-xs-12 col-sm-12" />
                                </div>
                            </div>
                            <div className="row col-6" style={{paddingRight: "0px"}}>
                                <label className="col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding col-form-label">{i18next.t("ownDate")}</label>
                                <div className="row col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding" style={{marginBottom: "10px"}}>
                                    
                                    <div className="col-10 no-left-right-margin">
                                        <div className="customDatePickerWidth">
                                        <DatePicker ref={this.calendarRef} dateFormat="dd.MM.yyyy" maxDate={new Date()} className="form-control pointer" onClick={() => {this.calendarRef.current.setOpen(true)}}
                                            selected={this.state.currentDate}
                                            onChange={this.dateChanged} />
                                        </div>
                                    </div>
                                    <div className="col-2 no-left-right-margin">
                                        <img className="img" onClick={() => {this.calendarRef.current.setOpen(true)}} style={{cursor: "pointer"}}width="50px" height="40px" src={calenderIcon} alt="calenderIcon" />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <label className="col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding col-form-label">{i18next.t("description")}</label>
                            <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding" style={{marginBottom: "10px"}}>
                                <textarea rows="3" value={this.props.docs.description} onChange={this.onDescriptionChanged} style={{minHeight: "70px"}} className="form-control col-lg-12 col-md-12 col-xs-12 col-sm-12" />
                            </div>
                        </div>
                        <div className="row">
                            <label className="col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding col-form-label">{i18next.t("tags")}</label>
                            <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding" style={{marginBottom: "10px"}}>
                                <textarea rows="2" className="form-control col-lg-12 col-md-12 col-xs-12 col-sm-12" style={{cursor: "pointer", minHeight: "70px"}} readOnly value={this.props.docs.tags.sort()} onClick={this.handleAddTags} />
                            </div>
                        </div>
                        <div className="row">
                            <label className="col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding col-form-label">{i18next.t("comment")}</label>
                            <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding" style={{marginBottom: "10px"}}>
                                <textarea rows="3" value={this.props.docs.comment} onChange={this.onCommentChanged} style={{minHeight: "70px"}} className="form-control col-lg-12 col-md-12 col-xs-12 col-sm-12" />
                            </div>
                        </div>
                        <label className="col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding col-form-label">{i18next.t("authors")}</label>

                        {
                            this.props.docs.authors.length > 0
                            ? <div>
                                <div>
                                    {
                                        this.props.docs.authors.map((value, index) => {
                                            return <div className="col-12 input-group no-right-padding" style={{marginBottom: "10px"}}>
                                              <input className="form-control" style={{cursor: "pointer"}} onClick={this.handleEditAuthor.bind(this, index)} value={this.props.docs.authors[index].firstName + " " + this.props.docs.authors[index].surname} type="input" readOnly />
                                              <div className="input-group-append">
                                                  <div className="input-group-text" onClick={this.handleDeleteAuthor.bind(this, index)} style={{cursor: "pointer"}}><i className="fa fa-remove"></i></div>
                                              </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            : null
                        }

                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding" style={{marginBottom: "10px"}}>
                                <button className="btn btn-outline-info btn-block" onClick={this.handleAddAuthor}>{i18next.t("addAuthors")}</button>
                            </div>
                        </div>

                        <div className="row">
                            <label className="col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding col-form-label">{i18next.t("outerId")}</label>
                            <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding" style={{marginBottom: "10px"}}>
                                <input type="text" value={this.props.docs.outerId} onChange={this.onOuterIdChanged} className="form-control col-lg-12 col-md-12 col-xs-12 col-sm-12" />
                            </div>
                        </div>
                        <div className="row">
                            <label className="col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding col-form-label">{i18next.t("creatorUserInfo")}</label>
                            <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding" style={{marginBottom: "10px"}}>
                                <input type="text" disabled value={(this.props.docs.creatorUserInfo !== null ? this.props.docs.creatorUserInfo : "") + (this.props.docs.creatorUserId !== null ? " [" + this.props.docs.creatorUserId + "]" : "")} onChange={this.onCreatorUserInfoChanged} className="form-control col-lg-12 col-md-12 col-xs-12 col-sm-12" />
                            </div>
                        </div>
                        <div className="row">
                            <label className="col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding col-form-label">{i18next.t("creationDate")}</label>
                            <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding" style={{marginBottom: "10px"}}>
                                <input type="text" disabled value={this.props.actions.formatDate(this.props.docs.confirmedDate) || ""} className="form-control col-lg-12 col-md-12 col-xs-12 col-sm-12" />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {
                        this.state.showPopup
                        ? <AddAuthorPopup closePopup={this.handleAddAuthor.bind(this)} />
                        : null
                    }
                </div>
                <div>
                    {
                        this.state.showTagsPopup
                        ? <AddTagsPopup closeTagsPopup={this.handleAddTags.bind(this)} />
                        : null
                    }
                </div>
                <div>
                    {
                        this.state.showLog
                        ? <LogPopup closeLogPopup={this.handleShowLog.bind(this)} />
                        : null
                    }
                </div>
            </div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer,
        docs: state.addDocReducer,
        page: state.mainPagePagingReducer,
        search: state.docsInArchSearchReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    formatDate, setOuterId,
        publishObject, changeSelectedTab,
        deleteObject, setOwnDate,
        pushSignature, getSignature,
        setProcessingStatus,
        setAuthors, setDescription,
        pushMetadata, deleteSignature,
        getObjectStatus, setCreatorUserId,
        getObjectSignatureVerificationResults,
        setUploadSignersDataResult,
        getObjectMetadata, setDocSignatures,
        setUploadResultData,
        setAllAuthorFields, getTechSignature,
        setEditAuthor, fromBytes,
        setActivityLog, clearUploadSignersDataResult,
        setOwnNumber, setComment,
        setCreatorUserInfo, queryUrl,
        getObjectData, formatDateForCalendar,
        setShowActionResult, setActionResultData,
        setSetTechSignatureArr, searchDataByQueryString,
        setIsLoggedIn, setRecievedData, 
        setALLDocsInArchPaginationParams,
        clearDocsWithProcessStatus,
        clearRecievedData
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddedDocResultView);