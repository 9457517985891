export const setDefaults = (value) => (dispatch) => {
    dispatch({
        type: 'SET_DEFAULTS',
        payload: value
    })
}

export const setIsLoggedIn = (value) => (dispatch) => {
    dispatch({
        type: 'SET_IS_LOGGED_IN',
        payload: value
    })
}

export const setVersion = (data) => (dispatch) => {
    dispatch({
        type: 'SET_VERSION',
        payload: data
    })
}

export const setIsIEBrowser = (value) => (dispatch) => {
    dispatch({
        type: 'SET_IS_IE_BROWSER',
        payload: value
    })
}

export const changeSearchState = (state) => (dispatch) => {
    dispatch({
        type: 'CHANGE_SEARCH_STATE',
        payload: state
    })
}

export const clearLoggedInUserData = (value) => (dispatch) => {
    dispatch({
        type: 'CLEAR_LOGGED_IN_USER_DATA',
        payload: value
    })
}

export const changeSelectedTab = (tab) => (dispatch) => {
    dispatch({
        type: 'CHANGE_SELECTED_TAB',
        payload: tab
    })
}

export const setProcessingStatus = (status) => (dispatch) => {
    dispatch({
        type: 'SET_PROCESSING_STATUS',
        payload: status
    })
}

export const setDocForUpload = (file) => (dispatch) => {
    dispatch({
        type: 'SET_DOC_FOR_UPLOAD',
        payload: file
    })
}

export const setSetTechSignatureArr = (arr) => (dispatch) => {
    dispatch({
        type: 'SET_TECH_SIGNATURE_ARR',
        payload: arr
    })
}

export const setUploadResultData = (data) => (dispatch) => {
    dispatch({
        type: 'SET_UPLOAD_DATA_RESULT',
        payload: data
    })
}

export const clearUploadResultData = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_UPLOAD_DATA_RESULT',
        payload: null
    })
}

export const setUploadDataIsDone = (value) => (dispatch) => {
    dispatch({
        type: 'SET_UPLOAD_DATA_IS_DONE',
        payload: value
    })
}

export const setTachSignatureVerfyResultsArr = (arr) => (dispatch) => {
    dispatch({
        type: 'SET_TECH_SIGNATURE_VERIFY_RESULTS_ARR',
        payload: arr
    })
}

export const clearTachSignatureVerfyResultsArr = (arr) => (dispatch) => {
    dispatch({
        type: 'CLEAR_TECH_SIGNATURE_VERIFY_RESULTS_ARR',
        payload: arr
    })
}

export const setArchiveDate = (date) => (dispatch) => {
    dispatch({
        type: 'SET_ARCHIVE_DATE',
        payload: date
    })
}

export const clearArchiveDate = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_ARCHIVE_DATE',
        payload: null
    })
}

export const setAuthors = (arr) => (dispatch) => {
    dispatch({
        type: 'SET_AUTHORS',
        payload: arr
    })
}

export const clearAuthors = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_AUTHORS',
        payload: null
    })
}

export const setAutoDefinedMimeType = (type) => (dispatch) => {
    dispatch({
        type: 'SET_AUTO_DEFINED_MIME_TYPE',
        payload: type
    })
}

export const clearAutoDefinedMimeType = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_AUTO_DEFINED_MIME_TYPE',
        payload: null
    })
}

export const setComment = (value) => (dispatch) => {
    dispatch({
        type: 'SET_COMMENT',
        payload: value
    })
}

export const clearComment = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_COMMENT',
        payload: null
    })
}

export const setConfirmedDate = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CONFIRMED_DATE',
        payload: value
    })
}

export const clearConfirmedDate = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_CONFIRMED_DATE',
        payload: null
    })
}

export const setCreatorUserId = (id) => (dispatch) => {
    dispatch({
        type: 'SET_CREATOR_USER_ID',
        payload: id
    })
}

export const clearCreatorUserId = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_CREATOR_USER_ID',
        payload: null
    })
}

export const setCreatorUserInfo = (info) => (dispatch) => {
    dispatch({
        type: 'SET_CREATOR_USER_INFO',
        payload: info
    })
}

export const clearCreatorUserInfo = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_CREATOR_USER_INFO',
        payload: null
    })
}

export const setDataMimeType = (type) => (dispatch) => {
    dispatch({
        type: 'SET_DATA_MIME_TYPE',
        payload: type
    })
}

export const clearDataMimeType = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_DATA_MIME_TYPE',
        payload: null
    })
}

export const setDataSize = (size) => (dispatch) => {
    dispatch({
        type: 'SET_DATA_SIZE',
        payload: size
    })
}

export const clearDataSize = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_DATA_SIZE',
        payload: null
    })
}

export const setDescription = (text) => (dispatch) => {
    dispatch({
        type: 'SET_DESCRIPTION',
        payload: text
    })
}

export const clearDescription = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_DESCRIPTION',
        payload: null
    })
}

export const setFileName = (name) => (dispatch) => {
    dispatch({
        type: 'SET_FILE_NAME',
        payload: name
    })
}

export const clearFileName = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_FILE_NAME',
        payload: null
    })
}

export const setId = (id) => (dispatch) => {
    dispatch({
        type: 'SET_ID',
        payload: id
    })
}

export const clearId = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_ID',
        payload: null
    })
}

export const setOuterId = (outerId) => (dispatch) => {
    dispatch({
        type: 'SET_OUTER_ID',
        payload: outerId
    })
}

export const clearOuterId = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_OUTER_ID',
        payload: null
    })
}

export const setOwnDate = (date) => (dispatch) => {
    dispatch({
        type: 'SET_OWN_DATE',
        payload: date
    })
}

export const clearOwnDate = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_OWN_DATE',
        payload: null
    })
}

export const setOwnNumber = (number) => (dispatch) => {
    dispatch({
        type: 'SET_OWN_NUMBER',
        payload: number
    })
}

export const clearOwnNumber = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_OWN_NUMBER',
        payload: null
    })
}

export const setSigners = (arr) => (dispatch) => {
    dispatch({
        type: 'SET_SIGNERS',
        payload: arr
    })
}

export const clearSigners = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_SIGNERS',
        payload: null
    })
}

export const setTags = (arr) => (dispatch) => {
    dispatch({
        type: 'SET_TAGS',
        payload: arr
    })
}

export const clearTags = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_TAGS',
        payload: null
    })
}

export const setRecievedData = (data) => (dispatch) => {
    dispatch({
        type: 'SET_RECIEVED_DATA',
        payload: data
    })
}

export const clearRecievedData = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_RECIEVED_DATA',
        payload: null
    })
}

export const setFirstName = (name) => (dispatch) => {
    dispatch({
        type: 'SET_FIRST_NAME',
        payload: name
    })
}

export const setSurname = (name) => (dispatch) => {
    dispatch({
        type: 'SET_SURNAME',
        payload: name
    })
}

export const setOrganisation = (org) => (dispatch) => {
    dispatch({
        type: 'SET_ORGANISATION',
        payload: org
    })
}

export const setDepartment = (dept) => (dispatch) => {
    dispatch({
        type: 'SET_DEPARTMENT',
        payload: dept
    })
}

export const setPositon = (pos) => (dispatch) => {
    dispatch({
        type: 'SET_POSITION',
        payload: pos
    })
}

export const setDrfou = (drfou) => (dispatch) => {
    dispatch({
        type: 'SET_DRFOU',
        payload: drfou
    })
}

export const setEdrpou = (edrpou) => (dispatch) => {
    dispatch({
        type: 'SET_EDRPOU',
        payload: edrpou
    })
}

export const setEddrun = (eddrun) => (dispatch) => {
    dispatch({
        type: 'SET_EDDRUN',
        payload: eddrun
    })
}

export const setMainPageNumber = (num) => (dispatch) => {
    dispatch({
        type: 'SET_MAIN_PAGE_NUMBER',
        payload: num
    })
}

export const setMainPageSize = (size) => (dispatch) => {
    dispatch({
        type: 'SET_MAIN_PAGE_SIZE',
        payload: size
    })
}

export const setMainPageTotalElements = (count) => (dispatch) => {
    dispatch({
        type: 'SET_MAIN_TOTAL_ELEMENTS',
        payload: count
    })
}

export const setMainPageTotalPages = (pages) => (dispatch) => {
    dispatch({
        type: 'SET_MAIN_TOTAL_PAGES',
        payload: pages
    })
}

export const setWrongStatusPageNumber = (num) => (dispatch) => {
    dispatch({
        type: 'SET_WRONG_STATUS_PAGE_NUMBER',
        payload: num
    })
}

export const setWrongStatusPageSize = (size) => (dispatch) => {
    dispatch({
        type: 'SET_WRONG_STATUS_PAGE_SIZE',
        payload: size
    })
}

export const setWrongStatusPageTotalElements = (count) => (dispatch) => {
    dispatch({
        type: 'SET_WRONG_STATUS_TOTAL_ELEMENTS',
        payload: count
    })
}

export const setWrongStatusPageTotalPages = (pages) => (dispatch) => {
    dispatch({
        type: 'SET_WRONG_STATUS_TOTAL_PAGES',
        payload: pages
    })
}

export const setProcessStatusPageNumber = (num) => (dispatch) => {
    dispatch({
        type: 'SET_PROCESS_STATUS_PAGE_NUMBER',
        payload: num
    })
}

export const setProcessStatusPageSize = (size) => (dispatch) => {
    dispatch({
        type: 'SET_PROCESS_STATUS_PAGE_SIZE',
        payload: size
    })
}

export const setProcessStatusPageTotalElements = (count) => (dispatch) => {
    dispatch({
        type: 'SET_PROCESS_STATUS_TOTAL_ELEMENTS',
        payload: count
    })
}

export const setProcessStatusPageTotalPages = (pages) => (dispatch) => {
    dispatch({
        type: 'SET_PROCESS_STATUS_TOTAL_PAGES',
        payload: pages
    })
}

export const setUploadSignersDataResult = (arr) => (dispatch) => {
    dispatch({
        type: 'SET_UPLOAD_SIGNERS_DATA_RESULT_ARR',
        payload: arr
    })
}

export const clearUploadSignersDataResult = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_UPLOAD_SIGNERS_DATA_RESULT_ARR',
        payload: null
    })
}

export const setDocsWithWrongStatus = (data) => (dispatch) => {
    dispatch({
        type: 'SET_DOCS_WITH_WRONG_STATUS_DATA',
        payload: data
    })
}

export const setDocsWithProcessStatus = (data) => (dispatch) => {
    dispatch({
        type: 'SET_DOCS_WITH_PROCESS_STATUS_DATA',
        payload: data
    })
}

export const clearDocsWithProcessStatus = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_DOCS_WITH_PROCESS_STATUS_DATA',
        payload: null
    })
}

export const setAllAuthorFields = (data) => (dispatch) => {
    dispatch({
        type: 'SET_ALL_AUTHOR_FIELDS',
        payload: data
    })
}

export const clearAllAuthorFields = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_ALL_AUTHOR_FIELDS',
        payload: null
    })
}

export const setEditAuthor = (value, index) => (dispatch) => {
    var data = {value, index}
    dispatch({
        type: 'SET_EDIT_AUTHOR',
        payload: data
    })
}

export const setActivityLog = (log) => (dispatch) => {
    dispatch({
        type: 'SET_ACTIVITY_LOG',
        payload: log
    })
}

export const setSelectedStatusForDocsWithWrongStatus = (status) => (dispatch) => {
    dispatch({
        type: 'SET_SELECTED_STATUS_FOR_DOCS_WITH_WRONG_STATUS',
        payload: status
    })
}

export const setStatusValuesForDocsWithWrongStatus = (arr) => (dispatch) => {
    dispatch({
        type: 'SET_STATUS_VALUES_FOR_DOCS_WITH_WRONG_STATUS',
        payload: arr
    })
}

export const setALLDocsInArchPaginationParams = (page) => (dispatch) => {
    dispatch({
        type: 'SET_ALL_DOCS_IN_ARCH_PAGINATION_PARAMS',
        payload: page
    })
}

export const setALLDocsWithWrongStatusPaginationParams = (page) => (dispatch) => {
    dispatch({
        type: 'SET_ALL_DOCS_WITH_WRONG_STATUS_PAGINATION_PARAMS',
        payload: page
    })
}

export const setALLDocsWithProcessStatusPaginationParams = (page) => (dispatch) => {
    dispatch({
        type: 'SET_ALL_DOCS_WITH_PROCESS_STATUS_PAGINATION_PARAMS',
        payload: page
    })
}

export const setBlockchainVerifyResults = (data) => (dispatch) => {
    dispatch({
        type: 'SET_BLOCKCCHAIN_VERIFY_RESULTS',
        payload: data
    })
}

export const clearBlockchainVerifyResults = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_BLOCKCCHAIN_VERIFY_RESULTS',
        payload: null
    })
}

export const setIsDocEdit = (status) => (dispatch) => {
    dispatch({
        type: 'SET_IS_DOC_EDIT',
        payload: status
    })
}

export const setShowActionResult = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_ACTION_RESULT',
        payload: value
    })
}

export const setActionResultData = (data) => (dispatch) => {
    dispatch({
        type: 'SET_ACTION_RESULT_DATA',
        payload: data
    })
}

export const setDocVersion = (version) => (dispatch) => {
    dispatch({
        type: 'SET_DOC_VERSION',
        payload: version
    })
}

export const setDocSignatures = (data) => (dispatch) => {
    dispatch({
        type: 'SET_DOC_SIGNATURES',
        payload: data
    })
}

export const clearDocSignatures = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_DOC_SIGNATURES',
        payload: null
    })
}

export const setActionResultArr = (data) => (dispatch) => {
    dispatch({
        type: 'SET_ACTION_RESULT_ARR',
        payload: data
    })
}

export const setDocInArchSearchId = (id) => (dispatch) => {
    dispatch({
        type: 'SET_DOC_IN_ARCH_SEARCH_ID',
        payload: id
    })
}

export const setDocInArchSearchDateFrom = (date) => (dispatch) => {
    dispatch({
        type: 'SET_DOC_IN_ARCH_SEARCH_DATE_FROM',
        payload: date
    })
}

export const setDocInArchSearchDateTo = (date) => (dispatch) => {
    dispatch({
        type: 'SET_DOC_IN_ARCH_SEARCH_DATE_TO',
        payload: date
    })
}

export const setDocInArchSearchDescription = (value) => (dispatch) => {
    dispatch({
        type: 'SET_DOC_IN_ARCH_SEARCH_DESCRIPTION',
        payload: value
    })
}

export const setDocInArchSearchOwnNumber = (value) => (dispatch) => {
    dispatch({
        type: 'SET_DOC_IN_ARCH_SEARCH_OWN_NUMBER',
        payload: value
    })
}

export const clearDocInArchSearchSearchParams = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_DOCS_IN_ARCH_SEARCH_PARAMS',
        payload: null
    })
}

export const setSortBy = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SORT_BY',
        payload: value
    })
}

export const setSortType = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SORT_TYPE',
        payload: value
    })
}

export const setSearchString = (str) => (dispatch) => {
    dispatch({
        type: 'SET_SEARCH_STRING',
        payload: str
    })
}

export const setSelectedAll = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SELECTED_ALL',
        payload: value
    })
}

export const setToken = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN',
        payload: value
    })
}

export const clearToken = (value) => (dispatch) => {
    dispatch({
        type: 'CLEAR_TOKEN',
        payload: value
    })
}

export const setLoggedInUserData = (value) => (dispatch) => {
    dispatch({
        type: 'SET_LOGGED_IN_USER_DATA',
        payload: value
    })
}

export const setLoginInput = (value) => (dispatch) => {
    dispatch({
        type: 'SET_LOGIN_INPUT',
        payload: value
    })
}

export const setPasswordInput = (value) => (dispatch) => {
    dispatch({
        type: 'SET_PASSWORD_INPUT',
        payload: value
    })
}

export const setAllConfigFields = (value) => (dispatch) => {
    dispatch({
        type: 'SET_ALL_CONFIG_FIELDS',
        payload: value
    })
}

export const setExpireInTime = (value) => (dispatch) => {
    dispatch({
        type: 'SET_EXPIRE_IN_TIME',
        payload: value
    })
}

export const setAllUserFields = (user) => (dispatch) => {
    dispatch({
        type: 'SET_ALL_USER_FIELDS',
        payload: user
    })
}

export const setSelectedUser = (user) => (dispatch) => {
    dispatch({
        type: 'SET_SELECTED_USER',
        payload: user
    })
}

export const setShowUserInfo = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_USER_INFO',
        payload: value
    })
}

export const setAddedByMe = (value) => (dispatch) => {
    dispatch({
        type: 'SET_ADDED_BY_ME',
        payload: value
    })
}

export const setPreviousTab = (tab) => (dispatch) => {
    dispatch({
        type: 'SET_PREVIOUS_TAB',
        payload: tab
    })
}

export const setRedirectUrl = (url) => (dispatch) => {
    dispatch({
        type: 'SET_REDIRECT_URL',
        payload: url
    })
}

export const setSelectedPeriod = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SELECTED_PERIOD',
        payload: value
    })
}

export const setSearchStringForPeriods = (period) => (dispatch) => {
    dispatch({
        type: 'SET_SEARCH_STRING_FOR_PERIODS',
        payload: period
    })
}

export const setAdvancedSearchIsOpen = (value) => (dispatch) => {
    dispatch({
        type: 'SET_ADVANCED_SEARCH_IS_OPEN',
        payload: value
    })
}

export const setSearchFileName = (name) => (dispatch) => {
    dispatch({
        type: 'SET_SEARCH_FILE_NAME',
        payload: name
    })
}

export const setDocInArchSearchConfirmedDateFrom = (date) => (dispatch) => {
    dispatch({
        type: 'SET_DOC_IN_ARCH_SEARCH_CONFIRMED_DATE_FROM',
        payload: date
    })
}

export const setDocInArchSearchConfirmedDateTo = (date) => (dispatch) => {
    dispatch({
        type: 'SET_DOC_IN_ARCH_SEARCH_CONFIRMED_DATE_TO',
        payload: date
    })
}

export const setSelectedConfirmedPeriod = (period) => (dispatch) => {
    dispatch({
        type: 'SET_SELECTED_CONFIRMED_PERIOD',
        payload: period
    })
}

export const setDocInArchSearchTag = (value) => (dispatch) => {
    dispatch({
        type: 'SET_DOC_IN_ARCH_SEARCH_TAG',
        payload: value
    })
}

export const setDocInArchSearchAuthor = (value) => (dispatch) => {
    dispatch({
        type: 'SET_DOC_IN_ARCH_SEARCH_AUTHOR',
        payload: value
    })
}

export const setDocInArchSearchCreatorUserInfo = (value) => (dispatch) => {
    dispatch({
        type: 'SET_DOC_IN_ARCH_SEARCH_CREATOR_USER_INFO',
        payload: value
    })
}

export const setDocInArchSearchSigner = (value) => (dispatch) => {
    dispatch({
        type: 'SET_DOC_IN_ARCH_SEARCH_SIGNER',
        payload: value
    })
}

export const setDocInArchSearchSelectedSizeFromUnit = (value) => (dispatch) => {
    dispatch({
        type: 'SET_DOC_IN_ARCH_SEARCH_SELECTED_SIZE_FROM_UNIT',
        payload: value
    })
}

export const setDocInArchSearchSelectedSizeToUnit = (value) => (dispatch) => {
    dispatch({
        type: 'SET_DOC_IN_ARCH_SEARCH_SELECTED_SIZE_TO_UNIT',
        payload: value
    })
}

export const setDocInArchSearchSizeFrom = (value) => (dispatch) => {
    dispatch({
        type: 'SET_DOC_IN_ARCH_SEARCH_SIZE_FROM',
        payload: value
    })
}

export const setDocInArchSearchSizeTo = (value) => (dispatch) => {
    dispatch({
        type: 'SET_DOC_IN_ARCH_SEARCH_SIZE_TO',
        payload: value
    })
}

export const setPassport = (value) => (dispatch) => {
    dispatch({
        type: 'SET_PASSPORT',
        payload: value
    })
}

export const setExpireTime = (value) => (dispatch) => {
    dispatch({
        type: 'SET_EXPIRE_TIME',
        payload: value
    })
}

export const setTwoFactorCode = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TWO_FACTOR_CODE',
        payload: value
    })
}

export const setTwoFactorEnabled = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TWO_FACTOR_ENABLED',
        payload: value
    })
}

export const clearTwoFactorEnabled = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_TWO_FACTOR_ENABLED',
        payload: null
    })
}

export const setIs2fEnabled = (value) => (dispatch) => {
    dispatch({
        type: 'SET_IS_2F_ENABLED',
        payload: value
    })
}

export const clearTwoFactorCode = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_TWO_FACTOR_CODE',
        payload: null
    })
}

export const setChangePasswordGrant = (value) => (dispatch) => {
    dispatch({
        type: 'CHANGE_PASSWORD_GRANT',
        payload: value
    })
}

export const setUpdate2fSettingsGrant = (value) => (dispatch) => {
    dispatch({
        type: 'SET_UPDATE_2F_SETTINGS_GRANT',
        payload: value
    })
}

export const setTwoFactorAvailabelChannels = (channels) => (dispatch) => {
    dispatch({
        type: 'SET_TWO_FACTOR_AVAILABEL_CHANNELS',
        payload: channels
    })
}

export const setShowTwoFactorSettings = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_TWO_FACTOR_SETTINGS',
        payload: value
    })
}

export const setTwoFactorChannelId = (id) => (dispatch) => {
    dispatch({
        type: 'SET_TWO_FACTOR_CHANNEL_ID',
        payload: id
    })
}

export const setTwoFactorSettings = (settings) => (dispatch) => {
    dispatch({
        type: 'SET_TWO_FACTOR_SETTINGS',
        payload: settings
    })
}

export const setShowLogoutHandler = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_LOGOUT_HANDLER',
        payload: value
    })
}

export const setSelectedProvider = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SELECTED_PROVIDER',
        payload: value
    })
}