import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { formatDate } from '../actions/helpers'

import i18next from 'i18next'

class LogPopup extends Component {
	constructor(props) {
	    super(props);
	    this.state = {

	    }

	    this.close = this.close.bind(this);
	    this.keyUpHandler = this.keyUpHandler.bind(this, 'return');
	}

	componentDidMount() {
		document.addEventListener('keyup', this.keyUpHandler);
	}

	componentWillUnmount() {
		document.removeEventListener('keyup', this.keyUpHandler);
	}

	close () {
		this.props.closeLogPopup()
	}

	keyUpHandler(refName, e) {
        if (e.keyCode === 13) {
           document.getElementById("close").click();
           document.activeElement.blur()
        }
    }

	render() {
		return (
			<div className='popup'>
				<div className="popup_inner_log">
					<div className="col-12" style={{paddingTop: "15px"}}>
						<h4>{i18next.t("log")}</h4>
						<p></p>
						<div className="log-div">
							{
		                        this.props.docs.activityLog.activityLog.map((value, index) => {
		                            return <div className="col-12 no-left-right-margin row" style={{marginBottom: "10px"}}>
		                            	<div className="col-4 no-left-right-margin">{this.props.actions.formatDate(value.date)}</div>
		                            	<div className="col-4 no-left-right-margin">{i18next.t(value.activity)}</div>
		                            	<div className="col-4 no-left-right-margin">{i18next.t(value.details)}</div>
		                            </div>
		                        })
		                    }
	                    </div>
	                    <button id="close" className="btn btn-info" style={{float: "right"}} onClick={this.close}>{i18next.t("closeLabel")}</button>
                    </div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer,
        docs: state.addDocReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	formatDate
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LogPopup);