const baseState = {
    id: "",
    dateFrom: null,
    dateTo: null,
    description: "",
    ownNumber: "",
    searchString: null,
    addedByMe: false,
    selectedPeriod: 0,
    searchStringForPeriods: null,
    fileName: "",
    confirmedDateFrom: null,
    confirmedDateTo: null,
    selectedConfirmedPeriod: 0,
    tags: "",
    author: "",
    creatorUserInfo: "",
    signer: "",
    selectedSizeFromUnit: "byte",
    selectedSizeToUnit: "byte",
    sizeFrom: "",
    sizeTo: ""
}

const docsInArchSearchReducer = (state = baseState, { type, payload }) => {

    switch (type) {
        case "SET_DOC_IN_ARCH_SEARCH_ID": {
            return {
                ...state,
                id: payload
            }
        }

        case "SET_DOC_IN_ARCH_SEARCH_DATE_FROM": {
            return {
                ...state,
                dateFrom: payload
            }
        }

        case "SET_DOC_IN_ARCH_SEARCH_DATE_TO": {
            return {
                ...state,
                dateTo: payload
            }
        }

        case "SET_DOC_IN_ARCH_SEARCH_DESCRIPTION": {
            return {
                ...state,
                description: payload
            }
        }

        case "SET_DOC_IN_ARCH_SEARCH_OWN_NUMBER": {
            return {
                ...state,
                ownNumber: payload
            }
        }

        case "SET_SEARCH_STRING": {
            return {
                ...state,
                searchString: payload
            }
        }

        case "SET_ADDED_BY_ME": {
            return {
                ...state,
                addedByMe: payload
            }
        }

        case "SET_SELECTED_PERIOD": {
            return {
                ...state,
                selectedPeriod: payload
            }
        }

        case "SET_SELECTED_CONFIRMED_PERIOD": {
            return {
                ...state,
                selectedConfirmedPeriod: payload
            }
        }

        case "SET_SEARCH_STRING_FOR_PERIODS": {
            return {
                ...state,
                searchStringForPeriods: payload
            }
        }

        case "SET_SEARCH_FILE_NAME": {
            return {
                ...state,
                fileName: payload
            }
        }

        case "SET_DOC_IN_ARCH_SEARCH_CONFIRMED_DATE_FROM": {
            return {
                ...state,
                confirmedDateFrom: payload
            }
        }

        case "SET_DOC_IN_ARCH_SEARCH_CONFIRMED_DATE_TO": {
            return {
                ...state,
                confirmedDateTo: payload
            }
        }

        case "SET_DOC_IN_ARCH_SEARCH_TAG": {
            return {
                ...state,
                tags: payload
            }
        }

        case "SET_DOC_IN_ARCH_SEARCH_AUTHOR": {
            return {
                ...state,
                author: payload
            }
        }

        case "SET_DOC_IN_ARCH_SEARCH_CREATOR_USER_INFO": {
            return {
                ...state,
                creatorUserInfo: payload
            }
        }

        case "SET_DOC_IN_ARCH_SEARCH_SIGNER": {
            return {
                ...state,
                signer: payload
            }
        }

        case "SET_DOC_IN_ARCH_SEARCH_SELECTED_SIZE_FROM_UNIT": {
            return {
                ...state,
                selectedSizeFromUnit: payload
            }
        }

        case "SET_DOC_IN_ARCH_SEARCH_SELECTED_SIZE_TO_UNIT": {
            return {
                ...state,
                selectedSizeToUnit: payload
            }
        }

        case "SET_DOC_IN_ARCH_SEARCH_SIZE_FROM": {
            return {
                ...state,
                sizeFrom: payload
            }
        }

        case "SET_DOC_IN_ARCH_SEARCH_SIZE_TO": {
            return {
                ...state,
                sizeTo: payload
            }
        }

        case "CLEAR_DOCS_IN_ARCH_SEARCH_PARAMS": {
            return {
                ...state,
                id: "",
                dateFrom: null,
                dateTo: null,
                description: "",
                ownNumber: "",
                searchString: null,
                addedByMe: false,
                selectedPeriod: 0,
                searchStringForPeriods: null,
                fileName: "",
                confirmedDateFrom: null,
                confirmedDateTo: null,
                selectedConfirmedPeriod: 0,
                tags: "",
                author: "",
                creatorUserInfo: "",
                signer: "",
                selectedSizeFromUnit: "byte",
                selectedSizeToUnit: "byte",
                sizeFrom: "",
                sizeTo: ""
            }
        }

        default:
            return state;
    }
}

export default docsInArchSearchReducer