import { combineReducers } from 'redux';

import loginReducer from './loginReducer';
import addDocReducer from './addDocReducer';
import docsInArchReducer from './docsInArchReducer';
import authorReducer from './authorReducer';
import mainPagePagingReducer from './mainPagePagingReducer';
import wrongStatusPagePagingReducer from './wrongStatusPagePagingReducer';
import processStatusPagePagingReducer from './processStatusPagePagingReducer';
import docsWithWrongStatusReducer from './docsWithWrongStatusReducer';
import docsWithProcessStatusReducer from './docsWithProcessStatusReducer'
import blockchainVerificationResultReducer from './blockchainVerificationResultReducer'
import docsInArchSearchReducer from './docsInArchSearchReducer'

const baseState = {
    defaultsSetted: false,
    version: "",
    build: "",
    isIEBrowser: false,
    selectedTab: 0,
    processing: false,
    editAuthor: false,
    authorIndex: 0,
    serviceUrl: "",
    status: "success",
    action: "",
    message: "",
    resultMessagesArr: null,
    showActionResult: false,
    docVersion: 1,
    searchIsOpen: false,
    advancedSearchIsOpen: false,
    authUrl: "",
    clientId: "",
    authServiceUrl: "",
    showUserInfo: false,
    previousTab: 0,
    urlForAuth: "",
    clientTitle: "",
    domain: "",
    apps: [],
    selectedAuthProvider: "",
    expireTime: 0,
    numberOfPinInput: 3,
    showLogoutHandler: false,
    authProviders:[]
}

const base = (state = baseState, { type, payload }) => {

    switch (type) {
        case "SET_DEFAULTS": {
            return {
                ...state,
                serviceUrl: payload.serviceUrl,
                clientId: payload.clientId,
                defaultsSetted: true,
                authUrl: payload.authUrl,
                authServiceUrl: payload.serviceUrl,
                urlForAuth: payload.urlForAuth || "",
                clientTitle: payload.clientTitle || "",
                apps: payload.apps || [],
                domain: "." + payload.domain || "",
                selectedAuthProvider: payload.authProviders ? payload.authProviders[0].url : payload.authUrl,
                authProviders: payload.authProviders || [{url: payload.authUrl, clientTitle: "Шифр-Auth"}],
                numberOfPinInput: parseInt(payload.numberOfPinInput, 10) || 3
            }
        }

        case 'SET_VERSION': {
            return {
                ...state,
                version: payload.version,
                build: payload.build
            }
        }

        case "CHANGE_SEARCH_STATE": {
            return {
                ...state,
                searchIsOpen: payload
            }
        }

        case 'SET_IS_IE_BROWSER': {
            return {
                ...state,
                isIEBrowser: payload
            }
        }

        case 'SET_SHOW_USER_INFO': {
            return {
                ...state,
                showUserInfo: payload
            }
        }

        case 'SET_PREVIOUS_TAB': {
            return {
                ...state,
                previousTab: payload
            }
        }

        case "CHANGE_SELECTED_TAB": {
            return {
                ...state,
                selectedTab: payload
            }
        }

        case "SET_PROCESSING_STATUS": {
            return {
                ...state,
                processing: payload
            }
        }

        case "SET_EDIT_AUTHOR": {
            return {
                ...state,
                editAuthor: payload.value,
                authorIndex: payload.index
            }
        }

        case "SET_SHOW_ACTION_RESULT": {
            return {
                ...state,
                showActionResult: payload
            }
        }

        case "SET_DOC_VERSION": {
            return {
                ...state,
                docVersion: payload
            }
        }

        case "SET_ACTION_RESULT_DATA": {
            return {
                ...state,
                status: payload.status,
                action: payload.action,
                message: payload.message
            }
        }

        case "SET_ACTION_RESULT_ARR": {
            return {
                ...state,
                resultMessagesArr: payload
            }
        }

        case "SET_ADVANCED_SEARCH_IS_OPEN": {
            return {
                ...state,
                advancedSearchIsOpen: payload
            }
        }

        case "SET_EXPIRE_TIME": {
            return {
                ...state,
                expireTime: payload
            }
        }

        case "SET_SHOW_LOGOUT_HANDLER": {
            return {
                ...state,
                showLogoutHandler: payload
            }
        }

        case "SET_SELECTED_PROVIDER": {
            return {
                ...state,
                selectedAuthProvider: payload
            }
        }

        default:
            return state;
    }

}

export default combineReducers({
    base,
    loginReducer,
    addDocReducer,
    docsInArchReducer,
    authorReducer,
    mainPagePagingReducer,
    wrongStatusPagePagingReducer,
    processStatusPagePagingReducer,
    docsWithWrongStatusReducer,
    docsWithProcessStatusReducer,
    blockchainVerificationResultReducer,
    docsInArchSearchReducer
});


