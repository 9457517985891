const baseState = {
    firstName: "",
    surname: "",
    organisation: "",
    department: "",
    position: "",
    drfou: "",
    edrpou: "",
    eddrun: "",
    passportNumber: null,
    x509CertificateSerialNumber: null,
    x509CertificateIssuerFullName: null
}

const authorReducer = (state = baseState, { type, payload }) => {

    switch (type) {
        case "SET_FIRST_NAME": {
            return {
                ...state,
                firstName: payload
            }
        }

        case "SET_SURNAME": {
            return {
                ...state,
                surname: payload
            }
        }

        case "SET_ORGANISATION": {
            return {
                ...state,
                organisation: payload
            }
        }

        case "SET_DEPARTMENT": {
            return {
                ...state,
                department: payload
            }
        }

        case "SET_POSITION": {
            return {
                ...state,
                position: payload
            }
        }

        case "SET_DRFOU": {
            return {
                ...state,
                drfou: payload
            }
        }

        case "SET_EDRPOU": {
            return {
                ...state,
                edrpou: payload
            }
        }

        case "SET_EDDRUN": {
            return {
                ...state,
                eddrun: payload
            }
        }

        case "SET_PASSPORT": {
            return {
                ...state,
                passportNumber: payload
            }
        }

        case "SET_ALL_AUTHOR_FIELDS": {
            return {
                ...state,
                firstName: payload.firstName,
                surname: payload.surname,
                organisation: payload.organisation,
                department: payload.department || "",
                position: payload.position,
                drfou: payload.drfou,
                edrpou: payload.edrpou,
                eddrun: payload.eddrun,
                passportNumber: payload.passportNumber || null,
                x509CertificateSerialNumber: payload.x509CertificateSerialNumber || null,
                x509CertificateIssuerFullName: payload.x509CertificateIssuerFullName || null
            }
        }

        case "CLEAR_ALL_AUTHOR_FIELDS": {
            return {
                ...state,
                firstName: "",
                surname: "",
                organisation: "",
                department: "",
                position: "",
                drfou: "",
                edrpou: "",
                eddrun: "",
                passportNumber: null,
                x509CertificateSerialNumber: null,
                x509CertificateIssuerFullName: null
            }
        }

        default:
            return state;
    }
}

export default authorReducer