import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import saveAs from 'file-saver';

import i18next from 'i18next'

import { setProcessingStatus, setRecievedData, setALLDocsInArchPaginationParams, setMainPageNumber, setMainPageSize, setMainPageTotalElements,
			setMainPageTotalPages, clearBlockchainVerifyResults, setBlockchainVerifyResults, clearRecievedData, changeSearchState, setSortBy, setSortType,
			setActionResultData, setShowActionResult } from '../actions/localStates'

import { getMetadata, getObjectData, validateObject, searchDataByQueryString } from '../actions/api'

import { formatDate, searchUrl, pageUrl, fromBytes, formatDateWithoutTime, queryUrl } from '../actions/helpers';

import first from '../img/first.svg'
import last from '../img/last.svg'

import downloadFile from '../img/download-file.svg'
import verifyDoc from '../img/block-2_15.svg'

import VerifyBlockchainResultPopup from './VerifyBlockchainResultPopup'
import DocsInArchSearchContainer from '../containers/DocsInArchSearchContainer'

class DocsInArchView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showVerifyResultPopup: false,
			elementsCountArray: [10, 20, 50],
	    	showItemsCountForm: false,
	    	current_page: 1,
			records_per_page: this.props.page.size,
			searchOpen: this.props.base.searchIsOpen || false,
			loadingIndex: null
		};

		this.handleShowCountForm = this.handleShowCountForm.bind(this);
		this.handleShowBlockchainVerifyResults = this.handleShowBlockchainVerifyResults.bind(this);

		this.handleSearchClick = this.handleSearchClick.bind(this);

		this.prevPage = this.prevPage.bind(this);
		this.nextPage = this.nextPage.bind(this);
		this.firstPage = this.firstPage.bind(this);
		this.lastPage = this.lastPage.bind(this);
		this.changePage = this.changePage.bind(this);
		this.numPages = this.numPages.bind(this);

		this.refresh = this.refresh.bind(this);

		// this.handeleSortChanged = this.handeleSortChanged.bind(this);
	}

	componentDidMount() {
		var _this = this, searchBar, query;

		if (!this.props.base.searchIsOpen) {
			this.setState({searchOpen: false})
			this.setState({showSearchForm: false})
		} else {
			this.setState({searchOpen: true})
			this.setState({showSearchForm: true})
		}
		
		searchBar = document.getElementById("search-bar");
		searchBar.addEventListener( 'webkitTransitionEnd', function( event ) { _this.setState({showSearchForm: _this.state.searchOpen}) }, false );

		var params = {
			page: this.props.page.number,
			size: this.props.page.size,
			sortBy: this.props.page.sortBy === "fileName" || this.props.page.sortBy === "description" ? this.props.page.sortBy  + ".keyword" : this.props.page.sortBy,
			sortType: this.props.page.sortType
		}

		if (this.props.search.searchString !== null) {
			query = {
				"queryString" : this.props.search.searchString
			}
		} else {
			query = {
				"queryString" : "id:*"
			}
		}

		if (this.props.page.docsInArchPaginationParamsSetted) {

		    this.setState({current_page: this.props.page.number});
		    this.props.actions.setProcessingStatus(true)
			this.props.actions.searchDataByQueryString(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.actions.queryUrl(params), query)
			.then((response) => {
				console.log(response)

				if (response === null) {
					this.props.actions.setRecievedData([])
					this.props.actions.setProcessingStatus(false)
					return
				}
				this.props.actions.setRecievedData(response.content)
				this.props.actions.setALLDocsInArchPaginationParams(response.page)
				this.props.actions.setProcessingStatus(false)
			})
		} else {
			this.props.actions.setProcessingStatus(true)
			this.props.actions.searchDataByQueryString(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.actions.queryUrl(params), query)
			.then((response) => {
				console.log(response);

				if (response === null) {
					this.props.actions.setRecievedData([])
					this.props.actions.setProcessingStatus(false)
					return
				}
				this.props.actions.setALLDocsInArchPaginationParams(response.page)
				this.props.actions.setRecievedData(response.content)
				this.props.actions.setProcessingStatus(false)
			})
		}
	}

	refresh () {
		var query;

		var params = {
			page: this.props.page.number,
			size: this.props.page.size,
			sortBy: this.props.page.sortBy === "fileName" || this.props.page.sortBy === "description" ? this.props.page.sortBy  + ".keyword" : this.props.page.sortBy,
			sortType: this.props.page.sortType
		}

		if (this.props.search.searchString !== null) {
			query = {
				"queryString" : this.props.search.searchString
			}
		} else {
			query = {
				"queryString" : "id:*"
			}
		}

		this.props.actions.clearRecievedData();
		if (this.props.page.docsInArchPaginationParamsSetted) {

		    this.setState({current_page: this.props.page.number});
		    this.props.actions.setProcessingStatus(true)
			this.props.actions.searchDataByQueryString(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.actions.queryUrl(params), query)
			.then((response) => {
				console.log(response)

				if (response === null) {
					this.props.actions.setRecievedData([])
					this.props.actions.setProcessingStatus(false)
					return
				}

				this.props.actions.setRecievedData(response.content)
				this.props.actions.setALLDocsInArchPaginationParams(response.page)
				this.props.actions.setProcessingStatus(false)
			})
		} else {
			this.props.actions.setProcessingStatus(true)
			this.props.actions.searchDataByQueryString(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.actions.queryUrl(params), query)
			.then((response) => {
				console.log(response);

				if (response === null) {
					this.props.actions.setRecievedData([])
					this.props.actions.setProcessingStatus(false)
					return
				}

				this.props.actions.setALLDocsInArchPaginationParams(response.page)
				this.props.actions.setRecievedData(response.content)
				this.props.actions.setProcessingStatus(false)
			})
		}
	}

	handeleSortChanged (fieldId) {
		var query;
		console.log(fieldId)

		this.props.actions.setSortType(this.props.page.sortType !== "asc" ? "asc" : "desc")
		this.props.actions.setSortBy(fieldId)

		var params = {
			page: this.props.page.number,
			size: this.props.page.size,
			sortBy: fieldId === "fileName" || fieldId === "description" ? fieldId  + ".keyword" : fieldId,
			sortType: this.props.page.sortType !== "asc" ? "asc" : "desc"
		}

		if (this.props.search.searchString !== null) {
			query = {
				"queryString" : this.props.search.searchString
			}
		} else {
			query = {
				"queryString" : "id:*"
			}
		}

		this.props.actions.searchDataByQueryString(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.actions.queryUrl(params), query)
		.then((response) => {
			if (response === null) {
				this.props.actions.setIsLoggedIn(false);
				return
			}
			this.props.actions.setRecievedData(response.content)
			this.props.actions.setALLDocsInArchPaginationParams(response.page)
		})
	}

	handleShowCountForm () {
		this.setState({showItemsCountForm: !this.state.showItemsCountForm})
	}

	handleSearchClick () {
		this.setState({searchOpen: !this.state.searchOpen})
		this.props.actions.changeSearchState(!this.state.searchOpen);
		this.setState({showSearchForm: false})
	}

	convertSize (size) {
		var res, str = "";
		res = this.props.actions.fromBytes(size);
		str = res.value + " " + i18next.t(res.unit);
		return str
	}

	handleShowBlockchainVerifyResults () {
        document.activeElement.blur();
        this.setState({
            showVerifyResultPopup: !this.state.showVerifyResultPopup
        });
    }

	handleCountChanged (value) {
		var page, query;
		if (this.props.page.number < 1) page = 1;
	    if (this.props.page.number > this.numPages()) page = this.numPages();

	    var params = {
			page: page - 1,
			size: value,
			sortBy: this.props.page.sortBy === "fileName" || this.props.page.sortBy === "description" ? this.props.page.sortBy  + ".keyword" : this.props.page.sortBy,
			sortType: this.props.page.sortType
		}

		if (this.props.search.searchString !== null) {
			query = {
				"queryString" : this.props.search.searchString
			}
		} else {
			query = {
				"queryString" : "id:*"
			}
		}


		this.props.actions.setProcessingStatus(true)
		this.props.actions.clearRecievedData();

		this.props.actions.setMainPageSize(value);
		this.setState({showItemsCountForm: !this.state.showItemsCountForm});

		this.props.actions.searchDataByQueryString(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.actions.queryUrl(params), query)
		.then((response) => {
			this.props.actions.setALLDocsInArchPaginationParams(response.page)
			this.props.actions.setRecievedData(response.content)
			this.props.actions.setMainPageSize(response.page.size)
			this.numPages();
			this.changePage(this.props.page.number);

			this.props.actions.setProcessingStatus(false)
		})
	}

	prevPage ()	{
		if (this.props.page.number > 0) {
	        this.changePage(this.props.page.number - 1);
	    }
	}

	firstPage ()	{
	    this.changePage(0);
	}

	lastPage ()	{
	    this.changePage(this.numPages() - 1);
	}

	nextPage () {
		if (this.props.page.number < this.numPages()) {
	        this.changePage(this.props.page.number + 1);
	    }
	}

	changePage (page) {
		var query;

	    var params = {
			page: page,
			size: this.props.page.size,
			sortBy: this.props.page.sortBy === "fileName" || this.props.page.sortBy === "description" ? this.props.page.sortBy  + ".keyword" : this.props.page.sortBy,
			sortType: this.props.page.sortType
		}

	    if (page < 0) page = 0;
	    if (page >= this.numPages()) page = this.numPages() - 1;

	    if (page === this.props.page.number) {
	    	return;
	    }

		if (this.props.search.searchString !== null) {
			query = {
				"queryString" : this.props.search.searchString
			}
		} else {
			query = {
				"queryString" : "id:*"
			}
		}
		

		this.props.actions.setProcessingStatus(true)
		this.props.actions.clearRecievedData();
		this.props.actions.searchDataByQueryString(this.props.base.authServiceUrl, this.props.login.accessToken, this.props.actions.queryUrl(params), query)
		.then((response) => {
			this.props.actions.setRecievedData(response.content)
			this.props.actions.setALLDocsInArchPaginationParams(response.page)

			this.props.actions.setProcessingStatus(false)
		})
	}

	numPages () {
	    return Math.ceil(this.props.page.totalElements / this.props.page.size);
	}

	componentWillUnmount () {
	}

	getSelectedDoc (doc) {
		this.props.getDoc(doc)
	}

	handleVerifyFile (doc, index) {
		if (this.props.base.processing) {
			return;
		}

		if (index !== undefined) {
			this.setState({"loadingIndex": index})
		}
		this.props.actions.setProcessingStatus(true)
		this.props.actions.validateObject(this.props.base.authServiceUrl, this.props.login.accessToken, doc.id)
		.then((response) => {
			if (response.code !== undefined && response.code.length > 0) {
				this.props.actions.clearBlockchainVerifyResults()
				this.props.actions.setBlockchainVerifyResults(response);

				this.props.actions.setProcessingStatus(false);
				this.setState({"loadingIndex": null});

				this.props.actions.setActionResultData({status: "failed", action: "verifyDoc", message: response.message})
        		this.props.actions.setShowActionResult(true)

				return;
			} else {
				this.props.actions.clearBlockchainVerifyResults()
				this.props.actions.setBlockchainVerifyResults(response);
				
				console.log(response);
				this.props.actions.setProcessingStatus(false);
				this.setState({"loadingIndex": null});
				this.handleShowBlockchainVerifyResults();
			}
		})
	}

	handleDownloadFile (doc) {
		this.props.actions.getObjectData(this.props.base.authServiceUrl, this.props.login.accessToken, doc.id)
		.then((response) => {
			saveAs(response, doc.fileName || doc.id);
		})
	}

	render() {
		let searchBarOpenState = 'searh-bar'
		let itemsCountFormOpen = 'dropdown-menu'
		let useIEStyle = 'offset-8 bottomright'

		if (this.state.showItemsCountForm) {
			itemsCountFormOpen = 'dropdown-menu show'
		}

		if (this.props.base.isIEBrowser) {
			useIEStyle = 'offset-8 bottomright-ie'
		}

		if (this.props.base.searchIsOpen) {
		    // searchBarOpenState = 'searh-bar open'

		    if (this.props.base.advancedSearchIsOpen) {
		    	searchBarOpenState = 'advanced-bar open'
		    } else {
		    	searchBarOpenState = 'searh-bar open'
		    }
		}

		return (
			<div>
				<div className="no-left-right-margin" style={{marginTop: "10px"}}>
				<div>
					<button className="btn btn-outline-info" onClick={this.refresh}><i className="fa fa-refresh"></i></button>
				</div>
				<div id="search-bar" className={searchBarOpenState} style={{marginTop: "-20px"}}>
					<div style={{"cursor": "pointer"}}>
						<div style={{float: "right", marginRight: "15px"}} onClick={this.handleSearchClick}>
							{
								this.state.searchOpen
								? <span>{i18next.t("searchLabel")}<i className="fa fa-chevron-up" style={{paddingLeft: "5px"}}></i></span>
								: <span>{i18next.t("searchLabel")}<i className="fa fa-chevron-down" style={{paddingLeft: "5px"}}></i></span>
							}
						</div>
					</div>
					<div style={{paddingTop: "20px"}}>
						{
							this.state.showSearchForm
							? <DocsInArchSearchContainer />
							: null
						}
					</div>
				</div>
					{
						this.props.docs.data
						? <div className="table-holder">
							<div className="col-12 no-left-right-margin" style={{paddingRight: "15px", height: "100%"}}>
								<div className="row fixed_header_div">
									<div className="col-4" onClick={this.handeleSortChanged.bind(this, "fileName")} style={{textAlign: "center", wordWrap: "break-word", cursor: "pointer", borderRightWidth: "0px"}}>
										{i18next.t("fileName")}
										{
											this.props.page.sortBy === "fileName"
											? <span>&nbsp;
												{
													this.props.page.sortType === "desc"
													? <i className="fa fa-chevron-down"></i>
													: <i className="fa fa-chevron-up"></i>
												}
											</span>
											: null
										}
									</div>
									<div className="col-1 no-left-right-margin" onClick={this.handeleSortChanged.bind(this, "dataSize")} style={{textAlign: "center", wordWrap: "break-word", cursor: "pointer", paddingLeft: "2px", borderRightWidth: "0px"}}>
										{i18next.t("dataSize")}
										{
											this.props.page.sortBy === "dataSize"
											? <span>&nbsp;
												{
													this.props.page.sortType === "desc"
													? <i className="fa fa-chevron-down"></i>
													: <i className="fa fa-chevron-up"></i>
												}
											</span>
											: null
										}
									</div>
									<div className="col-2" onClick={this.handeleSortChanged.bind(this, "description")} style={{textAlign: "center", wordWrap: "break-word", cursor: "pointer", borderRightWidth: "0px"}}>
										{i18next.t("description")}
										{
											this.props.page.sortBy === "description"
											? <span>&nbsp;
												{
													this.props.page.sortType === "desc"
													? <i className="fa fa-chevron-down"></i>
													: <i className="fa fa-chevron-up"></i>
												}
											</span>
											: null
										}
									</div>
									<div className="col-2" onClick={this.handeleSortChanged.bind(this, "ownDate")} style={{textAlign: "center", wordWrap: "break-word", cursor: "pointer", borderRightWidth: "0px"}}>
										{i18next.t("ownDate")}
										{
											this.props.page.sortBy === "ownDate"
											? <span>&nbsp;
												{
													this.props.page.sortType === "desc"
													? <i className="fa fa-chevron-down"></i>
													: <i className="fa fa-chevron-up"></i>
												}
											</span>
											: null
										}
									</div>
			                        <div className="col-2" onClick={this.handeleSortChanged.bind(this, "archiveDate")} style={{textAlign: "center", wordWrap: "break-word", cursor: "pointer", borderRightWidth: "0px"}}>
			                        	{i18next.t("archiveDate")}
			                        	{
											this.props.page.sortBy === "archiveDate"
											? <span>&nbsp;
												{
													this.props.page.sortType === "desc"
													? <i className="fa fa-chevron-down"></i>
													: <i className="fa fa-chevron-up"></i>
												}
											</span>
											: null
										}
			                        </div>
						    		<div className="col-1" style={{textAlign: "center", wordWrap: "break-word"}}>{i18next.t("actionsLabel")}</div>
						    	</div>
						    	<div className="fixed_div" style={{marginTop: "5px"}}>
									{
					        			this.props.docs.data.map((doc, index) => {
										    return (<div key={index} className="row top-bottom-padding-4 place-items" style={{wordWrap: "break-word"}}>
										    		<div className="col-4 place-items" onClick={this.getSelectedDoc.bind(this, doc)} style={{textAlign: "left", cursor: "pointer"}}>{doc.fileName}</div>
										    		<div className="col-1 place-items" onClick={this.getSelectedDoc.bind(this, doc)} style={{textAlign: "right", cursor: "pointer"}}>{this.convertSize(doc.dataSize)}</div>
										    		<div className="col-2 place-items" onClick={this.getSelectedDoc.bind(this, doc)} style={{textAlign: "center", cursor: "pointer"}}>{doc.description}</div>
										    		<div className="col-2 place-items" onClick={this.getSelectedDoc.bind(this, doc)} style={{textAlign: "center", cursor: "pointer"}}>{this.props.actions.formatDateWithoutTime(doc.ownDate)}</div>
						                            <div className="col-2 place-items" onClick={this.getSelectedDoc.bind(this, doc)} style={{textAlign: "center", cursor: "pointer"}}>{this.props.actions.formatDate(doc.archiveDate)}</div>
										    		<div className="col-1" style={{textAlign: "center", paddingRight: "0px"}}>
										    			{
										    				this.state.loadingIndex !== null && (this.state.loadingIndex === index)
										    				? <span style={{paddingLeft: "10px", paddingRight: "13px"}}><i className="fa fa-spinner fa-pulse fa-1x"></i></span>
										    				: <img className="img" style={{cursor: "pointer"}} onClick={this.handleVerifyFile.bind(this, doc, index)} src={verifyDoc} width="40" height="30" alt={verifyDoc}/>
										    			}
										    			<img className="img" style={{cursor: "pointer"}} onClick={this.handleDownloadFile.bind(this, doc)} src={downloadFile} width="40" height="30" alt={downloadFile}/>
										    		</div>
										    </div>)
										})
									}
								</div>
							</div>
						</div>
						: <div className="loading-div"><div className="loading-div-inner"><i className="fa fa-spinner fa-pulse fa-5x"></i></div></div>
	        		}
				</div>
				{
					this.numPages() === 0
					? null
					: <div className={useIEStyle}>
						<div className="row">
							<div className="btn-group dropup" style={{minWidth: "5rem"}}>
								<button type="button" onClick={this.handleShowCountForm} className="btn btn-info dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.showItemsCountForm}>
									{
										this.props.page.size
									}
								</button>
								<div className={itemsCountFormOpen} style={{minWidth: "5rem"}}>
									{
										this.state.elementsCountArray.map((value, index) => {
											return (<span key={index} className="dropdown-item" style={{cursor: "pointer"}} onClick={this.handleCountChanged.bind(this, value)}>{value}</span>)
										})
									}
								</div>
							</div>

							<div  className="row">
								<div className={this.props.page.number === 0 ? "col-1 cursor-default opacity-05" : "col-1 cursor-pointer opacity-1"} onClick={this.firstPage}>
									<img className="img" style={{marginTop: "5px"}} src={first} width="20" height="26" alt={first}/>
								</div>
								<div className={this.props.page.number === 0 ? "col-1 cursor-default opacity-05" : "col-1 cursor-pointer opacity-1"} style={{paddingTop: "10px"}} onClick={this.prevPage}>
									<i className="fa fa-chevron-left"></i>
								</div>
								<div className="col-1" style={{paddingTop: "10px"}}>
									{
										this.props.page.number + 1
									}
								</div>
								<span style={{paddingTop: "10px"}}> / </span>
								<div className="col-1" style={{paddingTop: "10px"}}>
									{
										this.numPages()
									}
								</div>
								<div className={this.props.page.number === (this.numPages() - 1) ? "col-1 cursor-default opacity-05" : "col-1 cursor-pointer opacity-1"} style={{paddingTop: "10px"}} onClick={this.nextPage}>
									<i className="fa fa-chevron-right"></i>
								</div>
								<div className={this.props.page.number === (this.numPages() - 1) ? "col-1 cursor-default opacity-05" : "col-1 cursor-pointer opacity-1"} onClick={this.lastPage}>
									<img className="img" style={{marginTop: "5px"}} src={last} width="20" height="26" alt={last}/>
								</div>
							</div>
						</div>
					</div>
				}
				<div>
                    {
                        this.state.showVerifyResultPopup
                        ? <VerifyBlockchainResultPopup closeBlockchainPopup={this.handleShowBlockchainVerifyResults.bind(this)} />
                        : null
                    }
                </div>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer,
        docs: state.docsInArchReducer,
        page: state.mainPagePagingReducer,
        search: state.docsInArchSearchReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	formatDate, searchUrl,
    	setProcessingStatus,
    	setRecievedData, getObjectData,
    	getMetadata, pageUrl,
    	setALLDocsInArchPaginationParams,
    	setMainPageNumber, setMainPageSize,
    	setMainPageTotalElements,
		setMainPageTotalPages, clearRecievedData,
		validateObject, clearBlockchainVerifyResults,
		setBlockchainVerifyResults,
		fromBytes, formatDateWithoutTime,
		changeSearchState, queryUrl,
		searchDataByQueryString, setSortBy, setSortType,
		setActionResultData, setShowActionResult
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DocsInArchView);