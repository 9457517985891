import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import uk from 'date-fns/locale/uk';
import moment from 'moment';

import { setDocInArchSearchId, setDocInArchSearchDateFrom, setDocInArchSearchDateTo, setDocInArchSearchDescription, setDocInArchSearchOwnNumber, setSelectedConfirmedPeriod,
			clearDocInArchSearchSearchParams, setProcessingStatus, setALLDocsInArchPaginationParams, setRecievedData, setAddedByMe, setSelectedPeriod,
			setSearchStringForPeriods, setAdvancedSearchIsOpen, setSearchFileName, setDocInArchSearchConfirmedDateFrom, setDocInArchSearchConfirmedDateTo,
			setDocInArchSearchTag, setDocInArchSearchAuthor, setDocInArchSearchCreatorUserInfo, setDocInArchSearchSigner,
			setDocInArchSearchSelectedSizeFromUnit, setDocInArchSearchSelectedSizeToUnit, setDocInArchSearchSizeFrom, setDocInArchSearchSizeTo } from '../actions/localStates'

import { formatDateForCalendar, queryUrl } from '../actions/helpers'
import { searchDataByQueryString } from '../actions/api'

import i18next from 'i18next'
import calenderIcon from '../img/calendar.svg'

registerLocale('uk', uk)
setDefaultLocale('uk')

class DocsInArchSearchView extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	    	dateFrom: this.props.actions.formatDateForCalendar(this.props.search.dateFrom) || "",
	    	dateTo: this.props.actions.formatDateForCalendar(this.props.search.dateTo) || "",
	    	confirmedDateFrom: this.props.actions.formatDateForCalendar(this.props.search.confirmedDateFrom) || "",
	    	confirmedDateTo: this.props.actions.formatDateForCalendar(this.props.search.confirmedDateTo) || "",
	    	periods: ["", "currentDay", "previousDay", "currentWeek", "previousWeek", "currentMonth", "previousMonth"],
	    	confirmedPeriods: ["", "currentDay", "previousDay", "currentWeek", "previousWeek", "currentMonth", "previousMonth"],
	    	selectedPeriod: this.props.search.selectedPeriod || 0,
	    	selectedConfirmedPeriod: this.props.search.selectedConfirmedPeriod || 0,
	    	advancedSearchOpen: this.props.base.advancedSearchIsOpen || false,
	    	sizeFromUnit: ["byte", "kilobyte", "megabyte", "gigabyte"],
	    	sizeToUnit: ["byte", "kilobyte", "megabyte", "gigabyte"],
	    	selectedSizeFromUnit: this.props.search.selectedSizeFromUnit || "byte",
	    	selectedSizeToUnit: this.props.search.selectedSizeToUnit || "byte"
	    }

	    this.onOwnNumberChanged = this.onOwnNumberChanged.bind(this);
	    this.onDescriptionChanged = this.onDescriptionChanged.bind(this);
		this.onIdChanged = this.onIdChanged.bind(this);

		this.searchData = this.searchData.bind(this);

		this.cleanUp = this.cleanUp.bind(this);

		this.calendarFromRef = React.createRef();
		this.calendarToRef = React.createRef();

		this.calendarConfirmedFromRef = React.createRef();
		this.calendarConfirmedToRef = React.createRef();

		this.onDateFromChanged = this.onDateFromChanged.bind(this);
		this.onDateToChanged = this.onDateToChanged.bind(this);

		this.onConfirmedDateFromChanged = this.onConfirmedDateFromChanged.bind(this);
		this.onConfirmedDateToChanged = this.onConfirmedDateToChanged.bind(this);

		this.handleCheck = this.handleCheck.bind(this);
		this.onPeriodChanged = this.onPeriodChanged.bind(this);

		this.onFileNameChanged = this.onFileNameChanged.bind(this);
		this.onConfirmedPeriodChanged = this.onConfirmedPeriodChanged.bind(this);

		this.handleAdvancedSearchClick = this.handleAdvancedSearchClick.bind(this);

		this.onTagsChanged = this.onTagsChanged.bind(this);
		this.onCreatorUserInfoChanged = this.onCreatorUserInfoChanged.bind(this);
		this.onSignerChanged = this.onSignerChanged.bind(this);
		this.onAuthorChanged = this.onAuthorChanged.bind(this);

		this.onSizeFromChanged = this.onSizeFromChanged.bind(this);
		this.onSizeToChanged = this.onSizeToChanged.bind(this);

		this.onSelectedSizeFromUnitChanged = this.onSelectedSizeFromUnitChanged.bind(this);
		this.onSelectedSizeToUnitChanged = this.onSelectedSizeToUnitChanged.bind(this);

		this.keyUpHandler = this.keyUpHandler.bind(this, 'return');
	}

	componentDidMount() {
		var _this = this, advancedSearchBar;

		if (!this.props.base.advancedSearchIsOpen) {
			this.setState({advancedSearchOpen: false})
			this.setState({showAdvancedSearchForm: false})
		} else {
			this.setState({advancedSearchOpen: true})
			this.setState({showAdvancedSearchForm: true})
		}
		
		advancedSearchBar = document.getElementById("advanced-search-bar");
		advancedSearchBar.addEventListener( 'webkitTransitionEnd', function( event ) { _this.setState({showAdvancedSearchForm: _this.state.advancedSearchOpen}) }, false );
		document.addEventListener('keyup', this.keyUpHandler);
	}

	componentWillUnmount() {
		document.removeEventListener('keyup', this.keyUpHandler);
	}

	handleAdvancedSearchClick () {
		this.setState({advancedSearchOpen: !this.state.advancedSearchOpen})
		this.props.actions.setAdvancedSearchIsOpen(!this.state.advancedSearchOpen);
		this.setState({showAdvancedSearchForm: false})
	}

	keyUpHandler(refName, e) {
        if (e.keyCode === 13 && refName === "return") {
			e.preventDefault()
			e.stopPropagation();

			if (e.nativeEvent !== undefined) {
				e.nativeEvent.stopImmediatePropagation();
			}
			document.getElementById("search").click();
			document.activeElement.blur()
        }
    }

	onFileNameChanged (e) {
		this.props.actions.setSearchFileName(e.target.value);
	}

	onPeriodChanged (e) {
		let dateForSearch;
		var currentDate = moment();

		switch (e.target.value) {
			case "0":
				this.setState({"dateFrom": ""})
				this.props.actions.setDocInArchSearchDateFrom(null)
				this.setState({"dateTo": ""})
				this.props.actions.setDocInArchSearchDateTo(null)
				break;

			case "1":
				this.setState({"dateFrom": this.props.actions.formatDateForCalendar(currentDate.format('YYYY-MM-DD'))})
				this.props.actions.setDocInArchSearchDateFrom(currentDate.format('YYYY-MM-DD'))
				this.setState({"dateTo": this.props.actions.formatDateForCalendar(currentDate.format('YYYY-MM-DD'))})
				this.props.actions.setDocInArchSearchDateTo(currentDate.format('YYYY-MM-DD'))
				break;

			case "2":
				this.setState({"dateFrom": this.props.actions.formatDateForCalendar(moment().subtract(1, 'days').format('YYYY-MM-DD'))})
				this.props.actions.setDocInArchSearchDateFrom(moment().subtract(1, 'days').format('YYYY-MM-DD'))
				this.setState({"dateTo": this.props.actions.formatDateForCalendar(moment().subtract(1, 'days').format('YYYY-MM-DD'))})
				this.props.actions.setDocInArchSearchDateTo(moment().subtract(1, 'days').format('YYYY-MM-DD'))
				break;

			case "3":
				this.setState({"dateFrom": this.props.actions.formatDateForCalendar(moment().startOf('isoWeek').format('YYYY-MM-DD'))})
				this.props.actions.setDocInArchSearchDateFrom(moment().startOf('isoWeek').format('YYYY-MM-DD'))
				this.setState({"dateTo": this.props.actions.formatDateForCalendar(moment().format('YYYY-MM-DD'))})
				this.props.actions.setDocInArchSearchDateTo(moment().format('YYYY-MM-DD'))
				break;

			case "4":
				this.setState({"dateFrom": this.props.actions.formatDateForCalendar(moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'))})
				this.props.actions.setDocInArchSearchDateFrom(moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'))
				this.setState({"dateTo": this.props.actions.formatDateForCalendar(moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD'))})
				this.props.actions.setDocInArchSearchDateTo(moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD'))
				break;

			case "5":
				this.setState({"dateFrom": this.props.actions.formatDateForCalendar(moment().startOf('month').format('YYYY-MM-DD'))})
				this.props.actions.setDocInArchSearchDateFrom(moment().startOf('month').format('YYYY-MM-DD'))
				this.setState({"dateTo": this.props.actions.formatDateForCalendar(moment().endOf(moment()).format('YYYY-MM-DD'))})
				this.props.actions.setDocInArchSearchDateTo(moment().endOf(moment()).format('YYYY-MM-DD'))
				break;

			case "6":
				this.setState({"dateFrom": this.props.actions.formatDateForCalendar(moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'))})
				this.props.actions.setDocInArchSearchDateFrom(moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'))
				this.setState({"dateTo": this.props.actions.formatDateForCalendar(moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'))})
				this.props.actions.setDocInArchSearchDateTo(moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'))
				break;

			default:
				dateForSearch = "archiveDate:[" + currentDate.format('YYYY-MM-DD') + " TO " + currentDate.format('YYYY-MM-DD') + "]"
				break;
		}

		this.props.actions.setSelectedPeriod(e.target.value)
		this.props.actions.setSearchStringForPeriods(dateForSearch)
	}

	onConfirmedPeriodChanged (e) {
		var currentConfirmedDate = moment();

		switch (e.target.value) {
			case "0":
				this.setState({"confirmedDateFrom": ""})
				this.props.actions.setDocInArchSearchConfirmedDateFrom(null)
				this.setState({"confirmedDateTo": ""})
				this.props.actions.setDocInArchSearchConfirmedDateTo(null)
				break;

			case "1":
				this.setState({"confirmedDateFrom": this.props.actions.formatDateForCalendar(currentConfirmedDate.format('YYYY-MM-DD'))})
				this.props.actions.setDocInArchSearchConfirmedDateFrom(currentConfirmedDate.format('YYYY-MM-DD'))
				this.setState({"confirmedDateTo": this.props.actions.formatDateForCalendar(currentConfirmedDate.format('YYYY-MM-DD'))})
				this.props.actions.setDocInArchSearchConfirmedDateTo(currentConfirmedDate.format('YYYY-MM-DD'))
				break;

			case "2":
				this.setState({"confirmedDateFrom": this.props.actions.formatDateForCalendar(moment().subtract(1, 'days').format('YYYY-MM-DD'))})
				this.props.actions.setDocInArchSearchConfirmedDateFrom(moment().subtract(1, 'days').format('YYYY-MM-DD'))
				this.setState({"confirmedDateTo": this.props.actions.formatDateForCalendar(moment().subtract(1, 'days').format('YYYY-MM-DD'))})
				this.props.actions.setDocInArchSearchConfirmedDateTo(moment().subtract(1, 'days').format('YYYY-MM-DD'))
				break;

			case "3":
				this.setState({"confirmedDateFrom": this.props.actions.formatDateForCalendar(moment().startOf('isoWeek').format('YYYY-MM-DD'))})
				this.props.actions.setDocInArchSearchConfirmedDateFrom(moment().startOf('isoWeek').format('YYYY-MM-DD'))
				this.setState({"confirmedDateTo": this.props.actions.formatDateForCalendar(moment().format('YYYY-MM-DD'))})
				this.props.actions.setDocInArchSearchConfirmedDateTo(moment().format('YYYY-MM-DD'))
				break;

			case "4":
				this.setState({"confirmedDateFrom": this.props.actions.formatDateForCalendar(moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'))})
				this.props.actions.setDocInArchSearchConfirmedDateFrom(moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'))
				this.setState({"confirmedDateTo": this.props.actions.formatDateForCalendar(moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD'))})
				this.props.actions.setDocInArchSearchConfirmedDateTo(moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD'))
				break;

			case "5":
				this.setState({"confirmedDateFrom": this.props.actions.formatDateForCalendar(moment().startOf('month').format('YYYY-MM-DD'))})
				this.props.actions.setDocInArchSearchConfirmedDateFrom(moment().startOf('month').format('YYYY-MM-DD'))
				this.setState({"confirmedDateTo": this.props.actions.formatDateForCalendar(moment().endOf(moment()).format('YYYY-MM-DD'))})
				this.props.actions.setDocInArchSearchConfirmedDateTo(moment().endOf(moment()).format('YYYY-MM-DD'))
				break;

			case "6":
				this.setState({"confirmedDateFrom": this.props.actions.formatDateForCalendar(moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'))})
				this.props.actions.setDocInArchSearchConfirmedDateFrom(moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'))
				this.setState({"confirmedDateTo": this.props.actions.formatDateForCalendar(moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'))})
				this.props.actions.setDocInArchSearchConfirmedDateTo(moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'))
				break;

			default:
				this.setState({"confirmedDateFrom": this.props.actions.formatDateForCalendar(currentConfirmedDate.format('YYYY-MM-DD'))})
				this.props.actions.setDocInArchSearchConfirmedDateFrom(currentConfirmedDate.format('YYYY-MM-DD'))
				this.setState({"confirmedDateTo": this.props.actions.formatDateForCalendar(currentConfirmedDate.format('YYYY-MM-DD'))})
				this.props.actions.setDocInArchSearchConfirmedDateTo(currentConfirmedDate.format('YYYY-MM-DD'))
				break;
		}

		this.props.actions.setSelectedConfirmedPeriod(e.target.value)
	}

	onDateFromChanged (d) {
        this.props.actions.setDocInArchSearchDateFrom(d)
        this.setState({dateFrom: d})
    }

    onDateToChanged (d) {
        this.props.actions.setDocInArchSearchDateTo(d)
        this.setState({dateTo: d})
    }

    onConfirmedDateFromChanged (d) {
        this.props.actions.setDocInArchSearchConfirmedDateFrom(d)
        this.setState({confirmedDateFrom: d})
    }

    onConfirmedDateToChanged (d) {
        this.props.actions.setDocInArchSearchConfirmedDateTo(d)
        this.setState({confirmedDateTo: d})
    }

	searchData () {
		this.props.searchDocs()
	}

	handleCheck () {
		this.props.actions.setAddedByMe(!this.props.search.addedByMe)

		/*	
			<div className="col-3">
				<label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px", paddingLeft: "0px"}}>{i18next.t("signerLabel")}</label>
				<input className="form-control" type="text" value={this.props.search.signer} onChange={this.onSignerChanged} />
			</div>
			<div className="col-3">
				<label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px", paddingLeft: "0px"}}>{i18next.t("author")}</label>
				<input className="form-control" type="text" value={this.props.search.author} onChange={this.onAuthorChanged} />
			</div>
		*/
	}

	cleanUp () {
		var query;

		this.setState({dateFrom: ""})
		this.setState({dateTo: ""})

		this.setState({confirmedDateFrom: ""})
		this.setState({confirmedDateTo: ""})

		this.props.actions.setAddedByMe(false)

		var params = {
			page: this.props.page.number,
			size: this.props.page.size,
			sortBy: this.props.page.sortBy === "fileName" || this.props.page.sortBy === "description" ? this.props.page.sortBy  + ".keyword" : this.props.page.sortBy,
			sortType: this.props.page.sortType
		}

		query = {
			"queryString" : "id:*"
		}

		this.props.actions.setProcessingStatus(true)
		this.props.actions.searchDataByQueryString(this.props.base.serviceUrl, this.props.login.accessToken, this.props.actions.queryUrl(params), query)
		.then((response) => {
			console.log(response);
			this.props.actions.setALLDocsInArchPaginationParams(response.page)
			this.props.actions.setRecievedData(response.content)
			this.props.actions.setProcessingStatus(false)
		})

		this.props.actions.clearDocInArchSearchSearchParams()
	}

	onOwnNumberChanged (e) {
		this.props.actions.setDocInArchSearchOwnNumber(e.target.value);
	}

	onDescriptionChanged (e) {
		this.props.actions.setDocInArchSearchDescription(e.target.value);
	}

	onIdChanged (e) {
		this.props.actions.setDocInArchSearchId(e.target.value);
	}

	onTagsChanged (e) {
		this.props.actions.setDocInArchSearchTag(e.target.value);
	}

	onCreatorUserInfoChanged (e) {
		this.props.actions.setDocInArchSearchCreatorUserInfo(e.target.value);
	}

	onSignerChanged (e) {
		this.props.actions.setDocInArchSearchSigner(e.target.value);
	}

	onAuthorChanged (e) {
		this.props.actions.setDocInArchSearchAuthor(e.target.value);
	}

	onSizeFromChanged (e) {
		const re = /^[0-9\b]+$/;
	    if (e.target.value === '' || re.test(e.target.value)) {
	        this.props.actions.setDocInArchSearchSizeFrom(e.target.value);
	    }
		
	}

	onSizeToChanged (e) {
		const re = /^[0-9\b]+$/;
	    if (e.target.value === '' || re.test(e.target.value)) {
	        this.props.actions.setDocInArchSearchSizeTo(e.target.value);
	    }
	}

	onSelectedSizeFromUnitChanged (e) {
	    this.props.actions.setDocInArchSearchSelectedSizeFromUnit(e.target.value);
	}

	onSelectedSizeToUnitChanged (e) {
	    this.props.actions.setDocInArchSearchSelectedSizeToUnit(e.target.value);
	}

	render() {
		let serchButtonIsActive = false;
		let advancedSearchBarOpenState = 'advanced-searh-bar'

		if (this.props.base.advancedSearchIsOpen) {
		    advancedSearchBarOpenState = 'advanced-searh-bar open'
		}

		if (this.props.search.ownNumber.length > 0 || this.props.search.dateFrom !== null || this.props.search.dateTo !== null || this.props.search.description.length > 0 || this.props.search.id.length > 0 || this.props.search.addedByMe === true || this.props.search.fileName.length > 0 || this.props.search.tags.length > 0 || this.props.search.author.length > 0 || this.props.search.creatorUserInfo.length > 0 || this.props.search.signer.length > 0 || this.props.search.sizeFrom.length > 0 || this.props.search.sizeTo.length > 0 || this.props.search.confirmedDateFrom !== null || this.props.search.confirmedDateTo !== null) {
			serchButtonIsActive = true;
		}

		return (
			<div className="col-12" style={{padding: "0px"}}>
				<div className="row col-12" style={{padding: "0px"}}>
					<div className="col-3" style={{paddingLeft: "0px"}}>
						<label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px", paddingLeft: "0px"}}>{i18next.t("ownNumber")}</label>
						<input className="form-control" type="text" value={this.props.search.ownNumber} onChange={this.onOwnNumberChanged} />
					</div>
					<div className="row col-3" style={{padding: "0px"}}>
                        <label className="col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px"}}>{i18next.t("archiveDateFrom")}</label>
                        <div className="row col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding">
                            
                            <div className="col-10 no-left-right-margin">
                                <div className="customDatePickerWidth">
                                <DatePicker ref={this.calendarFromRef} dateFormat="dd.MM.yyyy" maxDate={new Date()} className="form-control pointer" onClick={() => {this.calendarFromRef.current.setOpen(true)}}
                                    selected={this.state.dateFrom}
                                    onChange={this.onDateFromChanged} />
                                </div>
                            </div>
                            <div className="col-2 no-left-right-margin">
                                <img className="img" onClick={() => {this.calendarFromRef.current.setOpen(true)}} style={{cursor: "pointer"}}width="50px" height="40px" src={calenderIcon} alt="calenderIcon" />
                            </div>

                        </div>
                    </div>
                    <div className="row col-3" style={{padding: "0px"}}>
                        <label className="col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px"}}>{i18next.t("archiveDateTo")}</label>
                        <div className="row col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding">
                            
                            <div className="col-10 no-left-right-margin">
                                <div className="customDatePickerWidth">
                                <DatePicker ref={this.calendarToRef} dateFormat="dd.MM.yyyy" maxDate={new Date()} className="form-control pointer" onClick={() => {this.calendarToRef.current.setOpen(true)}}
                                    selected={this.state.dateTo}
                                    onChange={this.onDateToChanged} />
                                </div>
                            </div>
                            <div className="col-2 no-left-right-margin">
                                <img className="img" onClick={() => {this.calendarToRef.current.setOpen(true)}} style={{cursor: "pointer"}}width="50px" height="40px" src={calenderIcon} alt="calenderIcon" />
                            </div>

                        </div>
                    </div>
                    <div className="row col-3" style={{paddingLeft: "0px"}}>
                        <label className="col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px"}}>{i18next.t("period")}</label>
                        <div className="row col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding">
                            
                            <div className="col-12 no-left-right-margin">
                            	<select className="form-control" onChange={this.onPeriodChanged} onClick={this.onPeriodChanged}>
                            		{
										this.state.periods.map((value, index) =>
										<option key={index} value={index} selected={index === parseInt(this.props.search.selectedPeriod) ? true : false}>{index === 0 ? "" : i18next.t(value)}</option>)
									}
                            	</select>
                            </div>

                        </div>
                    </div>
				</div>
				<div className="row col-12" style={{padding: "0px"}}>
					<div className="col-6" style={{paddingLeft: "0px"}}>
						<label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px", paddingLeft: "0px"}}>{i18next.t("description")}</label>
						<input className="form-control" type="text" value={this.props.search.description} onChange={this.onDescriptionChanged} />
					</div>
					<div className="col-4" style={{paddingLeft: "15px"}}>
						<label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px", paddingLeft: "0px"}}>{i18next.t("id")}</label>
						<input className="form-control" type="text" value={this.props.search.id} onChange={this.onIdChanged} />
					</div>
					<div className="col-2 abc-checkbox" style={{borderWidth: "0px", borderStyle: "none", paddingTop: "25px", alignSelf: "center"}}>
	                        <input id="checkbox1" onChange={this.handleCheck} checked={this.props.search.addedByMe} type="checkbox" />
	                        <label htmlFor="checkbox1">
	                        	{i18next.t("addedByMeLabel")}
	                        </label>

					</div>
				</div>

				<div id="advanced-search-bar" className={advancedSearchBarOpenState}>
					<div style={{"cursor": "pointer"}}>
						<div style={{float: "right", marginRight: "15px", paddingTop: "10px"}} onClick={this.handleAdvancedSearchClick}>
							{
								this.state.advancedSearchOpen
								? <span>{i18next.t("advancedSearchLabel")}<i className="fa fa-chevron-up" style={{paddingLeft: "5px"}}></i></span>
								: <span>{i18next.t("advancedSearchLabel")}<i className="fa fa-chevron-down" style={{paddingLeft: "5px"}}></i></span>
							}
						</div>
					</div>
					<div>
						{
							this.state.showAdvancedSearchForm
							? <div>
								<div className="row col-12" style={{padding: "0px"}}>
									<div className="col-3" style={{paddingLeft: "0px"}}>
										<label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px", paddingLeft: "0px"}}>{i18next.t("fileNameLabel")}</label>
										<input className="form-control" type="text" value={this.props.search.fileName} onChange={this.onFileNameChanged} />
									</div>
									<div className="row col-3" style={{padding: "0px"}}>
				                        <label className="col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px"}}>{i18next.t("confirmedDateFrom")}</label>
				                        <div className="row col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding">
				                            
				                            <div className="col-10 no-left-right-margin">
				                                <div className="customDatePickerWidth">
				                                <DatePicker ref={this.calendarConfirmedFromRef} dateFormat="dd.MM.yyyy" maxDate={new Date()} className="form-control pointer" onClick={() => {this.calendarConfirmedFromRef.current.setOpen(true)}}
				                                    selected={this.state.confirmedDateFrom}
				                                    onChange={this.onConfirmedDateFromChanged} />
				                                </div>
				                            </div>
				                            <div className="col-2 no-left-right-margin">
				                                <img className="img" onClick={() => {this.calendarConfirmedFromRef.current.setOpen(true)}} style={{cursor: "pointer"}}width="50px" height="40px" src={calenderIcon} alt="calenderIcon" />
				                            </div>

				                        </div>
				                    </div>
				                    <div className="row col-3" style={{padding: "0px"}}>
				                        <label className="col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px"}}>{i18next.t("confirmedDateTo")}</label>
				                        <div className="row col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding">
				                            
				                            <div className="col-10 no-left-right-margin">
				                                <div className="customDatePickerWidth">
				                                <DatePicker ref={this.calendarConfirmedToRef} dateFormat="dd.MM.yyyy" maxDate={new Date()} className="form-control pointer" onClick={() => {this.calendarConfirmedToRef.current.setOpen(true)}}
				                                    selected={this.state.confirmedDateTo}
				                                    onChange={this.onConfirmedDateToChanged} />
				                                </div>
				                            </div>
				                            <div className="col-2 no-left-right-margin">
				                                <img className="img" onClick={() => {this.calendarConfirmedToRef.current.setOpen(true)}} style={{cursor: "pointer"}}width="50px" height="40px" src={calenderIcon} alt="calenderIcon" />
				                            </div>

				                        </div>
				                    </div>
				                    <div className="row col-3" style={{paddingLeft: "0px"}}>
				                        <label className="col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px"}}>{i18next.t("period")}</label>
				                        <div className="row col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding">
				                            
				                            <div className="col-12 no-left-right-margin">
				                            	<select className="form-control" onChange={this.onConfirmedPeriodChanged} onClick={this.onConfirmedPeriodChanged}>
				                            		{
														this.state.confirmedPeriods.map((value, index) =>
														<option key={index} value={index} selected={index === parseInt(this.props.search.selectedConfirmedPeriod) ? true : false}>{index === 0 ? "" : i18next.t(value)}</option>)
													}
				                            	</select>
				                            </div>

				                        </div>
				                    </div>
								</div>

								<div className="row col-12" style={{padding: "0px"}}>
									<div className="col-6" style={{paddingLeft: "0px"}}>
										<label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px", paddingLeft: "0px"}}>{i18next.t("tags")}</label>
										<input className="form-control" type="text" value={this.props.search.tags} onChange={this.onTagsChanged} />
									</div>
									<div className="col-2" style={{paddingLeft: "15px"}}>
										<label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px", paddingLeft: "0px"}}>{i18next.t("docSizeFrom")}</label>
										<input className="form-control" type="text" value={this.props.search.sizeFrom} onChange={this.onSizeFromChanged} />
									</div>
									<div className="col-1" style={{paddingLeft: "15px"}}>
										<label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px", paddingLeft: "0px"}}>&nbsp;</label>
										<div className="col-12 no-left-right-margin">
			                            	<select className="form-control" onChange={this.onSelectedSizeFromUnitChanged} onClick={this.onSelectedSizeFromUnitChanged}>
			                            		{
													this.state.sizeFromUnit.map((value, index) =>
													<option key={index} value={value} selected={value === this.props.search.selectedSizeFromUnit ? true : false}>{i18next.t(value)}</option>)
												}
			                            	</select>
			                            </div>
									</div>
									<div className="col-2" style={{paddingLeft: "15px"}}>
										<label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px", paddingLeft: "0px"}}>{i18next.t("docSizeTo")}</label>
										<input className="form-control" type="text" value={this.props.search.sizeTo} onChange={this.onSizeToChanged} />
									</div>
									<div className="col-1" style={{paddingLeft: "15px"}}>
										<label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px", paddingLeft: "0px"}}>&nbsp;</label>
										<div className="col-12 no-left-right-margin">
			                            	<select className="form-control" onChange={this.onSelectedSizeToUnitChanged} onClick={this.onSelectedSizeToUnitChanged}>
			                            		{
													this.state.sizeToUnit.map((value, index) =>
													<option key={index} value={value} selected={value === this.props.search.selectedSizeToUnit ? true : false}>{i18next.t(value)}</option>)
												}
			                            	</select>
			                            </div>
									</div>
								</div>

								<div className="row col-12" style={{padding: "0px"}}>
									<div className="col-3" style={{paddingLeft: "0px"}}>
										<label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px", paddingLeft: "0px"}}>{i18next.t("creatorUserLabel")}</label>
										<input className="form-control" type="text" value={this.props.search.creatorUserInfo} onChange={this.onCreatorUserInfoChanged} />
									</div>
									<div className="col-3">
										<label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px", paddingLeft: "0px"}}>{i18next.t("signerLabel")}</label>
										<input className="form-control" type="text" value={this.props.search.signer} onChange={this.onSignerChanged} />
									</div>
									<div className="col-3">
										<label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px", paddingLeft: "0px"}}>{i18next.t("author")}</label>
										<input className="form-control" type="text" value={this.props.search.author} onChange={this.onAuthorChanged} />
									</div>
								</div>
							</div>
							: null
						}
					</div>
				</div>

				<div style={{float: "right", marginRight: "15px", marginTop: "10px"}}>
					<button id="search" className="btn btn-info" disabled={!serchButtonIsActive} onClick={this.searchData}>{i18next.t("searchLabel")}</button>&nbsp;
					<button className="btn btn-outline-info" onClick={this.cleanUp}>{i18next.t("cleanUpLabel")}</button>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
    	search: state.docsInArchSearchReducer,
    	page: state.mainPagePagingReducer,
    	login: state.loginReducer,
    	base: state.base
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	setDocInArchSearchId, setDocInArchSearchDateFrom,
    	setDocInArchSearchDateTo, setDocInArchSearchDescription,
    	setDocInArchSearchOwnNumber, clearDocInArchSearchSearchParams,
    	formatDateForCalendar, setProcessingStatus,
    	setALLDocsInArchPaginationParams, setRecievedData,
    	searchDataByQueryString, queryUrl, setAddedByMe,
    	setSelectedPeriod, setSearchStringForPeriods,
    	setAdvancedSearchIsOpen, setSearchFileName, setSelectedConfirmedPeriod,
    	setDocInArchSearchConfirmedDateFrom, setDocInArchSearchConfirmedDateTo,
    	setDocInArchSearchTag, setDocInArchSearchAuthor, setDocInArchSearchCreatorUserInfo,
		setDocInArchSearchSigner, setDocInArchSearchSelectedSizeFromUnit,
		setDocInArchSearchSelectedSizeToUnit, setDocInArchSearchSizeFrom,
		setDocInArchSearchSizeTo
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DocsInArchSearchView);