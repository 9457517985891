import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Cookies from 'universal-cookie';

import { clearLoggedInUserData, clearToken, setIsLoggedIn, setSelectedUser, setAllUserFields,
			changeSelectedTab, setLoginInput, setPasswordInput, setShowUserInfo, clearDocInArchSearchSearchParams, changeSearchState, setTwoFactorAvailabelChannels,
			setShowTwoFactorSettings, setShowLogoutHandler, setTwoFactorEnabled, setIs2fEnabled, setTwoFactorCode } from '../actions/localStates'
import { getUser, getAvailabelChannels } from '../actions/api'

import AboutPopup from './AboutPopup'
import Popup from '../containers/Popup'
import TwoFactorContainerPopup from '../containers/TwoFactorContainerPopup'

import LogoutPopup from './LogoutPopup'

import i18next from 'i18next'

const cookies = new Cookies();

class Header extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    	showOptions: false,
	    	showPopup: false,
	    	showApps: false,
	    	showBar: true,
	    	showPasswordPopup: false
	    }

	    this.handleShowOptions = this.handleShowOptions.bind(this);
	    this.closeMenu = this.closeMenu.bind(this);
	    this.logoutHandler = this.logoutHandler.bind(this);
	    this.getCurrentUser = this.getCurrentUser.bind(this);
	    this.showVersion = this.showVersion.bind(this);

	    this.handleShowApps = this.handleShowApps.bind(this);
	    this.closeApps = this.closeApps.bind(this);

	    this.redirect = this.redirect.bind(this);

	    this.twoFactorSettings = this.twoFactorSettings.bind(this);

	    this.handleChangePassword = this.handleChangePassword.bind(this);
	}

	handleShowOptions (event) {
		event.preventDefault();
		this.setState({
				showOptions: !this.state.showOptions
			}, () => {
			document.addEventListener('click', this.closeMenu);
		});
	}

	handleShowApps (event) {
		event.preventDefault();
		this.setState({
				showApps: !this.state.showApps
			}, () => {
			document.addEventListener('click', this.closeApps);
		});
	}

	twoFactorSettings () {
		if (!this.props.login.update2fSettingsGrant) {
			return
		}

		// var currentTime = new Date().getTime() / 1000;
		// if (currentTime > this.props.base.expireTime) {
		// 	this.props.actions.setShowSessionEndHandler(true);
		// 	return;
		// };

		this.props.actions.getAvailabelChannels(this.props.base.authUrl, this.props.login.accessToken)
		.then((response) => {
			this.props.actions.setTwoFactorAvailabelChannels([{id: "disabled",title: i18next.t("disabledTitle")}].concat(response));
			console.log(response);
		});

		this.props.actions.setAllUserFields(this.props.login.userData);
		this.props.actions.setShowTwoFactorSettings(true);
	}

	logoutHandler () {
		this.props.actions.clearDocInArchSearchSearchParams();
		this.props.actions.changeSearchState(false);

		this.props.actions.clearLoggedInUserData();
		this.props.actions.clearToken();
		this.props.actions.setIsLoggedIn(false);

		this.props.actions.changeSelectedTab(0);
		this.props.actions.setLoginInput("");
		this.props.actions.setPasswordInput("");
		this.props.actions.setShowLogoutHandler(false)

		this.props.actions.setTwoFactorEnabled(false);
        this.props.actions.setIs2fEnabled(false);
        this.props.actions.setTwoFactorCode("")
	}

	componentDidMount() {
		
	}

	showVersion () {
		this.setState({
	        showPopup: !this.state.showPopup
	    });
	}

	closePopup () {
		this.setState({
	        showPopup: !this.state.showPopup
	    });
	}

	getCurrentUser () {
		// var name = "?userName=" + this.props.login.loggedInUserId + "&status=ACTIVE";
		// this.props.actions.getUser(this.props.base.authUrl, name, this.props.login.accessToken)
		// .then((response) => {
  //   		this.props.actions.setSelectedUser(response.content[0]);
  //   		this.props.actions.setAllUserFields(response.content[0]);
  //   		this.props.actions.setShowUserInfo(true);
		// })

		this.props.actions.setSelectedUser(this.props.login.userData);
		this.props.actions.setAllUserFields(this.props.login.userData);
		this.props.actions.setShowUserInfo(true);
	}

	redirect () {

	}

	closeTwoFactorContainer () {
  		this.props.actions.setShowTwoFactorSettings(false);
  	}

	showMenu(event) {
		event.preventDefault();

		this.setState({ showOptions: true }, () => {
			document.addEventListener('click', this.closeMenu);
		});
	}

	closeMenu(event) { 
		this.setState({ showOptions: false }, () => {
			document.removeEventListener('click', this.closeMenu);
		});  
	}

	closeApps(event) { 
		this.setState({ showApps: false }, () => {
			document.removeEventListener('click', this.closeApps);
		});  
	}

	redirectToApp(url) {
		if (this.props.login.accessToken !== null) {
			cookies.set('tempTokenData', this.props.login.accessToken, { path: '/', expires: new Date(Date.now() + 10000), domain: this.props.base.domain });
		}

		window.location.href = url;
	}

	handleChangePassword () {
		this.setState({
	        showPasswordPopup: !this.state.showPasswordPopup
	    });
	}

	render() {
		let showOptionsOpen = 'dropdown-menu'
		if (this.state.showOptions) {
			showOptionsOpen = 'dropdown-menu show'
		}

		let showAppsOpen = 'dropdown-menu'
		if (this.state.showApps) {
			showAppsOpen = 'dropdown-menu show'
		}

		let url = this.props.login.urlForRedirection

		return (
			<div style={{width: "100%", height: "60px", backgroundColor: "#1C92C7", positon: "fixed", top: "0px", left: "0px", zIndex: "10"}}>
				<div className="row col-12" style={{paddingLeft: "0px", paddingRight: "0px"}}>
					{
						this.props.base.apps !== undefined && this.props.base.apps.length > 0
						? <div style={{paddingRight: "0px", paddingTop: "5px", float: "right"}}>
							<div style={{textAlign: "right",  color: "white"}}>
								<div className="btn-group">
									<button type="button" style={{color: "white"}} onClick={this.handleShowApps} className="btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.showApps}>
										<i className="fa fa-bars fa-2x"></i>
									</button>
									<div className={showAppsOpen} style={{minWidth: "200px", width: "100%", backgroundColor: "#1C92C7"}}>
										{
											this.props.base.apps.map((item, index) => {
												return <span className="dropdown-item header-span" key={index} onClick={this.redirectToApp.bind(this, item.url)} style={{color: "white", textDecoration: "none", cursor: "pointer"}}>{item.clientTitle}</span>
											})	
										}
									</div>
								</div>
							</div>
						</div>
						: null
					}
					
					<span className="col-8" style={{textAlign: "left", fontSize: "24px", paddingTop: "10px", color: "white"}}>
						{i18next.t("headerTitle")}
					</span>
					<span className="col-2" style={{textAlign: "right"}}>
						
					</span>
					<div style={{paddingRight: "0px", paddingTop: "15px", float: "right"}}>
						{
							this.props.login.loggedInUserData
							? <div style={{textAlign: "right",  color: "white"}}>
								<div className="btn-group dropdown topright" style={{minWidth: "200px"}}>
									<button type="button" style={{color: "white"}} onClick={this.handleShowOptions} className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.showOptions}>
										<span>{this.props.login.loggedInUserName}</span>&nbsp;
										<span style={{textAlign: "right"}}>({this.props.login.loggedInUserId})</span>
									</button>
									<div className={showOptionsOpen} style={{minWidth: "200px", width: "100%", backgroundColor: "#1C92C7"}}>
										<span className="dropdown-item header-span" onClick={this.getCurrentUser} style={{cursor: "pointer", color: "white"}}>{i18next.t("userDataButtonText")}</span>
										{
											this.props.base.urlForAuth !== undefined && this.props.base.urlForAuth.length > 0
											? <span className="dropdown-item header-span" style={{cursor: "pointer"}}><a href={url} style={{color: "white", textDecoration: "none"}}>{this.props.base.clientTitle}</a></span>
											: null
										}
										{
											this.props.login.update2fSettingsGrant
											? <span className="dropdown-item header-span" style={{cursor: "pointer", color: "white"}} onClick={this.twoFactorSettings}>{i18next.t("twoFactor")}</span>
											: null
										}

										{
											this.props.login.changePasswordGrant
											? <span className="dropdown-item header-span" onClick={this.handleChangePassword} style={{cursor: "pointer", color: "white"}}>{i18next.t("changePasswordLabel")}</span>
											: null
										}
										<span className="dropdown-item header-span" style={{cursor: "pointer", color: "white"}} onClick={this.showVersion}>{i18next.t("aboutButtonText")}</span>
										<span className="dropdown-item header-span" onClick={this.logoutHandler} style={{cursor: "pointer", color: "white"}}>{i18next.t("exitButtonText")}</span>
									</div>
								</div>
							</div>
							: null
						}
					</div>
					{
						this.state.showPopup
						? <AboutPopup closePopup={this.closePopup.bind(this)} />
			            : null
			        }
			        {
						this.state.showPasswordPopup
						? <Popup closePopup={this.handleChangePassword.bind(this)} />
			            : null
			        }
			        {
						this.props.login.showTwoFactorSettings
						? <TwoFactorContainerPopup closeTwoFactorContainerPopup={this.closeTwoFactorContainer.bind(this)} />
						: null
					}

					{
						this.props.base.showLogoutHandler
						? <LogoutPopup logout={this.logoutHandler} />
			            : null
			        }
				</div>	
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	clearLoggedInUserData, clearToken,
	    setIsLoggedIn, getUser,
	    setSelectedUser,
	    changeSelectedTab,
		setAllUserFields,
		setLoginInput, setPasswordInput,
		setShowUserInfo,
		clearDocInArchSearchSearchParams, changeSearchState,
		getAvailabelChannels, setTwoFactorAvailabelChannels,
		setShowTwoFactorSettings, setShowLogoutHandler,
		setTwoFactorEnabled, setIs2fEnabled, setTwoFactorCode
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);