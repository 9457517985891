import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { changeUserPassword } from '../actions/api'

import { setShowLogoutHandler } from '../actions/localStates'

import i18next from 'i18next'

class Popup extends Component {

  constructor(props) {
      super(props);
      this.state = {
          oldPass: "",
          newPass: "",
          confirmPass: "",
          showOldPassword: false,
          showNewPassword: false,
          showConfirmPassword: false,
          oldPassIsEmpty: false,
          newPassIsEmpty: false,
          confirmedPasswordNotEqual: false,
          passwordMinLength: true,
          errorMessageForOldPass: "",
          errorMessageForNewPass: "",
          errorMessageForConfirmPassword: ""
      }

      this.changePassword = this.changePassword.bind(this);
      this.handleChangeOldPass = this.handleChangeOldPass.bind(this);
      this.handleChangeNewPass = this.handleChangeNewPass.bind(this);
      this.handleChangeConfirmPass = this.handleChangeConfirmPass.bind(this);

      this.validateFields = this.validateFields.bind(this);

      this.keyUpHandler = this.keyUpHandler.bind(this, 'changePass');
  }

  componentDidMount() {
    document.addEventListener('keyup', this.keyUpHandler);
  }

  componentWillUnmount () {
      document.removeEventListener('keyup', this.keyUpHandler);
  }

  keyUpHandler(refName, e) {
    if (e.keyCode === 13) {
      this.validateFields()
    }
  }

  validateFields () {
      this.state.oldPass.length > 0
        ? this.setState({oldPassIsEmpty: false})
        : this.setState({oldPassIsEmpty: true}, () => {
          this.setState({errorMessageForOldPass: "needToBeFilledField"})
          })

      this.state.newPass.length > 0
        ? this.setState({newPassIsEmpty: false})
        : this.setState({newPassIsEmpty: true}, () => {
          this.setState({errorMessageForNewPass: "needToBeFilledField"})
          })

      if (!this.state.oldPassIsEmpty && !this.state.newPassIsEmpty) {
        if ((this.state.newPass.length >= 8) && (this.state.newPass === this.state.confirmPass)) {
          this.setState({confirmedPasswordNotEqual: false})
          this.changePassword()
        } else {
          this.setState({confirmedPasswordNotEqual: true}, () => {
            this.setState({errorMessageForConfirmPassword: "needToBeFilledField"})
          })
        }
      }
  }

  hoverOldPass() {
        this.setState({showOldPassword: true})
  }

  outOldPass() {
      this.setState({showOldPassword: false})
  }

  hoverNewPass() {
        this.setState({showNewPassword: true})
  }

  outNewPass() {
      this.setState({showNewPassword: false})
  }

  hoverConfirmPass() {
        this.setState({showConfirmPassword: true})
  }

  outConfirmPass() {
      this.setState({showConfirmPassword: false})
  }

  handleChangeOldPass (e) {
    this.setState({oldPass: e.target.value})
    e.target.value.length > 0
      ? this.setState({oldPassIsEmpty: false}, () => {
        this.setState({errorMessageForOldPass: ""})
        })
      : this.setState({oldPassIsEmpty: true}, () => {
        this.setState({errorMessageForOldPass: "needToBeFilledField"})
        })

  }

  handleChangeNewPass (e) {
    this.setState({newPass: e.target.value})
    e.target.value.length > 0 && e.target.value.length < 8
      ? this.setState({newPassIsEmpty: false}, () => {
        this.setState({errorMessageForNewPass: "passMinLength"})
        })
      : this.setState({newPassIsEmpty: false}, () => {
        this.setState({errorMessageForNewPass: ""})
        })
  }

  handleChangeConfirmPass (e) {
    this.setState({confirmPass: e.target.value})
    this.state.newPass === e.target.value
    ? this.setState({confirmedPasswordNotEqual: false}, () => {
      this.setState({errorMessageForConfirmPassword: ""})
      })
    : this.setState({confirmedPasswordNotEqual: true}, () => {
      this.setState({errorMessageForConfirmPassword: "passNotEqual"})
      })
  }

  changePassword () {
    var _this = this
      var pass = {
        "oldPassword": this.state.oldPass,
        "newPassword": this.state.newPass
      }

      // var pass = {
      //   "newPassword": this.state.newPass
      // }

      this.props.actions.changeUserPassword(this.props.base.authUrl, pass, this.props.login.accessToken)
          .then((response) => {
              if (response.code !== undefined) {
                  alert(response.message);
                  return
              }
              if (response) {
                alert(i18next.t("passChangedSuccess"));
                _this.props.actions.setShowLogoutHandler(true);
                _this.props.closePopup();
              }
          })
  }

  render() {
      return (
          <div className='popup'>
              <div className={
                (this.state.errorMessageForNewPass.length > 0 || this.state.errorMessageForConfirmPassword.length > 0)
                ? "popup_inner_password_error"
                : "popup_inner_password"}>
                  <div className="col-12">
                      <h4 style={{paddingTop: "30px"}}>{i18next.t("changePassFormHeader")}</h4>
                      {
                        <div>
                          <label className="col-12 col-form-label" style={{fontSize: "15px", paddingLeft: "0px", color: "#b5b1af"}}>{i18next.t("oldPassLabel")}</label>
                          <div className="col-12 input-group" style={{paddingLeft: "0px", paddingRight: "0px"}}>
                              <input className={this.state.oldPassIsEmpty ? "form-control is-invalid" : "form-control"} value={this.state.oldPass} type={this.state.showOldPassword ? "input" : "password"} onChange={this.handleChangeOldPass} />
                              <div className="input-group-append">
                                  <div className="input-group-text" onMouseOver={this.hoverOldPass.bind(this)} onMouseOut={this.outOldPass.bind(this)}><i className="fa fa-eye"></i></div>
                              </div>
                          </div>
                          {
                              this.state.errorMessageForOldPass.length > 0
                              ? <span style={{color: "red"}}>{i18next.t(this.state.errorMessageForOldPass)}</span>
                              : null
                          }
                        </div>
                      }
                      <div>
                          <label className="col-12 col-form-label" style={{fontSize: "15px", paddingLeft: "0px", color: "#b5b1af"}}>{i18next.t("newPassLabel")}</label>
                          <div className="col-12 input-group" style={{paddingLeft: "0px", paddingRight: "0px"}}>
                              <input className={(this.state.errorMessageForNewPass.length > 0) ? "form-control is-invalid" : "form-control"} value={this.state.newPass} type={this.state.showNewPassword ? "input" : "password"} onChange={this.handleChangeNewPass} />
                              <div className="input-group-append">
                                  <div className="input-group-text" onMouseOver={this.hoverNewPass.bind(this)} onMouseOut={this.outNewPass.bind(this)}><i className="fa fa-eye"></i></div>
                              </div>
                          </div>
                          {
                              this.state.errorMessageForNewPass.length > 0
                              ? <span style={{color: "red"}}>{i18next.t(this.state.errorMessageForNewPass)}</span>
                              : null
                          }
                      </div>
                      <div>
                          <label className="col-12 col-form-label" style={{fontSize: "15px", paddingLeft: "0px", color: "#b5b1af"}}>{i18next.t("confirmNewPassLabel")}</label>
                          <div className="col-12 input-group" style={{paddingLeft: "0px", paddingRight: "0px"}}>
                              <input className={this.state.confirmedPasswordNotEqual ? "form-control is-invalid" : "form-control"} value={this.state.confirmPass} type={this.state.showConfirmPassword ? "input" : "password"} onChange={this.handleChangeConfirmPass} />
                              <div className="input-group-append">
                                  <div className="input-group-text" onMouseOver={this.hoverConfirmPass.bind(this)} onMouseOut={this.outConfirmPass.bind(this)}><i className="fa fa-eye"></i></div>
                              </div>
                          </div>
                          {
                              this.state.errorMessageForConfirmPassword.length > 0
                              ? <span style={{color: "red"}}>{i18next.t(this.state.errorMessageForConfirmPassword)}</span>
                              : null
                          }
                      </div>
                      <div style={{float: "right", paddingTop: "10px", paddingBottom: "30px"}}>
                          <button className="btn btn-info" style={{marginLeft: "5px"}} onClick={this.validateFields}>{i18next.t("changePasswordLabel")}</button>
                          <button className="btn btn-outline-info" style={{marginLeft: "5px"}} onClick={this.props.closePopup}>{i18next.t("cancelLabel")}</button>
                      </div>
                  </div>
              </div>
          </div>
      );
    }
}

function mapStateToProps(state) {
    return {
        base: state.base,
        selectedUser: state.userReducer,
        login: state.loginReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
      changeUserPassword,
      setShowLogoutHandler
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Popup);
