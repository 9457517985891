import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getMetadata, getObjectMetadata, getTechSignature, getObjectTechSignatureVerificationResults, getObjectSignatureVerificationResults, getObjectStatus, getSignature } from '../actions/api'
import { setRecievedData, setProcessingStatus, setUploadDataIsDone, setSetTechSignatureArr, setTachSignatureVerfyResultsArr, setUploadSignersDataResult,
			setUploadResultData, setActivityLog, changeSelectedTab, clearUploadResultData, setShowActionResult, clearUploadSignersDataResult,
			setActionResultData, setDocSignatures, clearAllAuthorFields, setPreviousTab } from '../actions/localStates'

import DocsInArchView from '../components/DocsInArchView'

class DocsInArchContainer extends Component {
	constructor() {
		super();
		this.state = {

		};

		this.getSelectedDoc = this.getSelectedDoc.bind(this);
	}

	componentDidMount() {
		// this.props.actions.setActionResultData({status: "success", action: "updatingFileData", message: "temp string"})
		// this.props.actions.setShowActionResult(true)
		// this.props.actions.setProcessingStatus(true)
		// this.props.actions.getMetadata(this.props.base.serviceUrl, "?page=0&size=20&status=PUBLISHED")
		// .then((response) => {
		// 	console.log(response);
		// 	this.props.actions.setRecievedData(response.content)
		// 	this.props.actions.setProcessingStatus(false)
		// })
	}

	getSelectedDoc (doc) {
		this.props.actions.clearUploadResultData();
		this.props.actions.clearUploadSignersDataResult();
		this.props.actions.clearAllAuthorFields();
				

		this.props.actions.getObjectTechSignatureVerificationResults(this.props.base.authServiceUrl, this.props.login.accessToken, doc.id)
		.then((response) => {
			if (response.code !== undefined && response.code.length > 0) {
				this.props.actions.setProcessingStatus(false)
				alert(response.message);
				return;
			}

			this.props.actions.setTachSignatureVerfyResultsArr(response)
			console.log(response)

			this.props.actions.getObjectSignatureVerificationResults(this.props.base.authServiceUrl, this.props.login.accessToken, doc.id)
			.then((response) => {
				if (response.code !== undefined && response.code.length > 0) {
					this.props.actions.setProcessingStatus(false)
					alert(response.message);
					return;
				}

				this.props.actions.setUploadSignersDataResult(response)

                this.props.actions.getObjectMetadata(this.props.base.authServiceUrl, this.props.login.accessToken, doc.id)
				.then((response) => {
					if (response.code !== undefined && response.code.length > 0) {
						this.props.actions.setProcessingStatus(false)

						this.props.actions.setActionResultData({status: "failed", action: "viewDoc", message: response.message})
                		this.props.actions.setShowActionResult(true)

						return;
					}

					this.props.actions.setUploadResultData(response)

					this.props.actions.getObjectStatus(this.props.base.authServiceUrl, this.props.login.accessToken, doc.id)
					.then((response) => {
						if (response.code !== undefined && response.code.length > 0) {
							this.props.actions.setProcessingStatus(false)
							alert(response.message);
							return;
						}
						console.log(response);

						this.props.actions.setActivityLog(response)

						this.props.actions.setProcessingStatus(false)
						this.props.actions.setUploadDataIsDone(true)
						this.props.actions.setPreviousTab(0)
						this.props.actions.changeSelectedTab(1);
					})
				})
            })
		})
    }

	componentWillUnmount () {
	}

	render() {
		return (
			<DocsInArchView getDoc={this.getSelectedDoc} />
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	getMetadata,clearUploadResultData,
    	setRecievedData, clearUploadSignersDataResult,
    	setProcessingStatus, getSignature,
    	getObjectMetadata, setShowActionResult,
    	setUploadDataIsDone, setPreviousTab,
    	getTechSignature, getObjectTechSignatureVerificationResults,
    	getObjectSignatureVerificationResults, getObjectStatus,
    	setSetTechSignatureArr, setTachSignatureVerfyResultsArr,
    	setUploadSignersDataResult, changeSelectedTab,
		setUploadResultData, setActivityLog,
		setActionResultData, setDocSignatures, clearAllAuthorFields
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DocsInArchContainer);