export const getDefaults = () => (dispatch) => {
    return fetch(process.env.PUBLIC_URL + "/config.json", {
        method: 'GET',
        dataType: "json"
      }).then(response => {
        return response.json()
    });
}

export const getVersion = () => (dispatch) => {
    return fetch(process.env.PUBLIC_URL + "/version.json", {
        method: 'GET',
        dataType: "json"
      }).then(response => {
        return response.json()
    });
}

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.status);
    }
    return response;
}

export const pushObject = (url, token, version, data) => (dispatch) => {
    return fetch(url + "/arch/api/v1/object/" + version, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/octet-stream',
          'Authorization' : `Bearer ${token}`
        },
        body: data
      }).then(response => {
        return response.json()
    });
}

export const getTechSignature = (url, token, id) => (dispatch) => {
    return fetch(url + "/arch/api/v1/object/" + id + "/techSignature", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
        }
      }).then(response => {
        return response.json()
    });
}

export const getSignature = (url, token, id) => (dispatch) => {
    return fetch(url + "/arch/api/v1/object/" + id + "/signature", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
        }
      }).then(response => {
        return response.json()
    });
}

export const publishObject = (url, token, id) => (dispatch) => {
    return fetch(url + "/arch/api/v1/object/" + id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
        }
      }).then(response => {
        if (response.ok) {
            return true;
        } else {
            return response.json()
        }
    });
}

export const deleteObject = (url, token, id) => (dispatch) => {
    return fetch(url + "/arch/api/v1/object/" + id, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
        }
      }).then(response => {
        if (response.ok) {
            return true;
        } else {
            return response.json()
        }
    });
}

export const pushSignature = (url, token, id, data) => (dispatch) => {
    return fetch(url + "/arch/api/v1/object/" + id + "/signature", {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/octet-stream',
          'Authorization' : `Bearer ${token}`
        },
        body: data
      }).then(response => {
        if (response.ok) {
            return true;
        } else {
            return response.json()
        }
    });
}

export const deleteSignature = (url, token, id) => (dispatch) => {
    return fetch(url + "/arch/api/v1/object/" + id + "/signature", {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
        }
      }).then(response => {
        if (response.ok) {
            return true;
        } else {
            return response.json()
        }
    });
}

export const pushMetadata = (url, token, id, metadata) => (dispatch) => {
    return fetch(url + "/arch/api/v1/object/" + id + "/metadata", {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
        },
        body: metadata
      }).then(response => {
        if (response.ok) {
            return true;
        } else {
            return response.json()
        }
    });
}

export const getObjectData = (url, token, id) => (dispatch) => {
    return fetch(url + "/arch/api/v1/object/" + id + "/data", {
        method: 'GET',
        headers: {
            'Content-Type': 'application/octet-stream',
            'Authorization' : `Bearer ${token}`
        }
      }).then(response => {
        if (response.ok) {
            return response.blob()
        } else {
            return response.json()
        }
    });
}

export const getObjectMetadata = (url, token, id) => (dispatch) => {
    return fetch(url + "/arch/api/v1/object/" + id + "/metadata", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
        }
      }).then(response => {
        return response.json()
    });
}

export const getMetadata = (url, token, params) => (dispatch) => {
    return fetch(url + "/arch/api/v1/object/metadata" + params, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
        }
      }).then(response => {
        return response.json()
    });
}

export const getObjectSignatureVerificationResults = (url, token, id) => (dispatch) => {
    return fetch(url + "/arch/api/v1/object/" + id + "/signature/vr", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
        }
      }).then(response => {
        return response.json()
    });
}

export const getObjectTechSignatureVerificationResults = (url, token, id) => (dispatch) => {
    return fetch(url + "/arch/api/v1/object/" + id + "/techSignature/vr", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
        }
      }).then(response => {
        return response.json()
    });
}

export const getObjectStatus = (url, token, id) => (dispatch) => {
    return fetch(url + "/arch/api/v1/object/" + id + "/status", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
        }
      }).then(response => {
        return response.json()
    });
}

export const validateObject = (url, token, id) => (dispatch) => {
    return fetch(url + "/arch/api/v1/object/" + id + "/validator", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
        }
      }).then(response => {
        return response.json()
    });
}

export const searchDataByQueryString = (url, token, params, query) => (dispatch) => {
    return fetch(url + "/arch/api/v1/se/" + params, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
        },
        body: JSON.stringify(query)
      }).then(handleErrors)
      .then(response => {
        return response.json()
      })
      .catch(error => {
        console.log('Request failed', error);
        return null; 
      });
}

export const login = (url, user) => (dispatch) => {
  return fetch(url + "/token", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      dataType: "json",
      body: JSON.stringify(user)
    }).then(response => {
      if(response.ok) {
        return response.json()
      } else {
        return response.json();
      }
    })
}

export const login2f = (url, data, token) => (dispatch) => {
  return fetch(url + "/token.user", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data),
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json();
      } else {
        return response.json();
      }
    })
}

export const verifyToken = (url, token) => (dispatch) => {
  return fetch(url + "/token", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json'
      },
      body: token,
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json()
      }
    })
}

export const getConfiguration = (url, token) => (dispatch) => {
  return fetch(url + "/configuration/", {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json()
      } else {
        return response.json()
      }
    })
}

export const getUser = (url, userName, token) => (dispatch) => {

  return fetch(url + "/user" + userName, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json()
      }
    })
}

export const getUserProfile = (url, token) => (dispatch) => {

  return fetch(url + "/user/profile", {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      console.log(response.headers);
      if(response.ok) {
        return response.json()
      }
    })
}

export const getPublicKeyCurrentUtcDateTime = (url, token) => (dispatch) => {

  return fetch(url + "/protectedParameters/settings", {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      }
    }).then(response => {
      if(response.ok) {
        return response.json()
      }
    })
}

export const init2f = (url, data, token) => (dispatch) => {
  return fetch(url + "/token.user.2f", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data),
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json();
      } else {
        return response.json();
      }
    })
}

export const changeUserPassword = (url, pass, token) => (dispatch) => {
  return fetch(url + "/user/self/password", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(pass),
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return true;
      } else {
        return response.json();
      }
    })
}

export const getAvailabelChannels = (url, token) => (dispatch) => {
  return fetch(url + "/2f", {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json();
      } else {
        return response.json();
      }
    })
}

export const update2fUserInfo = (url, data, token) => (dispatch) => {
  return fetch(url + "/user/self/2f", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data),
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return true;
      } else {
        return response.json();
      }
    })
}