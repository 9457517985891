const baseState = {
    data: null,
    selectedAll: false,
    selectedStatus: "CREATION_INITIATED",
    statusValuesArr: ["PUBLICATION_INITIATED", "REMOVING_INITIATED"]
}

const docsWithWrongStatusReducer = (state = baseState, { type, payload }) => {

    switch (type) {
        case "SET_DOCS_WITH_WRONG_STATUS_DATA": {
            return {
                ...state,
                data: payload
            }
        }

        case "SET_SELECTED_STATUS_FOR_DOCS_WITH_WRONG_STATUS": {
            return {
                ...state,
                selectedStatus: payload
            }
        }

        case "SET_STATUS_VALUES_FOR_DOCS_WITH_WRONG_STATUS": {
            return {
                ...state,
                statusValuesArr: payload
            }
        }

        case "SET_SELECTED_ALL": {
            return {
                ...state,
                selectedAll: payload
            }
        }

        default:
            return state;
    }
}

export default docsWithWrongStatusReducer