export const pageUrl = (params) => (dispatch) => {
	var stringUrl = "";

	stringUrl = params.url + "/" + params.type + "?page=" + params.page + "&size=" + params.size

    return stringUrl;
}

export const tokenUrl = (params) => (dispatch) => {
	var stringUrl = "";

	stringUrl = params.url + "/" + params.type + "?page=" + params.page + "&size=" + params.size + "&subjectType=" + params.subjectType + "&subject=" + params.subject

    return stringUrl;
}

export const searchUrl = (params) => (dispatch) => {
	var stringUrl = "", url = "", type = "", page = "", size = "", clientName = "", fullName = "", status = "",
		userName = "", firstName = "", secondName = "";

	if (params.url !== undefined && params.url.length > 0) {
		url = params.url + "/"
	}

	if (params.type !== undefined && params.type.length > 0) {
		type = params.type
	}

	if (params.page !== undefined) {
		page = "page=" + params.page
	}

	if (params.size !== undefined) {
		size = "&size=" + params.size
	}

	if (params.clientName !== undefined && params.clientName.length > 0) {
		clientName = "&clientName=" + params.clientName
	}

	if (params.fullName !== undefined && params.fullName.length > 0) {
		fullName = "&fullName=" + params.fullName
	}

	if (params.status !== undefined && params.status.length > 0) {
		status = "&status=" + params.status
	}

	if (params.userName !== undefined && params.userName.length > 0) {
		userName = "&userName=" + params.userName
	}

	if (params.firstName !== undefined && params.firstName.length > 0) {
		firstName = "&firstName=" + params.firstName
	}

	if (params.secondName !== undefined && params.secondName.length > 0) {
		secondName = "&secondName=" + params.secondName
	}


	stringUrl = url + type + "?" + page + size + clientName + fullName + status + userName + firstName + secondName

    return stringUrl;
}

export const queryUrl = (params) => (dispatch) => {
	var stringUrl = "", page = "", size = "", sort = "";

	if (params.page !== undefined) {
		page = "page=" + params.page
	}

	if (params.size !== undefined) {
		size = "&size=" + params.size
	}

	if ((params.sortBy !== undefined && params.sortType !== undefined) && params.sortBy.length > 0) {
		sort = "&sort=" + params.sortBy + "," + params.sortType
	}

	stringUrl = "query?" + page + size + sort

    return stringUrl;
}

export const formatDate = (dateInString) => (dispatch) => {

	if (dateInString === null) {
		return ""
	}

	var dt = new Date(Date.parse(dateInString));
    var localDate = dt;

    var gmt = localDate;
    var min = gmt.getTime() / 1000 / 60;
    var localNow = new Date().getTimezoneOffset();
    var localTime = min - localNow;

    var dateStr = new Date(localTime * 1000 * 60);
	// var locale = window.navigator.userLanguage || window.navigator.language;

	var clientDateTimeStr = dateStr.toLocaleString('uk-UA', {
	  	"day": "numeric",
        "month": "numeric",
        "year": "numeric",
        "hour": "2-digit",
        "minute": "2-digit",
        "second": "2-digit"
	});

	return clientDateTimeStr;
}

export const formatDateWithoutTime = (dateInString) => (dispatch) => {

	if (dateInString === null) {
		return ""
	}

	var dt = new Date(Date.parse(dateInString));
    var localDate = dt;

    var gmt = localDate;
    var min = gmt.getTime() / 1000 / 60;
    var localNow = new Date().getTimezoneOffset();
    var localTime = min - localNow;

    var dateStr = new Date(localTime * 1000 * 60);
	// var locale = window.navigator.userLanguage || window.navigator.language;

	var clientDateTimeStr = dateStr.toLocaleString('uk-UA', {
	  	"day": "numeric",
        "month": "numeric",
        "year": "numeric"
	});

	return clientDateTimeStr;
}

export const formatDateWithoutTimeQueryStr = (dateInString) => (dispatch) => {

	if (dateInString === null) {
		return ""
	}

	var dt = new Date(Date.parse(dateInString));
    var localDate = dt;

    var gmt = localDate;
    var min = gmt.getTime() / 1000 / 60;
    var localNow = new Date().getTimezoneOffset();
    var localTime = min - localNow;

    var dateStr = new Date(localTime * 1000 * 60);
	// var locale = window.navigator.userLanguage || window.navigator.language;

	var clientDateTimeStr = dateStr.toLocaleString('fr-CA', {
	  	"day": "numeric",
        "month": "numeric",
        "year": "numeric"
	});

	return clientDateTimeStr;
}

export const formatDateForCalendar = (dateInString) => (dispatch) => {
	if (dateInString === null) {
		return "";
	}

	var dt = new Date(Date.parse(dateInString));
    var localDate = dt;

    var gmt = localDate;
    var min = gmt.getTime() / 1000 / 60;
    var localNow = new Date().getTimezoneOffset();
    var localTime = min - localNow;

    var dateStr = new Date(localTime * 1000 * 60);

	return dateStr;
}

export const fromBytes = (digit) => (dispatch) => {
	var result,
			gigabyte  = 1073741824,
			megabyte = 1048576,
			kilobyte = 1024

	function div(val, by) {
	    return (val - val % by) / by;
	}

	function isInt(n) {
		return Number(n) === n && n % 1 === 0
	}

	if (div(digit, gigabyte) > 0 && isInt(div(digit, gigabyte))) {
		result = {
			value: (digit / gigabyte).toFixed(2),
			unit: "gigabyte"
		}
	} else if (div(digit, megabyte) > 0 && isInt(div(digit, megabyte))) {
		result = {
			value: (digit / megabyte).toFixed(2),
			unit: "megabyte"
		}
	} else if (div(digit, kilobyte) > 0 && isInt(div(digit, kilobyte))) {
		result = {
			value: (digit / kilobyte).toFixed(2),
			unit: "kilobyte"
		}
	} else {
		result = {
			value: digit,
			unit: "byte"
		}
	}

	return result;
}

export const toBytes = (digit, unit) => (dispatch) => {
	var result,
			gigabyte  = 1073741824,
			megabyte = 1048576,
			kilobyte = 1024

	if (digit < 1) {
		result = 0;
		return result;
	}

	switch (unit) {
		case "byte":
			result = digit * 1
			break;

		case "kilobyte":
			result = digit * kilobyte
			break;

		case "megabyte":
			result = digit * megabyte
			break;

		case "gigabyte":
			result = digit * gigabyte
			break;

		default:
			result = digit * 1
			break;
	}

	return result;
}

export const fromSeconds = (digit, value) => (dispatch) => {
	var result, year = 31556952,
			month  = 2629746,
			day = 86400,
			hour = 3600,
			minute = 60;

		function div(val, by) {
		    return (val - val % by) / by;
		}

		function isInt(n) {
			return Number(n) === n && n % 1 === 0
		}

		if (div(digit, year) > 0 && isInt(div(digit, year))) {
			result = {
				value: div(digit, year),
				unit: "years"
			}
		} else if (div(digit, month) > 0 && isInt(div(digit, month))) {
			result = {
				value: div(digit, month),
				unit: "months"
			}
		} else if (div(digit, day) > 0 && isInt(div(digit, day))) {
			result = {
				value: div(digit, day),
				unit: "days"
			}
		} else if (div(digit, hour) > 0 && isInt(div(digit, hour))) {
			result = {
				value: div(digit, hour),
				unit: "hours"
			}
		} else if (div(digit, minute) > 0 && isInt(div(digit, minute))) {
			result = {
				value: div(digit, minute),
				unit: "minutes"
			}
		} else {
			result = {
				value: digit,
				unit: "seconds"
			}
		}
	return result;
}

export const toSeconds = (digit, value) => (dispatch) => {
	var totalSeconds, year = 31556952,
			month  = 2629746,
			day = 86400,
			hour = 3600,
			minute = 60;

	switch(value) {
		case "years":
			totalSeconds = year * digit
			break;

		case "months":
			totalSeconds = month * digit
			break;

		case "days":
			totalSeconds = day * digit
			break;

		case "hours":
			totalSeconds = hour * digit
			break;

		case "minutes":
			totalSeconds = minute * digit
			break;

		default:
			totalSeconds = digit
			break;
	}

	return totalSeconds;
}

// export const formatDate = (dateInString) => (dispatch) => {
//     var locale = window.navigator.userLanguage || window.navigator.language;
//     var localeDateOptions = {
//         "day": "numeric",
//         "month": "numeric",
//         "year": "numeric",
//         "hour": "2-digit",
//         "minute": "2-digit",
//         "second": "2-digit"
//     }

//     return new Date(dateInString).toLocaleDateString(locale, localeDateOptions);
// }

export const isIEOrEdge = () => (dispatch) => {
	var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
		if (msie > 0) {
		// IE 10 or older => return version number
		return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
	}

	var trident = ua.indexOf('Trident/');
	if (trident > 0) {
	// IE 11 => return version number
		var rv = ua.indexOf('rv:');
		return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
	}

	// other browser
	return false;
}