import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Body from '../components/Body';
import Drawer from '../components/Drawer';

class BodyContainer extends Component {
	constructor() {
		super();
		this.state = {

		};
	}

	componentDidMount() {
	}

	componentWillUnmount () {
	}

	render() {
		return (
			<div className="row body_div no-left-right-margin" style={{marginLeft: "0px", marginRight: "0px", top: "60px"}}>
				<Drawer />
				<Body />
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer,
        config: state.ConfigReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BodyContainer);