import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import { setFirstName, setSurname, setOrganisation, setDepartment, setPositon, setDrfou, setEdrpou, setEddrun, clearAllAuthorFields, setEditAuthor, setAuthors,
			setPassport } from '../actions/localStates'

class AddAuthorPopup extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    	arr: ["department", "drfou", "edrpou", "eddrun", "passportNumber"],
	    	activeArr: [],
	    	drfouIsValid: true,
	    	edrpouIsValid: true,
	    	eddrunIsValid: true,
	    	passportNumberIsValid: true,
	    	departmentIsValid: true
	    }

	    this.onFirstNameChanged = this.onFirstNameChanged.bind(this);
	    this.onSurnameChanged = this.onSurnameChanged.bind(this);
	    this.onOrganisationChanged = this.onOrganisationChanged.bind(this);
	    this.ondepartmentChanged = this.ondepartmentChanged.bind(this);
	    this.onPositionChanged = this.onPositionChanged.bind(this);
	    this.ondrfouChanged = this.ondrfouChanged.bind(this);
	    this.onedrpouChanged = this.onedrpouChanged.bind(this);
	    this.oneddrunChanged = this.oneddrunChanged.bind(this);
	    this.onpassportNumberChanged = this.onpassportNumberChanged.bind(this);

	    this.handleShowDropDown = this.handleShowDropDown.bind(this);

	    this.addAuthor = this.addAuthor.bind(this);
	    this.editAuthor = this.editAuthor.bind(this);
	    this.closePopup = this.closePopup.bind(this);
	    this.clearField = this.clearField.bind(this);

	    this.keyUpHandler = this.keyUpHandler.bind(this, 'return');
	    this.handleAuthorKeyUp = this.handleAuthorKeyUp.bind(this, 'authorsInput');
	}

	handleShowDropDown () {
		document.activeElement.blur();
        this.setState({
            showDropDown: !this.state.showDropDown
        });
	}

	handleChangeState (value, index) {
		var tmpArr = this.state.activeArr, arr = this.state.arr;
		tmpArr.push(value);

        document.activeElement.blur();
        this.setState({
            showDropDown: !this.state.showDropDown
        });

		arr.splice(index, 1);

		this.setState({arr: arr});

        this.setState({
            activeArr: tmpArr
        });
	}

	onFirstNameChanged (e) {
		this.props.actions.setFirstName(e.target.value)
	}

	onSurnameChanged (e) {
		this.props.actions.setSurname(e.target.value)
	}

	onOrganisationChanged (e) {
		this.props.actions.setOrganisation(e.target.value)
	}

	ondepartmentChanged (e) {
		this.props.actions.setDepartment(e.target.value)
	}

	onPositionChanged (e) {
		this.props.actions.setPositon(e.target.value)
	}

	ondrfouChanged (e) {
		var patternDRFOU = /^[0-9]{10}$/;
		const re = /^[0-9\b]+$/;

	    if (e.target.value === '' || re.test(e.target.value)) {
	    	if (e.target.value.length > 0 && patternDRFOU.test(e.target.value)) {
				this.setState({"drfouIsValid": true})
			} else {
				this.setState({"drfouIsValid": false})
			}

			this.props.actions.setDrfou(e.target.value);
	    }
	}

	onedrpouChanged (e) {
		var patternEDRPOU = /^[0-9]{8}$/;
		const re = /^[0-9\b]+$/;

	    if (e.target.value === '' || re.test(e.target.value)) {
	        if (e.target.value.length > 0 && patternEDRPOU.test(e.target.value)) {
				this.setState({"edrpouIsValid": true})
			} else {
				this.setState({"edrpouIsValid": false})
	        }

	        this.props.actions.setEdrpou(e.target.value);
	    }
	}

	oneddrunChanged (e) {
		const re = /^[0-9+-?]+$/;
		var patternUNZR = /^[0-9]{8}-[0-9]{5}$/;

	    if (e.target.value === '' || re.test(e.target.value)) {
	    	if (e.target.value.length === 8 && (this.props.author.eddrun.lastIndexOf('-') === -1)) {
	        	this.props.actions.setEddrun(e.target.value + "-");
	        } else {
	        	this.props.actions.setEddrun(e.target.value);
	        }
	        if (e.target.value.length > 0) {
		        if (patternUNZR.test(e.target.value)) {
					this.setState({"eddrunIsValid": true})
				} else {
					this.setState({"eddrunIsValid": false})
				}
			}
	    }
	}

	onpassportNumberChanged (e) {
		this.props.actions.setPassport(e.target.value)

		if (e.target.value.length > 0 && e.target.value.length < 8) {
			this.setState({"passportNumberIsValid": false})
		} else {
			this.setState({"passportNumberIsValid": true})
		}
	}

	addAuthor () {
		if (!this.state.drfouIsValid || !this.state.edrpouIsValid || !this.state.eddrunIsValid || !this.state.passportNumberIsValid) {
			return;
		}
		this.props.docs.authors.push(this.props.author);
		this.props.actions.clearAllAuthorFields();
		this.props.closePopup();
	}

	clearField (value) {
		switch (value) {
			case "department": {
                this.props.actions.setDepartment("")
                break;
            }

            case "drfou": {
                this.props.actions.setDrfou("")
                this.setState({"drfouIsValid": true})
                break;
            }

            case "edrpou": {
                this.props.actions.setEdrpou("")
                this.setState({"edrpouIsValid": true})
                break;
            }

            case "eddrun": {
                this.props.actions.setEddrun("")
                this.setState({"eddrunIsValid": true})
                break;
            }

            case "passportNumber": {
                this.props.actions.setPassport(null)
                this.setState({"passportNumberIsValid": true})
                break;
            }

            default:
                break;
		}
	}

	closePopup() {
		this.props.actions.clearAllAuthorFields();
		this.props.closePopup();
	}

	editAuthor () {
		if (!this.state.drfouIsValid || !this.state.edrpouIsValid || !this.state.eddrunIsValid || !this.state.passportNumberIsValid) {
			return;
		}
		this.props.docs.authors.forEach((element, index) => {
		    if(this.props.base.authorIndex === index) {
		        this.props.docs.authors[index] = this.props.author;
		    }
		});
		this.props.actions.setAuthors(this.props.docs.authors)
		this.props.actions.clearAllAuthorFields();
		this.props.actions.setEditAuthor(false, 0);
		this.props.closePopup();
	}

	componentDidMount() {
		var tmpArr = this.state.activeArr, arr = this.state.arr, _this = this;

		document.addEventListener('keyup', this.keyUpHandler);
		document.addEventListener('keyup', this.handleAuthorKeyUp);

		arr.forEach(function (value) {
			if (_this.props.author[value] !== null) {
				if ((_this.props.author[value] !== undefined) && _this.props.author[value].length > 0) {
					tmpArr.push(value);
				}
			}
		});

		tmpArr.forEach(function (value) {
			var index = arr.indexOf(value);
			if (arr.indexOf(value) > -1) {
				arr.splice(index, 1);
			}
		});

		this.setState({arr: arr});

        this.setState({
            activeArr: tmpArr
        });
	}

	componentWillUnmount() {
		document.removeEventListener('keyup', this.keyUpHandler);
		document.removeEventListener('keyup', this.handleAuthorKeyUp);
	}

	handleAuthorKeyUp(refName, e) {
        if (e.keyCode === 13 && refName === "authorsInput") {
        	e.preventDefault()
			e.stopPropagation();

			if (e.nativeEvent !== undefined) {
				e.nativeEvent.stopImmediatePropagation();
			}

			if (this.props.base.editAuthor) {
				document.getElementById("edit").click();
				document.activeElement.blur();
			} else {
				document.getElementById("add").click();
				document.activeElement.blur();
			}
			
        }
    }

    keyUpHandler(refName, e) {
        if (e.keyCode === 13 && refName === "return") {
			e.preventDefault()
			e.stopPropagation();

			if (e.nativeEvent !== undefined) {
				e.nativeEvent.stopImmediatePropagation();
			}
			document.getElementById("close").click();
			document.activeElement.blur()
        }
    }

	handleDeleteField (value, index) {
		var tmpArr = this.state.activeArr, arr = this.state.arr;

		tmpArr.splice(index, 1);

		arr.push(value);

		this.setState({arr: arr});

        this.setState({
            activeArr: tmpArr
        });

        this.clearField(value);
	}

	_renderElement (value, index) {
		var name, handlerName, isValid;
		name = value;
		handlerName = "on" + value + "Changed";
		isValid = value + "IsValid";


		return <div key={index} className="input-group">
					<label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t(name)}</label>
					<div className="col-12 input-group">
						<input className={(this.state[isValid]) ? "form-control" : "form-control is-invalid"}
							type="text" value={this.props.author[name]}
							onChange={this[handlerName]} maxLength={value === "eddrun"
							? "14"
							: value === "passportNumber" ? "9"
							: value === "drfou" ? "10"
							: value === "edrpou" ? "8" : ""} placeholder={value === "eddrun" ? "00000000-00000" : ""} onKeyUp={this.handleAuthorKeyUp} ref="authorsInput"></input>
							
					  	<div className="input-group-append">
							<div className="input-group-text" onClick={this.handleDeleteField.bind(this, name, index)} style={{cursor: "pointer"}}><i className="fa fa-remove"></i></div>
						</div>
						{
							!this.state[isValid] ?
							<div className="col-12" style={{paddingLeft: "0px", paddingTop: "5px"}}>
								<span style={{color: "red"}}>{i18next.t(isValid)}</span>
							</div> : null
						}
					</div>
                </div>
	}

	render() {
		let itemsCountFormOpen = 'dropdown-menu'
		if (this.state.showDropDown) {
			itemsCountFormOpen = 'dropdown-menu show'
		}
		return (
			<div className='popup'>
				<div className="popup_inner">
					<h4 style={{marginLeft: "15px", marginTop: "15px"}}>{i18next.t("addAuthors")}</h4>
					<div className="popup_inner_authors">
						<div>
							<label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("firstName")}</label>
							<div className="col-12 input-group">
							  <input className="form-control" value={this.props.author.firstName} onChange={this.onFirstNameChanged} onKeyUp={this.handleAuthorKeyUp} ref="authorsInput" />
							</div>
	                    </div>
	                    <div>
							<label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("surname")}</label>
							<div className="col-12 input-group">
							  <input className="form-control" value={this.props.author.surname} onChange={this.onSurnameChanged} onKeyUp={this.handleAuthorKeyUp} ref="authorsInput" />
							</div>
	                    </div>
						<div>
							<label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("organisation")}</label>
							<div className="col-12 input-group">
								<input className="form-control" value={this.props.author.organisation} onChange={this.onOrganisationChanged} onKeyUp={this.handleAuthorKeyUp} ref="authorsInput" />
							</div>
	                    </div>
	                    {	
	                    	
	                    	this.state.activeArr.map((value, index) => {
	                    		return this._renderElement(value, index)
	                    	})
	                    }
	                    {
	                    	this.state.arr.length > 0
	                    	? <div className="col-md-12 col-xs-12 col-sm-12" style={{marginTop: "10px"}}>
			                    <div className="btn-group btn-block dropup">
									<button type="button" onClick={this.handleShowDropDown} className="btn btn-outline-info btn-block dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.showDropDown}>
										{i18next.t("addFieldLabel")}
									</button>
									<div className={itemsCountFormOpen} style={{minWidth: "5rem", width: "100%"}}>
										{
											this.state.arr.map((value, index) => {
												return (<span key={index} className="dropdown-item" style={{cursor: "pointer"}} onClick={this.handleChangeState.bind(this, value, index)} value={value}>{i18next.t(value)}</span>)
											})
										}
									</div>
								</div>
		                    </div>
		                    : null
	                    }
	                    {
	                    	this.state.activeArr.length > 0
	                    	? null
	                    	: <div style={{marginTop: "60px"}}></div>
	                    }
	                </div>
	                <div style={{float: "right", marginRight: "15px", paddingTop: "15px", paddingBottom: "10px"}}>
                    	{
                    		this.props.base.editAuthor
                    		? <button id="edit" className="btn btn-info" style={{marginLeft: "5px"}} onClick={this.editAuthor}>{i18next.t("updateLabel")}</button>
                    		: <button id="add" className="btn btn-info" style={{marginLeft: "5px"}} disabled={(this.props.author.firstName.length > 0 && this.props.author.surname.length > 0) ? false : true} onClick={this.addAuthor}>{i18next.t("addLabel")}</button>
                    	}
						<button id="close" className="btn btn-outline-info" style={{marginLeft: "5px"}} onClick={this.closePopup}>{i18next.t("cancelLabel")}</button>
                  	</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer,
        author: state.authorReducer,
        docs: state.addDocReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	setFirstName, setSurname, setOrganisation,
    	setDepartment, setPositon, setDrfou,
    	setEdrpou, setEddrun, clearAllAuthorFields,
    	setEditAuthor, setAuthors, setPassport
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAuthorPopup);