import 'react-app-polyfill/ie11';
import'react-app-polyfill/stable';
import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import i18next from 'i18next'
import i18nextXHRBackend from 'i18next-xhr-backend'
import Root from './containers/Root'
import configureStore from './store/configureStore'


const store = configureStore()
i18next
.use(i18nextXHRBackend)
.init({
    lng: 'uk',
    fallbackLng: 'uk',
    debug: true,
    initImmediate: true,
    ns: ['common'],
    defaultNS: 'common',
    backend: {
        loadPath: "../locales/{{lng}}/{{ns}}.json",
        crossDomain: false
    }
}, function (err, t) {
    console.log(i18next.languages);
    if (err) {
        alert("Error while loading localisation library.\nПомилка при завантаженні бібліотеки локалізації.");
    }
    
    render(
	  <Router>
	    <Root store={store} />
	  </Router>,
	  document.getElementById('root')
	)
})
