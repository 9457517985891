const baseState = {
    data: null
}

const docsInArchReducer = (state = baseState, { type, payload }) => {

    switch (type) {
        case "SET_RECIEVED_DATA": {
            return {
                ...state,
                data: payload
            }
        }

        case "CLEAR_RECIEVED_DATA": {
            return {
                ...state,
                data: null
            }
        }

        default:
            return state;
    }
}

export default docsInArchReducer