import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import { setDocForUpload, setDocVersion } from '../actions/localStates'

import Dropzone from 'react-dropzone';

const dropzoneRef = createRef();

class AddDocView extends Component {
	constructor() {
		super();
		this.state = {
			fileForUpload: null,
			isLoading: false,
			responseFileData: null,
			responseTachSignatures: null
		};

		this.handleOnDrop = this.handleOnDrop.bind(this);
	}

	buildFileInputSelector(){
		const fileInput = document.getElementById('fileInputUpload');
		return fileInput;
	}

	componentDidMount() {
		this.fileInput = this.buildFileInputSelector();
	}


	componentWillUnmount () {
	}

	addDoc () {
		this.props.addDoc();
	}

	handleChangeVersion () {
		this.props.actions.setDocVersion(this.props.base.docVersion === 0 ? 1 : 0)
		// if ((this.props.base.docVersion + value) < 1) {
		// 	return
		// } else {
		// 	this.props.actions.setDocVersion(this.props.base.docVersion + value)
		// }
	}

	handleOnDrop (files) {
		if (files[0] !== undefined) {
			this.props.actions.setDocForUpload(files[0])
			this.addDoc()
		}
		console.log(files[0].name)
	}

	render() {
		return (
			<div style={{paddingLeft: "15px", paddingRight: "15px"}}>
				<Dropzone ref={dropzoneRef} onDrop={this.handleOnDrop}>
				  {({getRootProps, getInputProps}) => (
				    <div {...getRootProps()} disabled={this.props.base.processing.toString()} style={{cursor: "pointer", marginTop: "16px"}} className="dropzone">
				      <input {...getInputProps()} />
				      {
				      	this.props.base.processing
				      	? <p style={{marginTop: "16px"}}><i className="fa fa-spinner fa-pulse"></i>&nbsp; Uploading {this.props.docs.fileForUpload.name}</p>
				      	: <p style={{cursor: "pointer", marginTop: "16px"}}>{i18next.t("dragAndDropLabel")}</p>
				      }
				    </div>
				  )}
				</Dropzone>
				<div className="row col-12 no-left-right-margin">
						<div className="row col-12 col-md-12 col-lg-12 col-xs-12 col-sm-12" style={{paddingTop: "5px", marginTop: "5px", minWidth: "190px", paddingLeft: "0px"}}>
							
							<div style={{textAlign: "left", paddingTop: "1px", paddingRight: "10px"}}>
									
							<input type="checkbox" checked={this.props.base.docVersion === 1} onChange={this.handleChangeVersion.bind(this)} />
							
							</div>
							{i18next.t("docVersionTitle")}
							{/*
								<i className="fa fa-chevron-left" style={{cursor: "pointer", paddingTop: "1px"}} onClick={this.handleChangeVersion.bind(this, - 1)}></i>

								<div style={{width: "30px", textAlign: "center", paddingTop: "1px"}}>
									
								<span>{this.props.base.docVersion}</span>

								</div>
								<i className="fa fa-chevron-right" style={{cursor: "pointer", paddingTop: "1px"}} onClick={this.handleChangeVersion.bind(this, + 1)}></i>
							*/}
							
						</div>
							
						{/*
							<div className="col-10 col-md-10 col-lg-10 col-xs-10 col-sm-10 no-left-right-margin" style={{paddingTop: "5px", fontSize: "11pt"}}>
								<span>{i18next.t("docVersionDescription")}</span>
							</div>
						*/}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer,
        docs: state.addDocReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	setDocForUpload,
    	setDocVersion
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDocView);